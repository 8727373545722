import { memo } from 'react';

import { Box, CircularProgress, Stack, Typography } from '@mui/material';

import { LoadingTitleProps } from './types';

const LoadingTitle = memo((props: LoadingTitleProps) => {
  const { label, title, loading } = props;

  return (
    <Stack component="h3" direction="row" alignItems="center" spacing={2} m={0}>
      <Typography component="p" variant="h6" fontWeight={700}>
        {label}:
      </Typography>
      <Box>
        {loading ? (
          <CircularProgress
            sx={{ display: 'flex', alignItems: 'center' }}
            size="1em"
            aria-label="로딩 중"
          />
        ) : (
          <Typography component="p" variant="h6" fontWeight={700}>
            {title}
          </Typography>
        )}
      </Box>
    </Stack>
  );
});

LoadingTitle.displayName = 'LoadingTitle';

export default LoadingTitle;
