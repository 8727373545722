import { Outlet } from 'react-router-dom';

import { Layout } from 'components';

const LayoutRoute = () => (
  <Layout>
    <Outlet />
  </Layout>
);

export default LayoutRoute;
