import { memo, ChangeEvent } from 'react';

import { Stack, Switch } from '@mui/material';

import { fullHeight, fullWidth } from 'styles';

export type SwitchCellProps = {
  label: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

const SwtichCell = memo((props: SwitchCellProps) => {
  const { label, checked, disabled = false, onChange } = props;

  return (
    <Stack sx={[fullWidth, fullHeight]}>
      <Switch
        sx={{ m: 'auto' }}
        aria-label={label}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
    </Stack>
  );
});

SwtichCell.displayName = 'SwtichCell';

export default SwtichCell;
