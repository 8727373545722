import { SyntheticEvent, useCallback } from 'react';

import { Search } from '@mui/icons-material';
import { Autocomplete, Box, Stack, TextField } from '@mui/material';
import { array, object } from 'yup';

import { AutocompleteList, ModalButtonGroup, AutocompletePopper } from 'components';
import { useTypedFormik } from 'hooks';

import { UpdateTagFormProps, Tag } from './types';

const UpdateTagForm = (props: UpdateTagFormProps) => {
  const {
    type,
    initialValues,
    options,
    optionsLoading = false,
    onSubmit,
    isUpdateLoading = false,
    onCancel,
  } = props;

  const { values, handleSubmit, setFieldValue, handleBlur, shouldErrorShows, isValid } =
    useTypedFormik({
      initialValues,
      onSubmit,
      validateOnMount: true,
      validationSchema: object({
        tagList: array()
          .required()
          .test('isMultipleType', '태그를 1개 이상 선택해주세요.', (value) => {
            if (Array.isArray(value)) {
              return type === 'multiple' ? value.length > 0 : true;
            }

            return false;
          }),
      }),
    });

  const handleChangeValue = useCallback(
    (e: SyntheticEvent, value: Tag[]) => {
      setFieldValue('tagList', value);
    },
    [setFieldValue]
  );

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack component="fieldset" spacing={4}>
        <Autocomplete
          options={options.sort((tagA, tagB) => tagA.categoryNo - tagB.categoryNo)}
          renderInput={(params) => (
            <TextField
              {...params}
              onBlur={handleBlur('tagList')}
              error={shouldErrorShows('tagList')}
              placeholder="검색어 입력"
            />
          )}
          multiple
          value={values.tagList}
          onChange={handleChangeValue}
          getOptionLabel={(option) => option.tagName}
          renderOption={(props, option, state) => ({
            liProps: props,
            optionLabel: option.tagName,
            selected: state.selected,
            multiple: true,
          })}
          groupBy={(option) => option.categoryName}
          renderGroup={(params) => params}
          loading={optionsLoading}
          loadingText="로딩 중..."
          noOptionsText="검색 결과가 없습니다."
          disabled={optionsLoading}
          disableCloseOnSelect
          popupIcon={<Search />}
          sx={{ mt: '2px', '& .MuiAutocomplete-popupIndicator': { transform: 'none' } }}
          PopperComponent={AutocompletePopper}
          ListboxComponent={AutocompleteList}
          isOptionEqualToValue={(option, value) => option.tagNo === value.tagNo}
        />
        <ModalButtonGroup
          type="submit"
          onCancel={onCancel}
          disableConfirm={!isValid || isUpdateLoading}
          loading={isUpdateLoading}
        />
      </Stack>
    </Box>
  );
};

export default UpdateTagForm;
