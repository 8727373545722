import { atom, useRecoilState } from 'recoil';

import { SNACKBAR_STATE_KEY } from './const';
import { SnackbarState } from './types';

const snackbarState = atom<SnackbarState | null>({
  key: SNACKBAR_STATE_KEY,
  default: null,
});

const useSnackbar = () => {
  return useRecoilState(snackbarState);
};

export default useSnackbar;
