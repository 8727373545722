import { useCallback } from 'react';

import { Box, Stack } from '@mui/material';
import { flexRender, HeaderGroup } from '@tanstack/react-table';

import { SSO } from 'styles';
import { AnyObject } from 'utils';

import { cellStyle, TableLayerOrder } from './const';
import TextCell from './text-cell';

export type TableFooterProps<T extends AnyObject> = {
  footerGroups: HeaderGroup<T>[];
  rowHeight: number;
  stickyColumnsCount: number;
  stickyColumnsPositionList: number[];
  enableScrollX: boolean;
};

const tableFooterStyle: SSO = {
  position: 'sticky',
  bottom: 0,
  zIndex: TableLayerOrder.ColumnContainer,
  bgcolor: 'background.paper',
  borderTop: ({ palette }) =>
    `1px solid ${palette.mode === 'light' ? palette.line.grey : palette.line.darkGrey}`,
};

const TableFooter = <T extends AnyObject>(props: TableFooterProps<T>) => {
  const { footerGroups, rowHeight, stickyColumnsCount, stickyColumnsPositionList, enableScrollX } =
    props;

  const getStickyStyle = useCallback(
    (totalColSpan: number) => {
      const isStickyColumn = totalColSpan < stickyColumnsCount;
      return enableScrollX && isStickyColumn
        ? {
            position: 'sticky',
            left: stickyColumnsPositionList[totalColSpan],
            zIndex: TableLayerOrder.FixedColumnCell,
          }
        : false;
    },
    [enableScrollX, stickyColumnsCount, stickyColumnsPositionList]
  );

  const getFullWidthStyle = useCallback(
    (colWidth: number, flexGrow: number) => {
      // NOTE: checkbox인 경우는 처리 X
      return enableScrollX
        ? {
            width: colWidth,
            flexShrink: 0,
          }
        : {
            flexGrow,
            flexBasis: colWidth,
            flexShrink: 0,
          };
    },
    [enableScrollX]
  );

  return (
    <Box role="rowgroup" sx={tableFooterStyle}>
      {footerGroups.map((footerGroup) => {
        let totalColSpan = 0;

        return (
          <Stack key={footerGroup.id} role="row" direction="row">
            {footerGroup.headers.map((header) => {
              const colSpan = header.colSpan;
              const columnWidth = header.getSize();
              const stickyStyle = getStickyStyle(totalColSpan);
              const fullWidthStyle = getFullWidthStyle(columnWidth, colSpan);

              const { meta, footer } = header.column.columnDef;
              const textAlign = meta?.textAlign || 'start';

              totalColSpan += colSpan;

              return (
                <Box
                  key={header.id}
                  role="cell"
                  sx={[cellStyle, { height: rowHeight, p: 1 }, stickyStyle, fullWidthStyle]}
                >
                  <TextCell textAlign={textAlign}>
                    {flexRender(footer, header.getContext()) as string}
                  </TextCell>
                </Box>
              );
            })}
          </Stack>
        );
      })}
    </Box>
  );
};

export default TableFooter;
