import { useEffect } from 'react';

import { useRecoilSnapshot } from 'recoil';

const isDevelopMode = process.env.REACT_APP_ENV === 'dev';

const RecoilObserver = (props: { componentName: string }) => {
  const { componentName } = props;
  const snapshot = useRecoilSnapshot();

  useEffect(() => {
    if (isDevelopMode) {
      console.log('***');
      console.log(`${componentName} - The following atoms were modified:`);

      for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
        console.log(node.key, snapshot.getLoadable(node));
      }
    }
  }, [snapshot, componentName]);

  return null;
};

export default RecoilObserver;
