import { memo } from 'react';

import { Radio, RadioGroup, RadioGroupProps, FormControlLabel } from '@mui/material';

import { SettlementType } from 'api';

export type SelectSettlementTypeProps = RadioGroupProps;

const SelectSettlementType = memo((props: RadioGroupProps) => {
  return (
    <RadioGroup aria-label="게임 타입 선택" sx={{ flexDirection: 'row' }} {...props}>
      <FormControlLabel
        value={SettlementType.게임}
        control={<Radio />}
        label={SettlementType[SettlementType.게임]}
      />
      <FormControlLabel
        value={SettlementType.비게임}
        control={<Radio />}
        label={SettlementType[SettlementType.비게임]}
      />
    </RadioGroup>
  );
});

SelectSettlementType.displayName = 'SelectSettlementType';

export default SelectSettlementType;
