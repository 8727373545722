import { createContext, forwardRef, useContext } from 'react';

import { Box } from '@mui/material';

import { overflowY } from 'styles';

export const OuterElementContext = createContext({});

const OuterElement = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <Box ref={ref} {...props} {...outerProps} sx={overflowY} />;
});

OuterElement.displayName = 'OuterElement';

export default OuterElement;
