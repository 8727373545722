import { forwardRef, HTMLAttributes, useMemo } from 'react';
import { FixedSizeList } from 'react-window';

import { hasOwnProperty } from 'utils';

import { ITEM_SIZE, LISTBOX_PADDING, MIN_ITEM_COUNT } from './const';
import OuterElement, { OuterElementContext } from './outer-element';
import renderRow from './render-row';
import { GroupingItem, ListItem } from './types';

const AutocompleteList = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>((props, ref) => {
  const { children, ...rest } = props;

  const itemData = useMemo(() => {
    const itemData: ListItem[] = [];

    (children as ListItem[]).forEach((item) => {
      itemData.push(item);

      if (hasOwnProperty(item, 'group')) {
        itemData.push(...(item as GroupingItem).children);
      }
    });

    return itemData;
  }, [children]);

  const itemCount = itemData.length;

  const listHeight =
    itemCount > MIN_ITEM_COUNT ? MIN_ITEM_COUNT * ITEM_SIZE : itemCount * ITEM_SIZE;

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={rest}>
        <FixedSizeList
          itemData={itemData}
          itemCount={itemCount}
          height={listHeight + 2 * LISTBOX_PADDING}
          itemSize={ITEM_SIZE}
          width="100%"
          outerElementType={OuterElement}
          innerElementType="ul"
          overscanCount={5}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

AutocompleteList.displayName = 'AutocompleteList';

export default AutocompleteList;
