import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

import { CampaignAPI } from 'api';
import useUser from 'hooks/use-user';

import {
  CampaignTagListQueryFn,
  CampaignTagListQueryKey,
  CampaignTagListQueryOptions,
} from './types';

const useCampaignTagList = (includeDefaultTag = false, options?: CampaignTagListQueryOptions) => {
  const { user } = useUser();

  const queryKey = useMemo<CampaignTagListQueryKey>(() => 'campaignTagList', []);

  const queryFn = useCallback<CampaignTagListQueryFn>(() => {
    return CampaignAPI.getCampaginTagList(user.accessToken, {
      includeDefaultTag,
    });
  }, [user.accessToken, includeDefaultTag]);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, options);

  const campaignTagList = useMemo(() => {
    return response ? response.data.TagList : [];
  }, [response]);

  return {
    queryKey,
    campaignTagList,
    ...rest,
  };
};

export default useCampaignTagList;
