import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useUser } from 'hooks';

import { RedirectState } from './types';

const RedirectRoute = () => {
  const { isAuthorizedUser } = useUser();
  const location = useLocation();

  const state = location.state as RedirectState;

  if (isAuthorizedUser) {
    return <Navigate to={state?.nextPagePath || 'dashboard'} replace />;
  }

  return <Outlet />;
};

export default RedirectRoute;
