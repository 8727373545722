import { useEffect, useMemo, useState } from 'react';

import { Box, Button, Checkbox, Divider, Stack, TextField, Typography } from '@mui/material';

import { SSO } from 'styles';
import { AnyObject, intersection, not, union } from 'utils';

import Options from './options';
import { TransferListOption, TransferListProps } from './types';

const textFieldContainer: SSO = {
  position: 'relative',
  width: 1,
};

const textFieldStyle: SSO = {
  width: 1,
  '& .MuiOutlinedInput-input': { py: 2, pl: 2, pr: 9 },
};

const TransferList = <T extends AnyObject>(props: TransferListProps<T>) => {
  const {
    left,
    onChangeRight,
    uniqueKey,
    optionGenerator,
    leftOptionsTitle,
    leftSearchPlaceHolder,
    rightOptionsTitle,
    rightSearchPlaceHolder,
  } = props;

  /**
   * 좌우 선택된 옵션 목록
   */
  const [checkedOptions, setCheckedOptions] = useState<TransferListOption<T>[]>([]);

  /**
   * 왼족 리스트 필터 값
   */
  const [leftSearch, setLeftSearch] = useState('');

  /**
   * 왼쪽 리스트
   */
  const [leftOptions, setLeftOptions] = useState<TransferListOption<T>[]>(
    left.map((value) => ({ ...value, checked: false }))
  );

  /**
   * 왼쪽 리스트 내 선택된 옵션
   */
  const leftOptionsChecked = useMemo(
    () => intersection(checkedOptions, leftOptions, uniqueKey),
    [checkedOptions, leftOptions, uniqueKey]
  );

  /**
   * 오른족 리스트 필터 값
   */
  const [rightSearch, setRightSearch] = useState('');

  /**
   * 오른쪽 리스트
   */
  const [rightOptions, setRightOptions] = useState<TransferListOption<T>[]>([]);

  /**
   * 오른족 리스트 내 선택된 옵션
   */
  const rightOptionsChecked = useMemo(
    () => intersection(checkedOptions, rightOptions, uniqueKey),
    [checkedOptions, rightOptions, uniqueKey]
  );

  useEffect(() => {
    setRightOptions((prevRightOptions) => {
      // 조회 결과(left)가 바뀌면 leftOptions 업데이트
      setLeftOptions((prevLeftOptions) => {
        const newLeftOptions = left.map((value) => {
          // 이미 존재하는 옵션의 경우 checked 상태를 그대로 사용
          const prevLeftOption = prevLeftOptions.find(
            (prevOption) => prevOption[uniqueKey] === value[uniqueKey]
          );
          return { ...value, checked: prevLeftOption ? prevLeftOption.checked : false };
        });

        // right에 포함된 매체는 필터
        return not(newLeftOptions, prevRightOptions, uniqueKey);
      });

      return prevRightOptions;
    });
  }, [left, uniqueKey]);

  const handleToggle = (selectedOption: TransferListOption<T>) => () => {
    const currentIndex = checkedOptions.findIndex(
      (option) => option[uniqueKey] === selectedOption[uniqueKey]
    );
    const newCheckedOptions = [...checkedOptions];

    if (currentIndex === -1) {
      newCheckedOptions.push(selectedOption);
    } else {
      newCheckedOptions.splice(currentIndex, 1);
    }

    setCheckedOptions(newCheckedOptions);
  };

  const numberOfChecked = (options: TransferListOption<T>[]) =>
    intersection(checkedOptions, options, uniqueKey).length;

  const handleToggleAll = (options: TransferListOption<T>[]) => () => {
    if (numberOfChecked(options) === options.length) {
      setCheckedOptions(not(checkedOptions, options, uniqueKey));
    } else {
      setCheckedOptions(union(checkedOptions, options, uniqueKey));
    }
  };

  const handleCheckedRight = () => {
    const newRightOptions = rightOptions.concat(leftOptionsChecked);

    onChangeRight(newRightOptions);
    setRightOptions(newRightOptions);
    setLeftOptions(not(leftOptions, leftOptionsChecked, uniqueKey));
    setCheckedOptions(not(checkedOptions, leftOptionsChecked, uniqueKey));
  };

  const handleCheckedLeft = () => {
    const newRightOptions = not(rightOptions, rightOptionsChecked, uniqueKey);
    onChangeRight(newRightOptions);

    setRightOptions(newRightOptions);
    setLeftOptions(leftOptions.concat(rightOptionsChecked));
    setCheckedOptions(not(checkedOptions, rightOptionsChecked, uniqueKey));
  };

  const filteredLeftOptions = useMemo(
    () =>
      leftOptions.filter((value) => {
        const option = optionGenerator(value);
        return option.includes(leftSearch.toLowerCase());
      }),
    [leftOptions, leftSearch, optionGenerator]
  );

  const filteredRightOptions = useMemo(
    () =>
      rightOptions.filter((value) => {
        const option = optionGenerator(value);
        return option.includes(rightSearch.toLowerCase());
      }),
    [optionGenerator, rightOptions, rightSearch]
  );

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      {/* 왼족 옵션 리스트 */}
      <Stack sx={{ flex: '1 1 0', bgcolor: 'background.paper' }}>
        <Stack direction="row" alignItems="center" spacing={4} sx={{ px: 4, py: 3 }}>
          <Checkbox
            onClick={handleToggleAll(filteredLeftOptions)}
            checked={
              filteredLeftOptions.length !== 0 &&
              numberOfChecked(filteredLeftOptions) === filteredLeftOptions.length
            }
            indeterminate={
              numberOfChecked(filteredLeftOptions) !== filteredLeftOptions.length &&
              numberOfChecked(filteredLeftOptions) !== 0
            }
            disabled={filteredLeftOptions.length === 0}
          />
          <Typography sx={{ flexShrink: 0 }}>{leftOptionsTitle}</Typography>
          <Box sx={textFieldContainer}>
            <TextField
              variant="outlined"
              autoComplete="off"
              placeholder={leftSearchPlaceHolder}
              sx={textFieldStyle}
              value={leftSearch}
              onChange={(e) => setLeftSearch(e.target.value)}
            />
          </Box>
        </Stack>
        <Divider />
        <Options
          options={filteredLeftOptions}
          checkedOptions={checkedOptions}
          onToggle={handleToggle}
          uniqueKey={uniqueKey}
          optionGenerator={optionGenerator}
        />
      </Stack>
      {/* 이동 버튼 */}
      <Stack alignItems="center" spacing={1} sx={{ flexShrink: 0, mx: 3 }}>
        <Button
          variant="outlined"
          size="small"
          onClick={handleCheckedRight}
          disabled={leftOptionsChecked.length === 0}
          aria-label="move selected right"
        >
          &gt;
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={handleCheckedLeft}
          disabled={rightOptionsChecked.length === 0}
          aria-label="move selected left"
        >
          &lt;
        </Button>
      </Stack>
      {/* 오른쪽 옵션 리스트 */}
      <Stack sx={{ flex: '1 1 0', bgcolor: 'background.paper' }}>
        <Stack direction="row" alignItems="center" spacing={4} sx={{ px: 4, py: 3 }}>
          <Checkbox
            onClick={handleToggleAll(filteredRightOptions)}
            checked={
              numberOfChecked(filteredRightOptions) === filteredRightOptions.length &&
              filteredRightOptions.length !== 0
            }
            indeterminate={
              numberOfChecked(filteredRightOptions) !== filteredRightOptions.length &&
              numberOfChecked(filteredRightOptions) !== 0
            }
            disabled={filteredRightOptions.length === 0}
          />
          <Typography sx={{ flexShrink: 0 }}>{rightOptionsTitle}</Typography>
          <Box sx={textFieldContainer}>
            <TextField
              variant="outlined"
              autoComplete="off"
              placeholder={rightSearchPlaceHolder}
              sx={textFieldStyle}
              value={rightSearch}
              onChange={(e) => setRightSearch(e.target.value)}
            />
          </Box>
        </Stack>
        <Divider />
        <Options
          options={filteredRightOptions}
          checkedOptions={checkedOptions}
          onToggle={handleToggle}
          uniqueKey={uniqueKey}
          optionGenerator={optionGenerator}
        />
      </Stack>
    </Stack>
  );
};

export default TransferList;
