import { useCallback, useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { SearchForm } from 'components';
import { useTypedFormik } from 'hooks';

export type FilterTableFormValues = { filterText: string };

export type FilterTableFormProps = {
  initialValue: string;
  onSearch: (value: string) => void;
  legend: string;
  placeholder?: string;
  disabled?: boolean;
};

const FilterTableForm = (props: FilterTableFormProps) => {
  const { initialValue, onSearch, legend, placeholder = '검색어 입력', disabled = false } = props;

  const initialValues = useMemo<FilterTableFormValues>(
    () => ({ filterText: initialValue }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSubmit = useCallback(
    (values: FilterTableFormValues) => {
      onSearch(values.filterText);
    },
    [onSearch]
  );

  const formik = useTypedFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Typography component="legend" sx={visuallyHidden}>
        {legend}
      </Typography>
      <SearchForm
        onClickSearch={formik.handleSubmit}
        placeholder={placeholder}
        disabled={disabled}
        {...formik.getFieldProps('filterText')}
      />
    </Box>
  );
};

export default FilterTableForm;
