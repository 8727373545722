import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { CSAPI, ReplyCSData } from 'api';
import { useUser } from 'hooks';

import { ReplyCSMutationOptions } from './types';

const useReplyCS = (options?: ReplyCSMutationOptions) => {
  const { user } = useUser();

  const mutateFn = useCallback(
    (data: ReplyCSData) => {
      return CSAPI.replyCS(user.accessToken, data);
    },
    [user.accessToken]
  );

  return useMutation(mutateFn, options);
};

export default useReplyCS;
