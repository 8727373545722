import { has } from 'lodash-es';

export default function hasOwnProperty<T extends object, K extends PropertyKey>(
  obj: T,
  prop: K | K[]
): obj is T & Record<K, unknown> {
  let result = true;

  if (Array.isArray(prop)) {
    prop.forEach((key) => {
      if (!has(obj, key)) {
        result = false;
      }
    });

    return result;
  }

  return has(obj, prop);
}
