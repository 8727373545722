import { useState, useCallback, MouseEvent, FocusEvent } from 'react';

import { Stack, Chip, TextField } from '@mui/material';
import { isFunction } from 'lodash-es';

import { getThemedProp, SSO } from 'styles';

import { TaggedInputProps } from './types';

const taggedInputContainer: SSO[] = [
  {
    width: 1,
    p: '9px',
    borderRadius: 1,
    border: 1,
  },
  getThemedProp('borderColor', 'rgba(0, 0, 0, 0.23)', 'rgba(255, 255, 255, 0.23)'),
];

const hoveredStyle = getThemedProp('borderColor', 'grey.900', 'common.white');
const focusedStyle = getThemedProp('borderColor', '#1976d2', '#90caf9');
const errorStyle: SSO = { borderColor: 'status.error' };

const textFieldStyle: SSO = {
  flex: '1 1 0',
  minWidth: 200,
  m: '3px',
  '& .MuiOutlinedInput-input': {
    px: '3px',
    py: '4.5px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

const TaggedInput = (props: TaggedInputProps) => {
  const {
    value,
    onChange,
    tagList,
    placeholder,
    onKeyUp,
    onDeleteTag,
    id,
    error = false,
    onBlur,
    size = 'medium',
  } = props;
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);

  const handleDelete = useCallback(
    (targetIdx: number) => (e: MouseEvent) => {
      e.stopPropagation();
      onDeleteTag(targetIdx);
    },
    [onDeleteTag]
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      setFocused(false);

      if (isFunction(onBlur)) {
        onBlur(e);
      }
    },
    [onBlur]
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      flexWrap="wrap"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={[
        ...taggedInputContainer,
        size === 'small' && { p: 0 },
        hovered && hoveredStyle,
        focused && focusedStyle,
        error && errorStyle,
      ]}
    >
      {tagList.map((tag, idx) => (
        <Chip
          key={`${tag}-${idx}`}
          label={tag}
          onDelete={handleDelete(idx)}
          size={size}
          sx={{ m: '3px' }}
        />
      ))}
      <TextField
        id={id}
        placeholder={placeholder}
        onFocus={() => setFocused(true)}
        onBlur={handleBlur}
        value={value}
        onChange={onChange}
        onKeyUp={onKeyUp}
        sx={textFieldStyle}
        autoComplete="off"
      />
    </Stack>
  );
};

export default TaggedInput;
