import { FileExtension } from './types';

export const formatMapper: { [K in FileExtension]: string } = {
  png: 'image/png',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  pdf: 'application/pdf',
  txt: 'text/plain',
  csv: 'text/csv',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  mp4: 'video/mp4',
  webm: 'video/webm',
  ogg: 'video/ogg',
};

export const DEFAULT_MAX_FILE_SIZE = 5000 * 1000;
