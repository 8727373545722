import { memo } from 'react';

import { Box, Stack } from '@mui/material';

import { fullHeight } from 'styles';

export type TextCellProps = {
  textAlign?: 'start' | 'center' | 'end';
  children: string;
};

const TextCell = memo((props: TextCellProps) => {
  const { textAlign = 'center', children } = props;

  return (
    <Stack sx={fullHeight}>
      <Box
        sx={{
          m: 'auto 0',
          whiteSpace: 'nowrap',
          textAlign,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {children}
      </Box>
    </Stack>
  );
});

TextCell.displayName = 'TextCell';

export default TextCell;
