import { createTuple, moment } from 'utils';

export const today = createTuple(moment().startOf('day'), moment().endOf('day'));
export const yesterday = createTuple(
  moment().subtract(1, 'days').startOf('day'),
  moment().subtract(1, 'days').endOf('day')
);

export const startOfWeek = moment().startOf('week');
export const endOfWeek = moment().endOf('week');

export const thisWeek = createTuple(
  startOfWeek,
  endOfWeek.isAfter(moment().endOf('day')) ? moment().endOf('day') : endOfWeek
);

export const lastWeek = createTuple(
  moment().subtract(1, 'week').startOf('week'),
  moment().subtract(1, 'week').endOf('week')
);

export const startOfMonth = moment().startOf('month');
export const endOfMonth = moment().endOf('month');

export const thisMonth = createTuple(
  startOfMonth,
  endOfMonth.isAfter(moment().endOf('day')) ? moment().endOf('day') : endOfMonth
);

export const lastMonth = createTuple(
  moment().subtract(1, 'month').startOf('month'),
  moment().subtract(1, 'month').endOf('month')
);

export const getRecentDays = (n: number) => {
  return createTuple(
    moment()
      .subtract(n - 1, 'days')
      .startOf('day'),
    moment().endOf('day')
  );
};
