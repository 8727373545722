import { CompanyCategoryFlag } from 'api';

export default function getCompanyCategory(companyCategoryFlag: number) {
  let companyCategoryFlagCopied = companyCategoryFlag;
  const companyCategory: CompanyCategoryFlag[] = [];

  // extract companyType
  [
    CompanyCategoryFlag.광고주,
    CompanyCategoryFlag.대행사,
    CompanyCategoryFlag.랩사,
    CompanyCategoryFlag.PM,
  ].forEach((category) => {
    if (companyCategoryFlagCopied > 0 && companyCategoryFlagCopied >= category) {
      companyCategoryFlagCopied = companyCategoryFlagCopied & ~category;
      companyCategory.push(category);
    }
  });

  return companyCategory;
}
