import { memo, useMemo } from 'react';

import { useTheme } from '@mui/material';

import { CampaignStatus } from 'api';

import { CampaignStatusIconProps } from './types';

const CampaignStatusIcon = memo((props: CampaignStatusIconProps) => {
  const { status } = props;
  const { palette } = useTheme();

  const mappedColor = useMemo(
    () => ({
      [CampaignStatus.라이브]: palette.status.success,
      [CampaignStatus.정지]: palette.status.error,
      [CampaignStatus.테스트]: palette.status.test,
      [CampaignStatus.보상]: palette.status.warning,
      [CampaignStatus.임시저장]: palette.status.success,
    }),
    [palette]
  );

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      {status === CampaignStatus.임시저장 ? (
        <rect
          x="2"
          y="2"
          width="12"
          height="12"
          rx="6"
          stroke={mappedColor[status]}
          strokeWidth="4"
        />
      ) : (
        <rect width="16" height="16" rx="8" fill={mappedColor[status]} />
      )}
    </svg>
  );
});

CampaignStatusIcon.displayName = 'CampaignStatusIcon';

export default CampaignStatusIcon;
