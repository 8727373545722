import { ThemeProvider } from '@mui/material';

import getTheme from 'styles/get-theme';
import usePaletteMode from 'styles/use-palette-mode';

import ResetStyle from './reset-style';
import { CustomThemeProviderProps } from './types';

const CustomThemeProvider = (props: CustomThemeProviderProps) => {
  const { children } = props;
  const [paletteMode] = usePaletteMode();
  const theme = getTheme(paletteMode);

  return (
    <ThemeProvider theme={theme}>
      <ResetStyle />
      {children}
    </ThemeProvider>
  );
};

export default CustomThemeProvider;
