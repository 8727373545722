import { isString } from 'lodash-es';

import { AnyObject } from './types';

export function intersection<T extends string | number>(a: T[], b: T[]): T[];
export function intersection<T extends AnyObject>(a: T[], b: T[], key: keyof T): T[];
export function intersection<T extends string | number | AnyObject>(a: T[], b: T[], key?: keyof T) {
  if (isString(key)) {
    const typedA = a as AnyObject[];
    const typedB = b as AnyObject[];

    return typedA.filter((valueA) => {
      const uniqueValue = typedB.find((valueB) => valueB[key] === valueA[key]);
      return uniqueValue !== undefined;
    });
  }

  const typedA = a as (string | number)[];
  const typedB = b as (string | number)[];
  return typedA.filter((value) => typedB.indexOf(value) !== -1);
}
