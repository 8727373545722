import { isString } from 'lodash-es';

import { not } from './not';
import { AnyObject } from './types';

export function union<T extends string | number>(a: T[], b: T[]): T[];
export function union<T extends AnyObject>(a: T[], b: T[], key: keyof T): T[];
export function union<T extends string | number | AnyObject>(a: T[], b: T[], key?: keyof T) {
  if (isString(key)) {
    const typedA = a as AnyObject[];
    const typedB = b as AnyObject[];

    return [...typedA, ...not(typedB, typedA, key)];
  }

  const typedA = a as (string | number)[];
  const typedB = b as (string | number)[];
  return [...typedA, ...not(typedB, typedA)];
}
