import { UserRole } from 'api';

export enum UserStatus {
  LoggedIn = 0,
  LoggedOut = 1,
  Expired = 2,
}

export type User = {
  name: string;
  userId: string;
  rememberMe: boolean;
  accessToken: string;
  status: UserStatus;
  roles: UserRole[];
};
