import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

import { MediaAPI } from 'api';
import useUser from 'hooks/use-user';

import { MediaListQueryOptions, MediaListQueryFn, MediaListQueryKey } from './types';

const useMediaList = (options?: MediaListQueryOptions) => {
  const { user } = useUser();
  const queryKey = useMemo<MediaListQueryKey>(() => 'mediaList', []);

  const queryFn = useCallback<MediaListQueryFn>(() => {
    return MediaAPI.getMediaList(user.accessToken);
  }, [user.accessToken]);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, options);

  const mediaList = useMemo(() => {
    return response ? response.data.MediaList : [];
  }, [response]);

  return {
    queryKey,
    mediaList,
    ...rest,
  };
};

export default useMediaList;
