import { get, post } from 'api/request';

import * as T from './types';

export default class ManagementAPI {
  static getAllCompanyList(token: string) {
    return get<T.AllCompanyListResponseSuccess>(
      '/api/v1/management/membership/AllCompanyList',
      token
    );
  }

  static getAllCompanyMemberList(token: string, params: T.AllCompanyMemberListParams) {
    return get<T.AllCompanyMemberListResponseSuccess, T.AllCompanyMemberListParams>(
      '/api/v1/management/membership/CompanyMemberList',
      token,
      params
    );
  }

  static updateCompany(token: string, data: T.UpdateCompanyData) {
    return post('/api/v1/management/membership/UpdateCompany', data, token);
  }

  static createCompany(token: string, data: T.CreateCompanyData) {
    return post<T.CreateCompanyResponseSuccess, T.CreateCompanyData>(
      '/api/v1/management/membership/CreateCompany',
      data,
      token
    );
  }

  static getAllCustomerList(token: string) {
    return get<T.AllCustomerListResponseSuccess>(
      '/api/v1/management/membership/AllCustomerList',
      token
    );
  }

  static createCustomer(token: string, data: T.CreateCustomerData) {
    return post<T.CreateCustomerResponseSuccess, T.CreateCustomerData>(
      '/api/v1/management/membership/CreateCustomer',
      data,
      token
    );
  }

  static updateCustomer(token: string, data: T.UpdateCustomerData) {
    return post('/api/v1/management/membership/UpdateCustomer', data, token);
  }

  static deleteCustomer(token: string, data: T.DeleteCustomerData) {
    return post('/api/v1/management/membership/DeleteCustomer', data, token);
  }

  /** faq */
  static getFAQList(token: string) {
    return get<T.GetFAQListResponseSuccess>('/api/v1/management/faq/FAQList', token);
  }

  static addFAQ(token: string, data: T.AddFAQData) {
    return post('/api/v1/management/faq/AddFAQ', data, token);
  }

  static updateFAQ(token: string, data: T.UpdateFAQData) {
    return post('/api/v1/management/faq/updateFAQ', data, token);
  }

  static updateFAQPriority(token: string, data: T.UpdateFAQPriorityData) {
    return post('/api/v1/management/faq/UpdateFAQPriority', data, token);
  }
}
