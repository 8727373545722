import { CSSProperties } from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import RouterLink from 'components/router-link';
import { SSO } from 'styles';

import { DefaultNavLinkIconProps, GroupedNavLinkItemProps, NavLinkItemProps } from './types';

export const DefaultNavLinkIcon = (props: DefaultNavLinkIconProps) => (
  <Box
    aria-hidden
    sx={{
      display: 'inline-block',
      width: 24,
      height: 24,
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 4,
        height: 4,
        bgcolor: props.selected ? 'common.white' : 'blueGrey.500',
        borderRadius: 1,
      },
    }}
  />
);

const activeItemStyle: SSO = {
  bgcolor: 'rgba(255, 255, 255, 0.04)',
};

const listItemButtonStyle: SSO = {
  ':hover': activeItemStyle,
  '&.Mui-selected': activeItemStyle,
  '&.Mui-selected:hover': activeItemStyle,
};

const navLinkItemStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  overflow: 'hidden',
};

const getItemColor = (selected = false): SSO => ({
  color: selected ? 'common.white' : 'blueGrey.500',
});

export const NavLinkItem = (props: NavLinkItemProps) => {
  const { to, text, icon, pathname, onClick, sx = [] } = props;
  const selected = pathname.startsWith(to);
  const itemColor = getItemColor(selected);

  return (
    <ListItemButton
      selected={selected}
      onClick={onClick}
      sx={[listItemButtonStyle, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      <RouterLink to={to} style={navLinkItemStyle}>
        <ListItemIcon sx={itemColor}>
          {icon || <DefaultNavLinkIcon selected={selected} />}
        </ListItemIcon>
        <ListItemText primary={text} sx={[itemColor, { flexShrink: 0 }]} />
      </RouterLink>
    </ListItemButton>
  );
};

export const GroupedNavLinkItem = (props: GroupedNavLinkItemProps) => {
  const { to, text, icon, subLinks, onClick, expanded, pathname } = props;
  const selected = pathname.startsWith(to);
  const itemColor = getItemColor(selected);

  return (
    <Box>
      <ListItemButton
        selected={selected}
        onClick={onClick}
        sx={{
          ':hover': activeItemStyle,
          '&.Mui-selected': { bgcolor: 'inherit' },
          '&.Mui-selected:hover': { bgcolor: 'inherit' },
        }}
      >
        <ListItemIcon sx={itemColor}>{icon}</ListItemIcon>
        <ListItemText primary={text} sx={[itemColor, { flexShrink: 0 }]} />
        {expanded ? <ExpandLess sx={itemColor} /> : <ExpandMore sx={itemColor} />}
      </ListItemButton>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subLinks.map((subLink, idx) => (
            <NavLinkItem
              key={idx}
              pathname={pathname}
              to={subLink.to}
              text={subLink.text}
              icon={subLink.icon}
              sx={{
                '.MuiTypography-root': {
                  typography: 'body2',
                },
              }}
            />
          ))}
        </List>
      </Collapse>
    </Box>
  );
};
