import { ChangeEvent, memo, useCallback } from 'react';

import { UpdateMediaStatusType } from 'api';
import { SwitchCell } from 'components';

import { ToggleExposedStateProps } from './types';

const ToggleExposedState = memo((props: ToggleExposedStateProps) => {
  const { campaignKey, mediaKey, isOpenToUser, onToggleState } = props;

  const handleToggleState = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onToggleState({
        campaignKey,
        mediaKey,
        toggleType: UpdateMediaStatusType.OpenUser,
        toggleStatus: checked,
      });
    },
    [campaignKey, mediaKey, onToggleState]
  );

  const label = `노출 상태 ${isOpenToUser ? '활성화' : '비활성화'}`;

  return <SwitchCell label={label} checked={isOpenToUser} onChange={handleToggleState} />;
});

ToggleExposedState.displayName = 'ToggleExposedState';

export default ToggleExposedState;
