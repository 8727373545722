import { useCallback } from 'react';

import { atom, useRecoilState } from 'recoil';

import { DEFAULT_VALUE, MODAL_STATE_KEY } from './const';
import { IndexedModal } from './types';

const modalState = atom<IndexedModal>({
  key: MODAL_STATE_KEY,
  default: DEFAULT_VALUE,
});

const useModal = (modalName: string) => {
  const [modal, setModal] = useRecoilState(modalState);
  const open = modal[modalName];

  const _setModal = useCallback(
    (open: boolean) => {
      setModal((prev) => ({ ...prev, [modalName]: open }));
    },
    [modalName, setModal]
  );

  const handleOpenModal = useCallback(() => {
    setModal((prev) => ({ ...prev, [modalName]: true }));
  }, [modalName, setModal]);

  const handleCloseModal = useCallback(() => {
    setModal((prev) => ({ ...prev, [modalName]: false }));
  }, [modalName, setModal]);

  const removeModal = useCallback(() => {
    setModal((prev) => {
      const { [modalName]: removedModal, ...rest } = prev;
      return rest;
    });
  }, [modalName, setModal]);

  return {
    open: Boolean(open),
    setModal: _setModal,
    handleOpenModal,
    handleCloseModal,
    removeModal,
  };
};

export default useModal;
