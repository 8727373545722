import moment from './moment';

const numberFormat = Intl.NumberFormat().format;
const priceFormat = Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format;

export function formatNumber(value: number, isPrice = false) {
  return isPrice ? priceFormat(value) : numberFormat(value);
}

export function formatDate(date: number, style = 'YYYY-MM-DD HH:mm') {
  return moment.unix(date).format(style);
}
