import { MediaIntegrationType, ParticipationType, PlatformType } from 'api/campaign';
import { MediaPlatformTag } from 'api/media';
import { ResponseSuccess } from 'api/types';

/**
 * 요약, 참여 적립, 간편 적립, 뉴스 적립, 캠페인 리포트 요청 params
 */
export type ReportParams = {
  startDate: string;
  endDate: string;
};

/**
 * 리포트 기준
 */
export enum ReportSearchType {
  전환건수 = 1,
  매출 = 2,
  순매출 = 3,
  매체비 = 4,
  마진 = 5,
}

/**
 * 매출 리포트 - 상단 대시보드 데이터
 */
export type SalesReportDashboardResponseSuccess = ResponseSuccess & {
  DashboardReport: {
    yesterDayCompareRevenue: number;
    yesterDayComparePercentage: number;
    latestWeekCompareRevenue: number;
    latestWeekComparePercentage: number;
    thisMonthCompareRevenue: number;
    thisMonthComparePercentage: number;
  };
};

/**
 * 참여적립 정보 by date
 */
export type GeneralCampaignReport = {
  reportDate: string; // 일
  grossRevenue: number; // 매출?
  commission: number; // 수수료
  netRevenue: number; // 순매출
  mediaCost: number; // 매체비
  revenue: number; // 마진
  participationCount: number; // 전환 건수
  adCount: number; // 캠페인 수
};

/**
 * 매출 리포트 - 참여적립 리포트
 */
export type GeneralCampaignReportResponseSuccess = ResponseSuccess & {
  CampaignReport: GeneralCampaignReport[];
};

/**
 * 간편적립 정보 by date
 */
export type CpmReport = {
  reportDate: string; // '2022-12-01'
  impression: number; // 노출
  click: number; // 클릭
  grossRevenue: number; // 매출
  netRevenue: number; // 순매출
  mediaCost: number; // 매체비
  revenue: number; // 마진
  commission: number; // 수수료 (사용 X)
};

/**
 * 간편적립 정보 detail by date
 */
export type CpmReportV2 = {
  integrationNo: number;
  integrationName: string;
  reportDate: string; // '2022-12-01'
  impression: number; // 노출
  click: number; // 클릭
  grossRevenue: number; // 매출
  netRevenue: number; // 순매출
  mediaCost: number; // 매체비
  revenue: number; // 마진
  commission: number; // 수수료 (사용 X)
};

/**
 * 매출 리포트 - 간편적립 리포트
 */
export type CpmReportResponseSuccess = ResponseSuccess & {
  CpmReport: CpmReport[];
};

/**
 * 매출 리포트 - 간편적립 리포트V2
 */
export type CpmReportV2ResponseSuccess = ResponseSuccess & {
  CpmReport: CpmReportV2[];
};

/**
 * 뉴스적립 정보 by date
 */
export type NewsReport = {
  reportDate: string; // "2022-12-01"
  impression: number;
  click: number;
  grossRevenue: number;
  netRevenue: number;
  mediaCost: number;
  revenue: number;
  commission: number;
};

/**
 * 뉴스적립 정보 detail by date
 */
export type NewsReportV2 = {
  integrationNo: number;
  integrationName: string;
  reportDate: string; // "2022-12-01"
  impression: number;
  click: number;
  grossRevenue: number;
  netRevenue: number;
  mediaCost: number;
  revenue: number;
  commission: number;
};

/**
 * 매출 리포트 - 뉴스적립 리포트
 */
export type NewsReportResponseSuccess = ResponseSuccess & {
  NewsReport: NewsReport[];
};

/**
 * 매출 리포트 - 뉴스적립 리포트V2
 */
export type NewsReportV2ResponseSuccess = ResponseSuccess & {
  NewsReport: NewsReportV2[];
};

/**
 * 캠페인 리포트
 */
export type CampaignReport = {
  campaignKey: string;
  campaignName: string;
  completeCount: number;
  grossRevenue: number;
  netRevenue: number;
  mediaCost: number;
  revenue: number;
  dailyCompleteCountList: number[]; // 조회 기간에 해당하는 각 날짜별 전환 건수
  dailyGrossRevenueList: number[]; // 조회 기간에 해당하는 각 날짜별 매출
  dailyNetRevenueList: number[]; // 조회 기간에 해당하는 각 날짜별 순매출
  dailyMediaCostList: number[]; // 조회 기간에 해당하는 각 날짜별 매체비
  dailyRevenueList: number[]; // 조회 기간에 해당하는 각 날짜별 마진
  participationTypeNo: ParticipationType;
};

/**
 * 캠페인 리포트 조회 결과
 */
export type CampaignReportResponseSuccess = ResponseSuccess & {
  CampaignRevenueReport: CampaignReport[];
};

/**
 * 캠페인 리포트 상세 조회 params
 */
export type CampaignReportDetailParams = ReportParams & {
  campaignKey: string;
  searchType: ReportSearchType;
};

export type CampaignDailyReport = {
  dailyValue: number;
  totalValue: number;
  mediaKey: number;
  mediaName: string;
};

export type CampaignReportDetail = {
  reportDate: string;
  dailyTotalValue: number;
  campaignDailyReportList: CampaignDailyReport[];
};

/**
 * 캠페인 리포트 상세 조회
 */
export type CampaignReportDetailResponseSuccess = ResponseSuccess & {
  CampaignKey: string;
  CampaignName: string;
  CampaignDetailReport: CampaignReportDetail[];
};

/**
 * 매체 리포트
 */
export type MediaReport = {
  mediaKey: number;
  mediaName: string;
  grossRevenue: number;
  netRevenue: number;
  mediaCost: number;
  revenue: number;
  completeCount: number;
  mediaPlatformTagNo: MediaPlatformTag;
  integrationType: MediaIntegrationType;
  platformType: PlatformType;
  companyName: string;
  dailyCompleteCountList: number[];
  dailyGrossRevenueList: number[];
  dailyNetRevenueList: number[];
  dailyMediaCostList: number[];
  dailyRevenueList: number[];
};

/**
 * 매체 리포트 조회
 */
export type MediaReportResponseSuccess = ResponseSuccess & {
  MediaRevenueReport: MediaReport[];
};

/**
 * 매체 리포트 상세 조회 params
 */
export type MediaReportDetailParams = ReportParams & {
  mediaKey: number;
  searchType: ReportSearchType;
};

export type MediaDailyReport = {
  dailyValue: number;
  totalValue: number;
  campaignKey: string;
  campaignName: string;
};

export type MediaReportDetail = {
  reportDate: string;
  dailyTotalValue: number;
  mediaDailyReportList: MediaDailyReport[];
};

/**
 * 캠페인 리포트 상세 조회
 */
export type MediaReportDetailResponseSuccess = ResponseSuccess & {
  MediaKey: number;
  MediaName: string;
  MediaDetailReport: MediaReportDetail[];
};

export type SummaryReport = {
  reportDate: string; //yyyy-mm-dd
  grossRevenue: number;
  commission: number;
  netRevenue: number;
  mediaCost: number;
  revenue: number;
  participationCount: number;
  adCount: number;
};

/**
 * 요약 리포트 조회
 */
export type SummaryReportResponseSuccess = ResponseSuccess & {
  SummaryReport: SummaryReport[];
};

export type WebtoonReport = NewsReport;
export type RVReport = NewsReport;
export type BenefitReport = NewsReport;

export type WebtoonReportV2 = NewsReportV2;
export type RVReportV2 = NewsReportV2;
export type BenefitReportV2 = NewsReportV2;
/**
 * 웹툰 리포트 조회
 */
export type WebtoonReportResponseSuccess = ResponseSuccess & {
  WebtoonReport: WebtoonReportV2[];
};

/**
 * RV 리포트 조회
 */
export type RVReportResponseSuccess = ResponseSuccess & {
  VideoReport: RVReportV2[];
};

/**
 * 혜택 리포트 조회
 */
export type BenefitReportResponseSuccess = ResponseSuccess & {
  BenefitReport: BenefitReportV2[];
};
