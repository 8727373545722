import { ParticipationType } from 'api/campaign';
import { CompanyCategoryFlag } from 'api/management';
import { ResponseSuccess } from 'api/types';

export enum DashboardTab {
  Today = 0,
  Yesterday = 1,
  LastWeek = 2,
  ThisMonth = 3,
  LastMounth = 4,
}

export type DashboardInfoParams = {
  tabType: DashboardTab;
};

export type DashboardInfoItem = {
  grossRevenue: number; // 매출
  netRevenue: number; // 순매출
  mediaCost: number; // 매체비
  revenue: number; // 마진
  participationCount: number; // 참여건수
  campaignCount: number; // 캠페인 개수
  commission: number; // 수수료 합
  isOriginalData: boolean; // true = 타겟 정보, false = 비교군 정보
};

export type DashboardInfoResponseSuccess = ResponseSuccess & {
  DashboardInfo: [DashboardInfoItem, DashboardInfoItem];
};

export type HourlyRevenueParams = {
  searchDate: string; // YYYY-MM-DD
};

export type HourlyInfoItem = {
  dateTime: string; // YYYY-MM-DD
  report_hour: number;
  campaignGross: number;
};

export type WeeklyAverageHourInfoItem = {
  report_hour: number;
  campaignGross: number;
};

export type HourlyRevenueResponseSuccess = ResponseSuccess & {
  HourlyInfo: HourlyInfoItem[];
  WeeklyAverageHourInfo: WeeklyAverageHourInfoItem[];
};

export type CampaignSales = {
  campaignKey: string;
  campaignName: string;
  participationTypeNo: ParticipationType;
  campaignCompanyCategoryType: CompanyCategoryFlag;
  campaignCompanyName: string;
  campaignGross: number;
  mediaCost: number;
  revenue: number;
  participateCount: number;
  commisionGross: number;
};

export type TopCampaignRevenueParams = {
  startDate: string; // YYYY-MM-DD
  endDate: string; // YYYY-MM-DD
};

export type TopCampaignRevenueResponseSuccess = ResponseSuccess & {
  TopCampaignSalesList: CampaignSales[];
};

export type MediaSales = {
  mediaKey: number;
  mediaName: string;
  campaignGross: number;
  mediaCost: number;
  revenue: number;
  participateCount: number;
  campaignCount: number;
  commisionGross: number;
};

export type TopMediaRevenueParams = TopCampaignRevenueParams;

export type TopMediaRevenueResponseSuccess = ResponseSuccess & {
  TopMediaSalesList: MediaSales[];
};
