export default function addScrollBarStyle() {
  const outerElem = document.createElement('div');
  outerElem.setAttribute('style', 'width:30px; height:30px; overflow-y: auto;');

  const innerElem = document.createElement('div');
  innerElem.setAttribute('style', 'width:100%; height:40px');

  outerElem.appendChild(innerElem);
  document.body.appendChild(outerElem);

  const innerElemWidth = outerElem.children[0].clientWidth;
  const hasObtrusiveScrollbar = 30 - innerElemWidth !== 0;

  if (hasObtrusiveScrollbar) {
    document.body.classList.add('has-obtrusive-scrollbar');
  }

  document.body.removeChild(outerElem);
}
