import { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Stack, SxProps, TableSortLabel, Theme } from '@mui/material';
import { flexRender, Header } from '@tanstack/react-table';
import { isFunction } from 'lodash-es';

import { fullHeight, fullWidth, SSO } from 'styles';
import { AnyObject } from 'utils';

import { cellStyle } from './const';
import { CHECKBOX_COLUMN_ID } from './use-table';

export type ColumnHeaderProps<T extends AnyObject> = {
  header: Header<T, unknown>;
  sx?: SxProps<Theme>;
};

const tableSortLabelStyle: SSO = {
  '.MuiTableSortLabel-icon.MuiTableSortLabel-icon': {
    color: 'inherit',
    width: 16,
    height: 16,
  },
};

const ColumnHeader = <T extends AnyObject>(props: ColumnHeaderProps<T>) => {
  const { header, sx = [] } = props;
  const [, setMouseDown] = useState(false);

  const canSort = header.column.getCanSort();
  const isSorted = header.column.getIsSorted();

  const toggleSortingHandler = useMemo(
    () => header.column.getToggleSortingHandler(),
    [header.column]
  );

  const canResize = header.column.getCanResize();
  const isResizing = header.column.getIsResizing();
  const resizeHandler = useMemo(() => header.getResizeHandler(), [header]);

  /**
   * column resize 시 cursor 스타일 업데이트
   */
  const updateUserSelectStyle = useCallback((selectable: boolean) => {
    const rootEl = document.getElementById('root');

    if (rootEl) {
      rootEl.style.cursor = selectable ? '' : 'col-resize';
      rootEl.style.userSelect = selectable ? '' : 'none';
    }
  }, []);

  const handleClickSortLabel = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (isFunction(toggleSortingHandler)) {
        toggleSortingHandler(e);
      }
    },
    [toggleSortingHandler]
  );

  const handleClickResizer = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      setMouseDown(true);
      updateUserSelectStyle(false);
      resizeHandler(e);
    },
    [resizeHandler, updateUserSelectStyle]
  );

  /**
   * column resize 종료 시 cursor 스타일 업데이트
   */
  useEffect(() => {
    const handleMouseUp = () => {
      setMouseDown((mouseDown) => {
        if (mouseDown) {
          updateUserSelectStyle(true);
          return false;
        }

        return mouseDown;
      });
    };

    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [updateUserSelectStyle]);

  const isCheckbox = header.column.id === CHECKBOX_COLUMN_ID;
  const columnEl = header.isPlaceholder
    ? null
    : flexRender(header.column.columnDef.header, header.getContext());

  return (
    <Box
      key={header.id}
      role="columnheader"
      sx={[cellStyle, { p: 2 }, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={[fullWidth, fullHeight]}
      >
        {/* 체크박스 혹은 컬럼명 */}
        {isCheckbox ? (
          columnEl
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            onClick={handleClickSortLabel}
            sx={[canSort && { cursor: 'pointer' }]}
          >
            <Box
              component="p"
              sx={[
                {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: 'bold',
                },
              ]}
            >
              {columnEl}
            </Box>
            {/* 정렬 아이콘 */}
            {canSort && (
              <TableSortLabel
                sx={tableSortLabelStyle}
                active={Boolean(isSorted)}
                direction={isSorted || undefined}
              />
            )}
          </Stack>
        )}
        {/* 리사이즈 핸들 */}
        {canResize && (
          <Box
            sx={[
              {
                position: 'absolute',
                top: 0,
                right: 0,
                width: 10,
                height: 1,
                ':hover': {
                  cursor: 'col-resize',
                },
              },
              isResizing && {
                cursor: 'col-resize',
              },
            ]}
            onMouseDown={handleClickResizer}
          />
        )}
      </Stack>
    </Box>
  );
};

export default ColumnHeader;
