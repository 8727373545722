import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

import { CampaignAPI } from 'api';
import useUser from 'hooks/use-user';

import * as T from './types';

const useStandardMediaPriceList = (options?: T.StandardMediaPriceListQueryOptions) => {
  const { user } = useUser();

  const queryKey = useMemo<T.StandardMediaPriceListQueryKey>(() => 'standardMediaPriceList', []);

  const queryFn = useCallback<T.StandardMediaPriceListQueryFn>(() => {
    return CampaignAPI.getStandardMediaPriceList(user.accessToken);
  }, [user.accessToken]);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, options);

  const standardMediaPriceList = useMemo(() => {
    return response ? response.data.MediaPriceList : [];
  }, [response]);

  return {
    queryKey,
    standardMediaPriceList,
    ...rest,
  };
};

export default useStandardMediaPriceList;
