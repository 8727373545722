import { useMutation } from 'react-query';

import { AccountAPI } from 'api';

const useChangePassword = () => {
  const mutation = useMutation(AccountAPI.changePassword);
  return mutation;
};

export default useChangePassword;
