import { SSO, getThemedProp } from 'styles';

import { FOOTER_HEIGHT } from './const';

export const footerStyle: SSO[] = [
  { height: FOOTER_HEIGHT, borderTop: 1 },
  getThemedProp('borderColor', 'line.grey', 'line.darkGrey'),
];

export const dtStyle: SSO = {
  '&::after': {
    content: '":"',
    display: 'inline-block',
    mr: 1,
  },
};
