import { isString } from 'lodash-es';

import { ReportSearchType } from 'api';
import { useQueryParams, ParsedQs, getValidSearchDates } from 'hooks';

import { MIN_QUERY_DATE } from './const';

const useReportParams = () => {
  return useQueryParams(getReportParams);
};

export default useReportParams;

function getReportParams(parsedSearch: ParsedQs): {
  startDate: string;
  endDate: string;
  searchType: ReportSearchType;
} {
  const { startDate, endDate, searchType } = parsedSearch;

  const searchDates = getValidSearchDates({
    minDate: MIN_QUERY_DATE,
    startDate,
    endDate,
  });

  const parsedSeachType = isString(searchType) ? parseInt(searchType) : ReportSearchType.전환건수;

  return {
    ...searchDates,
    searchType: ReportSearchType[parsedSeachType] ? parsedSeachType : ReportSearchType.전환건수,
  };
}
