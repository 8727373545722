import { LazyExoticComponent, Suspense, lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

const DefaultErrorFallback = () => {
  return <span>로드 실패</span>;
};

const SuspendedComponent = ({ element }: { element: JSX.Element }) => {
  return (
    <ErrorBoundary FallbackComponent={DefaultErrorFallback}>
      <Suspense fallback={null}>{element}</Suspense>
    </ErrorBoundary>
  );
};

/**Lazy Components */
const LazyDatePicker = lazy(() =>
  import('./date-picker').then((module) => {
    return { default: module.DatePicker };
  })
);

const LazyTimePicker = lazy(() =>
  import('./date-picker').then((module) => {
    return { default: module.TimePicker };
  })
);

const LazyDateRangePicker = lazy(() => import('./date-range-picker'));

const LazyLogo = lazy(() => import('./logo'));

const LazyAntdDateRangePicker = lazy(
  () => import('./antd-date-range-picker/antd-date-range-picker')
);

export const LazyPieChart = lazy(() =>
  import('./chart/pie-chart').then((m) => {
    return { default: m.default };
  })
);
export const LazyStackedAreaChart = lazy(() => import('./chart/stacked-area-chart'));

/**Suspended Components*/
type SuspendedLazyComponentType<
  T extends LazyExoticComponent<(props: Parameters<T>[0]) => JSX.Element>
> = (props: Parameters<T>[0]) => JSX.Element;

export const TimePicker: SuspendedLazyComponentType<typeof LazyTimePicker> = (props) => {
  return <SuspendedComponent element={<LazyTimePicker {...props} />} />;
};

export const DateRangePicker: SuspendedLazyComponentType<typeof LazyDateRangePicker> = (props) => {
  return <SuspendedComponent element={<LazyDateRangePicker {...props} />} />;
};

export const AntdDateRangePicker: SuspendedLazyComponentType<typeof LazyAntdDateRangePicker> = (
  props
) => {
  return <SuspendedComponent element={<LazyAntdDateRangePicker {...props} />} />;
};

export const Logo: SuspendedLazyComponentType<typeof LazyLogo> = (props) => {
  return <SuspendedComponent element={<LazyLogo {...props} />} />;
};

export const DatePicker: SuspendedLazyComponentType<typeof LazyDatePicker> = (props) => {
  return <SuspendedComponent element={<LazyDatePicker {...props} />} />;
};

export const PieChart: SuspendedLazyComponentType<typeof LazyPieChart> = (props) => {
  return <SuspendedComponent element={<LazyPieChart {...props} />} />;
};

export const StackedAreaChart: SuspendedLazyComponentType<typeof LazyStackedAreaChart> = (
  props
) => {
  return <SuspendedComponent element={<LazyStackedAreaChart {...props} />} />;
};
