import { useMemo } from 'react';

import { FormikHelpers } from 'formik';

import { csMacroMessage, csReplyFormValidationSchema } from './const';
import { ReplyCSFormValues, CSMacroType } from './types';

import useTypedFormik from '../use-typed-formik';

const useReplyCSForm = (
  onSubmit: (values: ReplyCSFormValues, formikHelpers: FormikHelpers<ReplyCSFormValues>) => void
) => {
  const initialValues = useMemo<ReplyCSFormValues>(
    () => ({
      csMacroType: CSMacroType['수동 입력'],
      csReplyMessage: csMacroMessage[CSMacroType['수동 입력']],
    }),
    []
  );

  const replyCSForm = useTypedFormik({
    initialValues,
    onSubmit,
    validationSchema: csReplyFormValidationSchema,
    validateOnMount: true,
  });

  const csMacroTypeOptions = useMemo(() => {
    return Object.keys(CSMacroType)
      .map((key) => Number(key))
      .filter((value): value is CSMacroType => !Number.isNaN(value));
  }, []);

  const handleChangeMacroType = (value: CSMacroType) => {
    replyCSForm.setValues({
      csMacroType: value,
      csReplyMessage: csMacroMessage[value],
    });
  };

  return {
    ...replyCSForm,
    csMacroTypeOptions,
    handleChangeMacroType,
  };
};

export default useReplyCSForm;
