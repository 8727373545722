import { memo, ReactNode, useCallback, useMemo } from 'react';

import {
  FormControl,
  FormLabel,
  ListSubheader,
  SelectProps,
  Select,
  Typography,
  MenuItem,
} from '@mui/material';

import { ParticipationType } from 'api';
import { getThemedProp, overflowY } from 'styles';

import { labelStyle, participationTypeByCategory, textFieldStyle } from './const';
import { ParticipationTypeFormValue, ParticipationTypeCategory } from './types';

export type SelectParticipationTypeProps = Omit<
  SelectProps<ParticipationTypeFormValue>,
  'displayEmpty' | 'renderValue' | 'sx' | 'MenuProps'
> & {
  helperText?: string | null;
  options?: ReactNode[];
};

const listSubheaderStyle = getThemedProp('bgcolor', 'grey.200', 'background.paper');

const SelectParticipationType = memo((props: SelectParticipationTypeProps) => {
  const { options, helperText, ...selectProps } = props;

  const renderParticipationOption = useCallback((value: ParticipationTypeFormValue) => {
    return value === '' ? '상품유형을 선택하세요.' : ParticipationType[value];
  }, []);

  const getParticipationTypeOptions = useCallback(
    (participationTypeCategory: ParticipationTypeCategory) => {
      return participationTypeByCategory[participationTypeCategory].map((type) => (
        <MenuItem value={type} key={type}>
          {ParticipationType[type]}
        </MenuItem>
      ));
    },
    []
  );

  const defaultOptions = useMemo(
    () => [
      <ListSubheader key={ParticipationTypeCategory.기본} sx={listSubheaderStyle}>
        기본
      </ListSubheader>,
      getParticipationTypeOptions(ParticipationTypeCategory.기본),
      <ListSubheader key={ParticipationTypeCategory.SNS} sx={listSubheaderStyle}>
        SNS
      </ListSubheader>,
      getParticipationTypeOptions(ParticipationTypeCategory.SNS),
      <ListSubheader key={ParticipationTypeCategory['간편 적립']} sx={listSubheaderStyle}>
        간편 적립
      </ListSubheader>,
      getParticipationTypeOptions(ParticipationTypeCategory['간편 적립']),
    ],
    [getParticipationTypeOptions]
  );

  return (
    <FormControl>
      <FormLabel htmlFor={selectProps.id} required sx={labelStyle}>
        상품유형
      </FormLabel>
      <Select
        {...selectProps}
        displayEmpty
        renderValue={renderParticipationOption}
        sx={textFieldStyle}
        MenuProps={{
          sx: { '.MuiPaper-root': overflowY },
          MenuListProps: {
            sx: { pt: 0 },
          },
        }}
      >
        {options ? options : defaultOptions}
      </Select>
      {selectProps.error && (
        <Typography fontSize="0.75rem" sx={{ color: 'status.error', mt: '3px', ml: '14px' }}>
          {helperText}
        </Typography>
      )}
    </FormControl>
  );
});

SelectParticipationType.displayName = 'SelectParticipationType';

export default SelectParticipationType;
