import { ReactQueryDevtools } from 'react-query/devtools';
import { RecoilRoot } from 'recoil';

import { Snackbar } from 'components';
import QueryClientProvider from 'query-client';
import RecoilObserver from 'recoil-observer';
import AppRouter from 'router';
import { CustomThemeProvider } from 'styles';

const App = () => (
  <RecoilRoot>
    <RecoilObserver componentName="App" />
    <QueryClientProvider>
      <CustomThemeProvider>
        <AppRouter />
        <Snackbar />
      </CustomThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  </RecoilRoot>
);

export default App;
