import jwtDecode, { JwtPayload } from 'jwt-decode';
import { isString } from 'lodash-es';
import { array, bool, number, object, string } from 'yup';

import { UserRole } from 'api';
import { moment } from 'utils';

import { User, UserStatus } from './types';

export const USER_STATE_KEY = 'user';

export const defaultUser: User = {
  name: '',
  userId: '',
  rememberMe: false,
  accessToken: '',
  status: UserStatus.LoggedOut,
  roles: [],
};

export const userValidationScehma = object({
  name: string().required().trim().min(1),
  userId: string().required().email(),
  rememberMe: bool().required(),
  accessToken: string()
    .required()
    .test('isValidToken', '유효하지 않은 토큰입니다.', (value, context) => {
      if (!isString(value)) return false;

      let decoded: JwtPayload & { email: string };

      try {
        decoded = jwtDecode(value);
      } catch (error) {
        return false;
      }

      const { userId } = context.parent as User;
      const { email, exp } = decoded;
      const now = moment();
      const expiredAt = moment.unix(exp as number);

      return userId === email && expiredAt.isValid() && expiredAt.isAfter(now);
    }),
  status: number()
    .required()
    .oneOf([UserStatus.LoggedIn, UserStatus.LoggedOut, UserStatus.Expired]),
  roles: array()
    .of(number())
    .required()
    .test('isValidRole', '접근 권한이 없습니다.', (value) => {
      return Array.isArray(value) && value.includes(UserRole.Read);
    }),
});
