import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { CompleteMultiRewardByTpNoData, CSAPI } from 'api';
import { useUser } from 'hooks';
import { MutationOptions } from 'query-client';

const useCompleteMultiRewardByTpNo = (options?: MutationOptions<CompleteMultiRewardByTpNoData>) => {
  const { user } = useUser();

  const mutateFn = useCallback(
    (data: CompleteMultiRewardByTpNoData) => {
      return CSAPI.completeMultiRewardByTpNo(user.accessToken, data);
    },
    [user.accessToken]
  );

  return useMutation(mutateFn, options);
};

export default useCompleteMultiRewardByTpNo;
