import { Box, SxProps, Theme } from '@mui/material';

import logoDark from 'assets/images/logo-dark-2x.png';
import logoLight from 'assets/images/logo-light-2x.png';
import { usePaletteMode } from 'styles';

const Logo = (props: { sx?: SxProps<Theme> }) => {
  const { sx = [] } = props;
  const [mode] = usePaletteMode();
  const logo = mode === 'light' ? logoLight : logoDark;

  return (
    <Box
      component="img"
      src={logo}
      alt="애드팝콘 리워드"
      sx={[{ display: 'block' }, ...(Array.isArray(sx) ? sx : [sx])]}
    />
  );
};

export default Logo;
