import { Box, SxProps, Theme } from '@mui/material';
import { Cell, flexRender } from '@tanstack/react-table';

import { AnyObject } from 'utils';

import { cellStyle } from './const';
import TextCell from './text-cell';

export type RowCellProps<T extends AnyObject> = {
  cell: Cell<T, unknown>;
  sx?: SxProps<Theme>;
};

const RowCell = <T extends AnyObject>(props: RowCellProps<T>) => {
  const { cell, sx = [] } = props;
  const { cell: cellData, meta } = cell.column.columnDef;

  const type = meta?.type || 'text';
  const textAlign = meta?.textAlign || 'start';
  const cellContent = flexRender(cellData, cell.getContext());

  return (
    <Box role="cell" sx={[cellStyle, { p: 1 }, ...(Array.isArray(sx) ? sx : [sx])]}>
      {type === 'text' ? (
        <TextCell textAlign={textAlign}>{cellContent as string}</TextCell>
      ) : (
        cellContent
      )}
    </Box>
  );
};

export default RowCell;
