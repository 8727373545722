import { Stack, Box } from '@mui/material';

import { fullWidth, fullHeight, getThemedProp } from 'styles';

import { MainProps } from './types';

import { FOOTER_HEIGHT } from '../footer';
import { HEADER_HEIGHT } from '../header';
import NavigationContainer, { NAV_MAX_WIDTH } from '../navigation';

const Main = (props: MainProps) => (
  <Stack
    direction="row"
    sx={[fullWidth, { height: `calc(100% - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)` }]}
  >
    <NavigationContainer />
    <Box
      sx={[
        fullHeight,
        getThemedProp('bgcolor', 'grey.50', 'darkGrey.900'),
        { minWidth: `calc(100% - ${NAV_MAX_WIDTH}px)`, flex: '1 1 0', p: 5 },
      ]}
    >
      {props.children}
    </Box>
  </Stack>
);

export default Main;
