export const newPasswordRegex = /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
export const numberOnlyRegex = /^(0|[1-9]+[0-9]*)$/;
export const numberGreaterThanZeroRegex = /^([1-9]+[0-9]*)$/;
export const validTimeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
export const validDateTimeRegex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})/;
export const decimalNumRegex =
  /(^(0|[1-9]+[0-9]*)$)|(^(0|[1-9]+[0-9]*)[.]$)|(^(0|[1-9]+[0-9]*)[.]([0-9]+[0-9]*)$)/;
export const lineBreakRegex = /(\r\n|\r|\n)/;
export const urlRegex =
  /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
