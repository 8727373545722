import { Stack, Typography } from '@mui/material';

import ChangePasswordFormContainer from 'components/change-password-form';
import Logo from 'components/logo';
import RouterLink from 'components/router-link';
import { getThemedProp } from 'styles';

import { HEADER_HEIGHT } from './const';
import DarkModeSwitch from './dark-mode-switch';
import UserMenuContainer from './user-menu';

const Header = () => (
  <Stack
    component="header"
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={[
      {
        height: HEADER_HEIGHT,
        pl: 14,
        pr: 3,
        borderBottom: 1,
      },
      getThemedProp('borderColor', 'line.grey', 'line.darkGrey'),
    ]}
  >
    <Typography component="h1">
      <RouterLink to="/dashboard">
        <Logo sx={{ height: HEADER_HEIGHT }} />
      </RouterLink>
    </Typography>
    <Stack direction="row" alignItems="center">
      <DarkModeSwitch />
      <UserMenuContainer />
    </Stack>
    <ChangePasswordFormContainer />
  </Stack>
);

export default Header;
