export function sortString(str1: string, str2: string, order: 'asc' | 'desc') {
  if (order === 'asc') {
    return str1 < str2 ? -1 : str1 > str2 ? 1 : 0;
  } else {
    return str1 < str2 ? 1 : str1 > str2 ? -1 : 0;
  }
}

export function sortNumber(num1: number, num2: number, order: 'asc' | 'desc') {
  return order === 'asc' ? num1 - num2 : num2 - num1;
}

export function sortBool(val1: boolean, val2: boolean, order: 'asc' | 'desc') {
  if (val1 === val2) {
    return 0;
  } else if (val1) {
    return order === 'asc' ? 1 : -1;
  } else {
    return order === 'asc' ? -1 : 1;
  }
}
