import { post } from 'api/request';

import { ChangePasswordData, LoginData, LoginResponseSuccess } from './types';

export default class AccountAPI {
  /**
   * 로그인 요청
   */
  static login(data: LoginData) {
    return post<LoginResponseSuccess, LoginData>('/Account/Login', data);
  }

  /**
   * 비밀번호 변경 요청
   */
  static changePassword(data: ChangePasswordData) {
    return post('/Account/ChangePassword', data);
  }
}
