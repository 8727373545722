import { CompanyCategoryFlag } from 'api/management';
import { MediaPlatformTag } from 'api/media';
import { ResponseSuccess } from 'api/types';

export type SettlementListParams = {
  searchYear: number;
  searchMonth: number; // 1 ~ 12
};

/**
 * 캠페인 정산 정보
 */
export type CampaignSettlement = {
  reportDate: string; // '2022-11-01T00:00:00'
  companyCategoryFlag: CompanyCategoryFlag;
  companyName: string;
  campaignName: string;
  campaignKey: string;
  grossUnitPrice: number;
  commissionFee: number;
  netUnitPrice: number;
  completeCount: number;
  grossRevenue: number;
  netRevenue: number;
  mediaCost: number;
  revenue: number;
  subCampaignSettlement: null | CampaignSettlement[];
};

/**
 * 캠페인 정산 목록 조회
 */
export type CampaignSettlementListResponseSuccess = ResponseSuccess & {
  SettlementCampaignList: CampaignSettlement[];
};

/**
 * 매체사 정산 상태
 */
export enum CompanySettlementStatus {
  미신청 = 0,
  '출금 신청' = 1,
  반려 = 2,
  '출금 완료' = 3,
}

/**
 * 매체사 정산 정보
 */
export type CompanySettlement = {
  paymentRequestDateTime: string | null; // 신청 날짜
  companyKey: number; // 매체사 코드
  companyName: string; // 매체사
  erpNo: string; // ERP 거래처 코드
  erpName: string; // ERP 거래처명
  accountHolder: string | null; // 예금주명
  isCompany: boolean; // 유형 (회사 vs. 개인)
  bankName: string | null; // 은행명
  bankingAccount: string | null; // 계좌정보
  bankingAccountImage: string | null; // 계좌 사본 url
  companyRegistNo: string | null; // 사업자 등록 번호
  companyRegistNoImage: string | null; // 사업자 등록증 사본 url
  mediaCost: number; // 월 매체비
  paymentRequestStatus: CompanySettlementStatus; // 정산 상태
  paymentUpdateDateTime: string | null; // 출금 일자
  paymentRequestMediaCost: number; // 신청 금액
  companyNo: number; // 매체사 no
  paymentRequestNo: number; // 정산 고유 no
};

/**
 * 매체사 정산 목록 조회
 */
export type CompanySettlementListResponseSuccess = ResponseSuccess & {
  SettlementCompanyList: CompanySettlement[];
};

/**
 * ERP 정보 수정 데이터
 */
export type UpdateCompanyErpMatchInfoData = {
  companyNo: number;
  companyERPName: string;
  companyERPNo: string;
};

/**
 * 매체 정산 - 출금 완료 처리 데이터
 */
export type CompletePaymentData = {
  paymentRequestNoList: number[];
};

/**
 * 매체 정산 - 반려 처리 데이터
 */
export type DenyPaymentData = {
  paymentRequestNoList: number[];
  denyMessage: string;
};

export type PaymentRequestSimpleInfoParams = {
  paymentRequestNo: number;
};

export type MediaCostByReportDate = {
  reportDatetime: string; // '2015-10-01T00:00:00'
  mediaCost: number;
};

/**
 * 출금 완료 버튼 클릭 시, 상세 내역 팝업 조회
 */
export type PaymentRequestSimpleInfoResponseSuccess = ResponseSuccess & {
  PaymentRequestSimpleInfo: {
    mediaCosts: MediaCostByReportDate[];
  };
};

/**
 * 매체 정산 상세 조회 params
 */
export type CompanySettlementDetailParams = {
  companyKey: number;
};

export type MediaCost = {
  grossCost: number; // 발생 수익
  rs: number; // 플랫폼 수수료
  netCost: number; // 출금 예정 금액
  mediaName: string;
};

export type CompanyMonthlyCost = {
  month: number;
  year: number;
  grossCost: number; // 발생 수익
  rs: number; // 플랫폼 수수료
  netCost: number; // 출금 예정 금액
  paymentRequestStatus: CompanySettlementStatus;
  paymentRequestDateTime: string | null; // "2022-12-08T10:38:44"
  mediaCosts: MediaCost[];
};

/**
 * 매체 정산 상세 조회 params
 */
export type CompanySettlementDetailResponseSuccess = ResponseSuccess & {
  CompanySettlementDetail: {
    companyName: string;
    companyKey: number;
    totalRevenue: number;
    paidRevenue: number;
    requestRevenue: number;
    deniedRevenue: number;
    unsettledRevenue: number;
    companyMonthlyCosts: CompanyMonthlyCost[];
  };
};

/**
 * 출금 신청 상세 params
 */
export type PaymentRequestDetailParams = {
  companyKey: number;
  paymentRequestNo: number;
};

export type Payment_MediaCost_Campaign = {
  grossCost: number;
  rs: number;
  netCost: number;
  completeCount: number;
  campaignName: string;
};

export type Payment_MediaCost = MediaCost & {
  campaigns: Payment_MediaCost_Campaign[];
};

export type Payment_CompanyMonthlyCost = {
  month: number;
  year: number;
  grossCost: number;
  rs: number;
  netCost: number;
  paymentDatetime: string; // '2022-11-01T00:00:00';
  mediaCosts: Payment_MediaCost[];
};

/**
 * 출금 신청 상세 조회
 */
export type PaymentRequestDetailResponseSuccess = ResponseSuccess & {
  PaymentRequestDetailInfo: {
    companyName: string | null;
    paymentRequestStatus: CompanySettlementStatus;
    paymentRequestDateTime: string | null; // '0001-01-01T00:00:00';
    paymentUpdateDateTime: string | null; // '0001-01-01T00:00:00';
    paymentRequestCost: number;
    companyMonthlyCosts: Payment_CompanyMonthlyCost[] | null;
  };
};

/**
 * 매체 수익 상세 - 매체사 목록 조회 params
 */
export type S_CompanyListParams = {
  searchYear: number;
  searchMonth: number;
};

export type S_Company = {
  companyNo: number;
  companyKey: number;
  companyName: string;
};

export type S_CompanyListResponseSuccess = ResponseSuccess & {
  CompanyList: S_Company[];
};

/**
 * 매체 수익 상세 조회 params
 */
export type CompanyRevenueDetailParams = {
  companyKey: number;
  searchYear: number;
  searchMonth: number;
};

export type CompanyRevenueDetailByCampaign = {
  reportDateTime: string;
  companyName: string;
  mediaName: string;
  mediaKey: number;
  campaignName: string;
  mediaUnitPrice: number;
  completeCount: number;
  grossCost: number;
  rsRate: number;
  rs: number;
  netCost: number;
};

/**
 * 매체 수익 상세 조회
 */
export type CompanyRevenueDetailResponseSuccess = ResponseSuccess & {
  CompanyDetailRevenue: CompanyRevenueDetailByCampaign[];
};

/**
 * 플랫폼 타입
 */
export type PlatformNo = Exclude<MediaPlatformTag, MediaPlatformTag.없음>;

/**
 * 플랫폼 수익 상세 조회 params
 */
export type PlatformRevenueDetailParams = {
  platformNo: PlatformNo | 0; // 0 = 전체
  searchYear: number;
  searchMonth: number;
};

export type PlatformRevenueDetail = {
  reportDateTime: string; // '2022-12-01T00:00:00'
  companyName: string;
  mediaName: string | null;
  platformNo: PlatformNo;
  companyKey: number;
  mediaKey: number;
  campaignCount: number;
  grossCost: number;
  rsRate: number;
  rs: number;
  netCost: number;
};

/**
 * 플랫폼 수익 상세 조회
 */
export type PlatformRevenueDetailResponseSuccess = ResponseSuccess & {
  PlatformDetailRevenue: PlatformRevenueDetail[];
};
