import { moment } from 'utils';

export type GetValidSearchDatesOptions = {
  /**
   * startDate, endDate이 minDate와 maxDate 사이에 있는지 체크
   */
  minDate: string | moment.Moment;

  /**
   * startDate, endDate이 minDate와 maxDate 사이에 있는지 체크
   */
  maxDate?: string | moment.Moment;

  /**
   * 조회 시작 날짜 (default to NOW)
   */
  startDate?: string | moment.Moment;

  /**
   * 조회 종료 날짜 (default to NOW)
   */
  endDate?: string | moment.Moment;

  /**
   * 유효하지 않은 startDate의 경우 endDate를 기준으로 startDateInterval만큼 과거의 날짜를 기본으로 선택
   * @default 6
   */
  startDateInterval?: number;
};

const DEFAULT_START_DATE_INTERVAL = 6;

export function getValidSearchDates(options: GetValidSearchDatesOptions) {
  const {
    minDate,
    maxDate,
    startDate,
    endDate,
    startDateInterval = DEFAULT_START_DATE_INTERVAL,
  } = options;

  const _minDate = moment(minDate); // minDate
  const _maxDate = moment(maxDate); // maxDate || NOW

  const _startDate = moment(startDate); // startDate || NOW
  const _endDate = moment(endDate); // endDate || NOW

  const isValidStartDate = _startDate.isValid() && _startDate.isBetween(_minDate, _maxDate);
  const isValidEndDate =
    _endDate.isValid() &&
    _endDate.isBetween(_minDate, _maxDate) &&
    _endDate.isSameOrAfter(_startDate);

  return {
    startDate: isValidStartDate
      ? _startDate.format('YYYY-MM-DD')
      : moment(_endDate).subtract(startDateInterval, 'days').format('YYYY-MM-DD'),
    endDate: isValidEndDate ? _endDate.format('YYYY-MM-DD') : _maxDate.format('YYYY-MM-DD'),
  };
}
