import { useCallback } from 'react';

import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { isNumber } from 'lodash-es';

import { rowPerPageOptionList } from './const';
import { SelectRowsPerPageProps } from './types';

const SelectRowsPerPage = (props: SelectRowsPerPageProps) => {
  const { totalRowLength, value, onChange, sx = [], disabled = false } = props;

  const handleSelectRowsPerPage = useCallback(
    (e: SelectChangeEvent<number>) => {
      const { value } = e.target;
      if (isNumber(value)) {
        onChange(value);
      }
    },
    [onChange]
  );

  return (
    <Select
      value={value}
      onChange={handleSelectRowsPerPage}
      disabled={disabled}
      sx={[
        {
          minWidth: 120,
          height: 36,
          typography: 'body2',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {rowPerPageOptionList.map((option, idx) => (
        <MenuItem key={idx} value={option} sx={{ width: 1, height: 32 }}>
          <Typography variant="body2">{option}</Typography>
        </MenuItem>
      ))}
      {totalRowLength && (
        <MenuItem value={totalRowLength} sx={{ width: 1, height: 32 }}>
          <Typography variant="body2">전체</Typography>
        </MenuItem>
      )}
    </Select>
  );
};

export default SelectRowsPerPage;
