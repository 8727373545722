import { ChangeEvent, useCallback } from 'react';

import { DarkMode, LightMode } from '@mui/icons-material';
import { Checkbox } from '@mui/material';

import { usePaletteMode } from 'styles';

const DarkModeSwitch = () => {
  const [paletteMode, setPaletteMode] = usePaletteMode();
  const isDarkMode = paletteMode === 'dark';

  const toggleDarkMode = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      setPaletteMode(checked ? 'dark' : 'light');
    },
    [setPaletteMode]
  );

  return (
    <Checkbox
      value={isDarkMode}
      checked={isDarkMode}
      onChange={toggleDarkMode}
      aria-label="다크 모드 체크박스"
      icon={<LightMode sx={{ color: 'orange' }} />}
      checkedIcon={<DarkMode sx={{ color: 'yellow' }} />}
      sx={{ mr: 2 }}
    />
  );
};

export default DarkModeSwitch;
