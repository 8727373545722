import axios, { AxiosResponse } from 'axios';

import { AnyObject } from 'utils';

import { Response } from './types';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export function get<T extends Response | string = Response, U extends AnyObject = AnyObject>(
  url: string,
  params?: U
): Promise<AxiosResponse<T, U>>;

export function get<T extends Response | string = Response, U extends AnyObject = AnyObject>(
  url: string,
  token?: string,
  params?: U
): Promise<AxiosResponse<T, U>>;

export function get<T extends Response | string = Response, U extends AnyObject = AnyObject>(
  url: string,
  tokenOrParams?: string | U,
  params?: U
) {
  if (typeof tokenOrParams === 'string') {
    return axios.get<T, AxiosResponse<T, U>, U>(url, {
      params: params,
      headers: {
        Authorization: `Bearer ${tokenOrParams}`,
      },
    });
  }

  return axios.get<T, AxiosResponse<T, U>, U>(url, {
    params: tokenOrParams,
  });
}

export function post<T extends Response = Response, U extends AnyObject | FormData = AnyObject>(
  url: string,
  data: U,
  token?: string
) {
  return axios.post<T, AxiosResponse<T, U>, U>(url, data, {
    ...(token && {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  });
}
