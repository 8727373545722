import { useCallback } from 'react';

import { Snackbar as MuiSnackbar, Alert, Slide } from '@mui/material';

import { DEFAULT_DURATION, DEFAULT_ORIGIN } from './const';
import useSnackbar from './use-snackbar';

const Snackbar = () => {
  const [snackbar, setSnackbar] = useSnackbar();

  const handleClose = useCallback(() => {
    setSnackbar(null);
  }, [setSnackbar]);

  return snackbar ? (
    <MuiSnackbar
      open={snackbar.open}
      autoHideDuration={snackbar.duration || DEFAULT_DURATION}
      onClose={handleClose}
      anchorOrigin={snackbar.origin || DEFAULT_ORIGIN}
      TransitionComponent={Slide}
    >
      <Alert onClose={handleClose} severity={snackbar.severity}>
        {snackbar.message}
      </Alert>
    </MuiSnackbar>
  ) : null;
};

export default Snackbar;
