import { useCallback, useEffect } from 'react';

import { CloseOutlined } from '@mui/icons-material';
import { Box, IconButton, Modal as MuiModal, Stack, Typography } from '@mui/material';
import { isFunction } from 'lodash-es';

import { getThemedProp, overflowY } from 'styles';

import { ModalProps } from './types';
import useModal from './use-modal';

const Modal = (props: ModalProps) => {
  const {
    modalName,
    renderOnMount = false,
    title,
    showExitButton = true,
    disableExit = false,
    modalContentStyle,
    onClose,
    size = 'medium',
    children,
    ...rest
  } = props;

  const { open, handleCloseModal, setModal, removeModal } = useModal(modalName);

  const handleClose = useCallback(() => {
    if (disableExit) return;

    if (isFunction(onClose)) {
      onClose();
      return;
    }

    handleCloseModal();
  }, [disableExit, handleCloseModal, onClose]);

  useEffect(() => {
    setModal(renderOnMount);

    return () => {
      removeModal();
    };
  }, [renderOnMount, setModal, removeModal]);

  return (
    <MuiModal open={open} onClose={handleClose} {...rest}>
      <Stack
        spacing={3}
        sx={[
          {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: 300,
            maxWidth: (theme) => theme.breakpoints.values.xl,
            maxHeight: 'calc(100vh - 40px)',
            p: size === 'small' ? 3 : 4,
            width: size === 'small' ? 300 : 500,
            borderRadius: 1,
            bgcolor: 'background.paper',
            boxShadow: '0px 2px 30px rgba(0, 0, 0, 0.03)',
          },
          ...(Array.isArray(modalContentStyle) ? modalContentStyle : [modalContentStyle]),
        ]}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography component="h2" variant={size === 'small' ? 'body1' : 'h6'} fontWeight="bold">
            {title}
          </Typography>
          {showExitButton && (
            <IconButton
              aria-label="팝업 닫기"
              size={size}
              onClick={handleClose}
              sx={getThemedProp('color', 'grey.900', 'darkGrey.500')}
            >
              <CloseOutlined />
            </IconButton>
          )}
        </Stack>
        <Box sx={overflowY}>{open ? children : null}</Box>
      </Stack>
    </MuiModal>
  );
};

export default Modal;
