import { get, post } from 'api/request';

import * as T from './types';

export default class SettlementAPI {
  /**
   * 캠페인 정산 목록 조회
   */
  static getCampaignSettlementList(token: string, params: T.SettlementListParams) {
    return get<T.CampaignSettlementListResponseSuccess, T.SettlementListParams>(
      '/api/v1/settlement/manage/CampaignSettlement',
      token,
      params
    );
  }

  /**
   * 매체 정산 목록 조회
   */
  static getCompanySettlementList(token: string, params: T.SettlementListParams) {
    return get<T.CompanySettlementListResponseSuccess, T.SettlementListParams>(
      '/api/v1/settlement/manage/CompanySettlement',
      token,
      params
    );
  }

  /**
   * ERP 정보 수정
   */
  static updateCompanyErpMatchInfo(token: string, data: T.UpdateCompanyErpMatchInfoData) {
    return post('/api/v1/settlement/manage/UpdateCompanyERPMatchInfo', data, token);
  }

  /**
   * 매체 정산 - 출금 완료 요청
   */
  static completePayment(token: string, data: T.CompletePaymentData) {
    return post('/api/v1/settlement/manage/CompletePayment', data, token);
  }

  /**
   * 매체 정산 - 출금 반려 요청
   */
  static denyPayment(token: string, data: T.DenyPaymentData) {
    return post('/api/v1/settlement/manage/DenyPayment', data, token);
  }

  /**
   * 출금 완료 처리 - 신청 수익 기간 별 신청 금액 목록 조회
   */
  static getPaymentRequestSimpleInfo(token: string, params: T.PaymentRequestSimpleInfoParams) {
    return get<T.PaymentRequestSimpleInfoResponseSuccess, T.PaymentRequestSimpleInfoParams>(
      '/api/v1/settlement/manage/PaymentRequestSimpleInfo',
      token,
      params
    );
  }

  /**
   * 매체 정산 상세 조회
   */
  static getCompanySettlementDetail(token: string, params: T.CompanySettlementDetailParams) {
    return get<T.CompanySettlementDetailResponseSuccess, T.CompanySettlementDetailParams>(
      '/api/v1/settlement/manage/CompanySettlementDetail',
      token,
      params
    );
  }

  /**
   * 매체 정산 - 출금 상세 조회
   */
  static getPaymentRequestDetail(token: string, params: T.PaymentRequestDetailParams) {
    return get<T.PaymentRequestDetailResponseSuccess, T.PaymentRequestDetailParams>(
      '/api/v1/settlement/manage/PaymentRequestDetailInfo',
      token,
      params
    );
  }

  /**
   * 매체 수익 상세 - 매체사 목록 조회
   */
  static getCompanyList(token: string, params: T.S_CompanyListParams) {
    return get<T.S_CompanyListResponseSuccess, T.S_CompanyListParams>(
      '/api/v1/settlement/manage/CompanyList',
      token,
      params
    );
  }

  /**
   * 매체 수익 상세 조회
   */
  static getCompanyRevenueDetail(token: string, params: T.CompanyRevenueDetailParams) {
    return get<T.CompanyRevenueDetailResponseSuccess, T.CompanyRevenueDetailParams>(
      '/api/v1/settlement/manage/CompanyDetailRevenue',
      token,
      params
    );
  }

  /**
   * 플랫폼 수익 상세
   */
  static getPlatformRevenueDetail(token: string, params: T.PlatformRevenueDetailParams) {
    return get<T.PlatformRevenueDetailResponseSuccess, T.PlatformRevenueDetailParams>(
      '/api/v1/settlement/manage/PlatformDetailRevenue',
      token,
      params
    );
  }
}
