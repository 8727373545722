import { PaletteMode } from '@mui/material';
import { atom, useRecoilState } from 'recoil';

import { DEFAULT_VALUE, PALETTE_MODE_STATE_KEY } from './const';

const paletteModeState = atom<PaletteMode>({
  key: PALETTE_MODE_STATE_KEY,
  default: DEFAULT_VALUE,
  effects: [
    ({ setSelf }) => {
      const persistedMode = localStorage.getItem(PALETTE_MODE_STATE_KEY);

      if (persistedMode === 'light' || persistedMode === 'dark') {
        setSelf(persistedMode);
      } else {
        localStorage.setItem(PALETTE_MODE_STATE_KEY, DEFAULT_VALUE);
      }
    },
    ({ onSet }) => {
      onSet((paletteMode) => {
        localStorage.setItem(PALETTE_MODE_STATE_KEY, paletteMode);
      });
    },
  ],
});

const usePaletteMode = () => {
  return useRecoilState(paletteModeState);
};

export default usePaletteMode;
