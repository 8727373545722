import { RefObject, useCallback, useMemo } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useVirtual, VirtualItem } from 'react-virtual';

import { Box, BoxProps, Stack } from '@mui/material';
import { Cell, Row } from '@tanstack/react-table';

import { getThemedProp, SSO } from 'styles';
import { AnyObject } from 'utils';

import { DEFAULT_OVERSCAN_COUNT, TableLayerOrder } from './const';
import RowCell from './row-cell';
import { CHECKBOX_COLUMN_ID } from './use-table';

export type TableBodyProps<T extends AnyObject> = {
  rows: Row<T>[];
  containerRef: RefObject<HTMLDivElement>;
  rowHeight: number;
  stickyColumnsCount: number;
  stickyColumnsPositionList: number[];
  enableScrollX: boolean;
  draggable: boolean;
  onDragEnd?: (result: DropResult) => void;
};

const rowStyle: SSO = {
  position: 'absolute',
  left: 0,
  width: 1,
};

const TableBody = <T extends AnyObject>(props: TableBodyProps<T>) => {
  const {
    containerRef,
    rows,
    rowHeight,
    stickyColumnsCount,
    stickyColumnsPositionList,
    enableScrollX,
    draggable,
    onDragEnd,
  } = props;

  const { virtualItems, totalSize } = useVirtual({
    parentRef: containerRef,
    size: rows.length,
    overscan: DEFAULT_OVERSCAN_COUNT,
    estimateSize: useCallback(() => rowHeight, [rowHeight]),
  });

  const getStickyStyle = useCallback(
    (cellIdx: number) => {
      const isStickyColumn = cellIdx < stickyColumnsCount;
      return enableScrollX && isStickyColumn
        ? {
            position: 'sticky',
            left: stickyColumnsPositionList[cellIdx],
            zIndex: TableLayerOrder.FixedRowCell,
          }
        : false;
    },
    [enableScrollX, stickyColumnsCount, stickyColumnsPositionList]
  );

  const getFullWidthStyle = useCallback(
    (cellId: string, width: number) => {
      return cellId === CHECKBOX_COLUMN_ID || enableScrollX
        ? {
            width,
            flexShrink: 0,
          }
        : {
            flexGrow: 1,
            flexBasis: width,
            flexShrink: 0,
          };
    },
    [enableScrollX]
  );

  const renderRowCells = useCallback(
    (cell: Cell<T, unknown>, cellIdx: number, depth: number) => {
      const columnWidth = cell.column.getSize();
      const stickyStyle = getStickyStyle(cellIdx);
      const fullWidthStyle = getFullWidthStyle(cell.column.id, columnWidth);
      const canExpanded = cell.row.getCanExpand();

      return (
        <RowCell
          key={cell.id}
          cell={cell}
          sx={[
            { height: rowHeight },
            stickyStyle,
            fullWidthStyle,
            depth % 2 === 1 && getThemedProp('bgcolor', 'grey.50', 'darkGrey.900'),
            canExpanded && { bgcolor: '#EFFAFA' },
          ]}
        />
      );
    },
    [getFullWidthStyle, getStickyStyle, rowHeight]
  );

  const renderRows = useCallback(
    (virtualRow: VirtualItem) => {
      const row = rows[virtualRow.index];

      const virtualizedRowStyle: SSO = {
        top: virtualRow.start,
        height: virtualRow.size,
      };

      if (draggable && onDragEnd) {
        return (
          <Draggable key={row.id} draggableId={row.id} index={row.index}>
            {(provided) => (
              <Stack
                role="row"
                direction="row"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                sx={[rowStyle, virtualizedRowStyle]}
              >
                {row.getVisibleCells().map((cell, idx) => renderRowCells(cell, idx, row.depth))}
              </Stack>
            )}
          </Draggable>
        );
      }

      return (
        <Stack role="row" direction="row" key={row.id} sx={[rowStyle, virtualizedRowStyle]}>
          {row.getVisibleCells().map((cell, idx) => renderRowCells(cell, idx, row.depth))}
        </Stack>
      );
    },
    [draggable, onDragEnd, renderRowCells, rows]
  );

  const virtualRows = useMemo(() => virtualItems.map(renderRows), [renderRows, virtualItems]);

  const renderTableBody = useCallback(
    (props?: BoxProps) => (
      <Box {...props} role="rowgroup" sx={{ position: 'relative', height: totalSize }}>
        {virtualRows}
      </Box>
    ),
    [totalSize, virtualRows]
  );

  return draggable && onDragEnd ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="droppable"
        mode="virtual"
        renderClone={(provided, _, rubric) => {
          const row = virtualItems.find(({ index }) => index === rubric.source.index);
          const top = row ? row.start : 0;
          const height = row ? row.size : rowHeight;

          return (
            <Stack
              role="row"
              direction="row"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              sx={[rowStyle, { top, height }]}
            >
              {rows[rubric.source.index]
                .getVisibleCells()
                .map((cell, idx) => renderRowCells(cell, idx, rows[rubric.source.index].depth))}
            </Stack>
          );
        }}
      >
        {(droppableProvided) =>
          renderTableBody({
            ref: droppableProvided.innerRef,
            ...droppableProvided.droppableProps,
          })
        }
      </Droppable>
    </DragDropContext>
  ) : (
    renderTableBody()
  );
};

export default TableBody;
