import { ChangeEvent, ChangeEventHandler, FocusEvent, FocusEventHandler, useCallback } from 'react';

import { FieldInputProps, FieldMetaProps, FormikConfig, useFormik } from 'formik';

import { AnyObject, StringKey } from 'utils';

const useTypedFormik = <T extends AnyObject>(formikConfig: FormikConfig<T>) => {
  const {
    touched,
    errors,
    getFieldProps,
    getFieldMeta,
    setFieldValue,
    handleBlur,
    handleChange,
    ...rest
  } = useFormik(formikConfig);

  const _getFieldProps = useCallback(
    <K extends StringKey<T>>(name: K) => {
      return getFieldProps(name) as FieldInputProps<T[K]>;
    },
    [getFieldProps]
  );

  const _getFieldMeta = useCallback(
    <K extends StringKey<T>>(name: K) => {
      return getFieldMeta(name) as FieldMetaProps<T[K]>;
    },
    [getFieldMeta]
  );

  const _setFieldValue = useCallback(
    <K extends StringKey<T>>(field: K, value: T[K], shouldValidate?: boolean) => {
      return setFieldValue(field, value, shouldValidate);
    },
    [setFieldValue]
  );

  const shouldErrorShows = useCallback(
    (fieldName: StringKey<T>) => {
      return Boolean(touched[fieldName] && errors[fieldName]);
    },
    [errors, touched]
  );

  const getHelperText = useCallback(
    (fieldName: StringKey<T>) => {
      return shouldErrorShows(fieldName) ? errors[fieldName] : null;
    },
    [errors, shouldErrorShows]
  );

  const _handleBlur = useCallback(
    <U extends FocusEvent | StringKey<T>>(fieldOrEvent: U) => {
      if (typeof fieldOrEvent === 'string') {
        return handleBlur(fieldOrEvent) as FocusEventHandler;
      }

      return handleBlur as FocusEventHandler;
    },
    [handleBlur]
  );

  const _handleChange = useCallback(
    <U extends ChangeEvent | StringKey<T>>(fieldOrEvent: U) => {
      if (typeof fieldOrEvent === 'string') {
        return handleChange(fieldOrEvent) as ChangeEventHandler;
      }

      return handleChange as ChangeEventHandler;
    },
    [handleChange]
  );

  return {
    touched,
    errors,
    getFieldProps: _getFieldProps,
    getFieldMeta: _getFieldMeta,
    setFieldValue: _setFieldValue,
    shouldErrorShows,
    getHelperText,
    handleBlur: _handleBlur,
    handleChange: _handleChange,
    ...rest,
  };
};

export default useTypedFormik;
