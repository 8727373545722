import { memo, ReactNode, useCallback, useMemo } from 'react';

import { FormControl, FormLabel, SelectProps, Select, Typography, MenuItem } from '@mui/material';
import { isArray, isNumber } from 'lodash-es';

import { IntegrationType } from 'api';
import { overflowY } from 'styles';

import { integrationTypeByParticipation, labelStyle, textFieldStyle } from './const';
import { IntegrationTypeFormValue, ParticipationTypeFormValue } from './types';

export type SelectIntegrationTypeProps = Omit<
  SelectProps<IntegrationTypeFormValue>,
  'displayEmpty' | 'renderValue' | 'sx' | 'MenuProps'
> & {
  helperText?: string | null;
  participationType: ParticipationTypeFormValue;
  options?: ReactNode[];
};

const SelectIntegrationType = memo((props: SelectIntegrationTypeProps) => {
  const { participationType, options, helperText, ...selectProps } = props;

  const renderIntegrationOption = useCallback((value: IntegrationTypeFormValue) => {
    return value === '' ? '연동 형태를 선택하세요.' : IntegrationType[value];
  }, []);

  const integrationTypeOptions = useMemo(() => {
    if (options) {
      return options;
    }

    if (isNumber(participationType)) {
      const integrationTypeList = integrationTypeByParticipation[participationType];

      if (isArray(integrationTypeList)) {
        return integrationTypeList.map((type) => (
          <MenuItem value={type} key={type}>
            {IntegrationType[type]}
          </MenuItem>
        ));
      }
    }

    return null;
  }, [options, participationType]);

  return (
    <FormControl>
      <FormLabel htmlFor={props.id} required sx={labelStyle}>
        연동 형태
      </FormLabel>
      <Select
        {...selectProps}
        displayEmpty
        renderValue={renderIntegrationOption}
        sx={textFieldStyle}
        MenuProps={{
          sx: { '.MuiPaper-root': overflowY },
        }}
      >
        {integrationTypeOptions}
      </Select>
      {selectProps.error && (
        <Typography fontSize="0.75rem" sx={{ color: 'status.error', mt: '3px', ml: '14px' }}>
          {helperText}
        </Typography>
      )}
    </FormControl>
  );
});

SelectIntegrationType.displayName = 'SelectIntegrationType';

export default SelectIntegrationType;
