import { useMemo } from 'react';
import { QueryClient, QueryClientProvider as ReactQueryClientProvider } from 'react-query';

import { useAxiosErrorHandler } from 'hooks';

import { CustomQueryClientProviderProps } from './types';

const QueryClientProvider = (props: CustomQueryClientProviderProps) => {
  const { children } = props;
  const axiosErrorHandler = useAxiosErrorHandler();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false, // TODO: rollback
            retry: 1,
            onError: (error) => {
              axiosErrorHandler(error);
            },
          },
          mutations: {
            onError: (error) => {
              axiosErrorHandler(error);
            },
          },
        },
      }),
    [axiosErrorHandler]
  );

  return <ReactQueryClientProvider client={queryClient}>{children}</ReactQueryClientProvider>;
};

export default QueryClientProvider;
