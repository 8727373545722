import { SSO } from 'styles';

import { MIN_COLUMN_WIDTH } from './use-table';

export const DEFAULT_ROW_HEIGHT = 50;
export const DEFAULT_OVERSCAN_COUNT = 10;
export const CHECKBOX_WIDTH = 50;

export enum TableLayerOrder {
  FixedRowCell = 1, // 열고정 처리되어있는 row 셀
  FixedRowCellPopup = 2, // 열고정 처리되어있는 row 셀의 내부 팝업
  ColumnContainer = 3, // column 셀 컨테이너
  FixedColumnCell = 4, // 열고정 처리되어있는 column 셀
}

export const cellStyle: SSO = {
  position: 'relative',
  minWidth: MIN_COLUMN_WIDTH,
  overflow: 'hidden',
  bgcolor: 'background.paper',
  border: ({ palette }) =>
    `1px solid ${palette.mode === 'light' ? palette.line.grey : palette.line.darkGrey}`,
  color: 'text.primary',
  typography: 'body2',
};
