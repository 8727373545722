export const blue = {
  50: '#E3F2FD',
  100: '#BCDFFB',
  200: '#91CBF9',
  300: '#66B7F7',
  400: '#45A7F6',
  500: '#2699F4',
  600: '#228BE6',
  700: '#1C78D3',
  800: '#1867C1',
  900: '#0F49A2',
};

export const red = {
  50: '#FFEBEE',
  100: '#FFCDD2',
  200: '#EF9A9A',
  300: '#E57373',
  400: '#EF5350',
  500: '#F44336',
  600: '#E53935',
  700: '#D32F2F',
  800: '#C62828',
  900: '#B71B1C',
};

export const green = {
  50: '#E4F8E5',
  100: '#BEEDBF',
  200: '#91E295',
  300: '#59D766',
  400: '#00CD3D',
  500: '#00C300',
  600: '#00B300',
  700: '#00A000',
  800: '#008F00',
  900: '#006F00',
};

export const amber = {
  50: '#FFF8E1',
  100: '#FFECB3',
  200: '#FFE082',
  300: '#FFD54F',
  400: '#FFCA28',
  500: '#FFC007',
  600: '#FFB300',
  700: '#FFA000',
  800: '#FF8E00',
  900: '#FF6E01',
};

export const grey = {
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#EEEEEE',
  300: '#E0E0E0',
  400: '#BDBDBD',
  500: '#9E9E9E',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',
};

export const blueGrey = {
  50: '#F5FAFF',
  100: '#F1F6FF',
  200: '#EAEFF9',
  300: '#DCE1EB',
  400: '#B9BEC8',
  500: '#9B9FA8',
  600: '#72767F',
  700: '#5E626A',
  800: '#3F434B',
  900: '#1E2229',
};

export const darkGrey = {
  50: '#F4F8FF',
  100: '#ECF0FA',
  200: '#E2E6F0',
  300: '#D1D5DF',
  400: '#ADB0BA',
  500: '#8D9099',
  600: '#656871',
  700: '#52555D',
  800: '#34373F',
  900: '#14171E',
};

export const white = '#fff';
export const black = '#000';
