import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

import { CampaignAPI } from 'api';
import useUser from 'hooks/use-user';

import { MediaTagListQueryFn, MediaTagListQueryKey, MediaTagListQueryOptions } from './types';

const useMediaTagList = (options?: MediaTagListQueryOptions) => {
  const { user } = useUser();

  const queryKey = useMemo<MediaTagListQueryKey>(() => 'mediaTagList', []);

  const queryFn = useCallback<MediaTagListQueryFn>(() => {
    return CampaignAPI.getMediaTagList(user.accessToken);
  }, [user.accessToken]);

  const { data: response, ...rest } = useQuery(queryKey, queryFn, options);

  const mediaTagList = useMemo(() => {
    return response ? response.data.TagList : [];
  }, [response]);

  return {
    queryKey,
    mediaTagList,
    ...rest,
  };
};

export default useMediaTagList;
