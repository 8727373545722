import { memo, useCallback } from 'react';

import { Button, Typography } from '@mui/material';

import { useSnackbar } from 'components/snackbar';
import { fullWidth, fullHeight, SSO } from 'styles';

import { CopyTextProps } from './types';

const buttonStyle: SSO = {
  justifyContent: 'initial',
  alignItems: 'initial',
  bgcolor: 'inherit',
  color: 'inherit',
  textTransform: 'none',
  ':hover': {
    bgcolor: 'inherit',
  },
};

const textStyle: SSO = {
  margin: 'auto',
  textDecoration: 'underline',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

const CopyText = (props: CopyTextProps) => {
  const { text } = props;
  const [, setSnackbar] = useSnackbar();

  const copyText = async (text: string) => {
    await navigator.clipboard.writeText(text);
  };

  const handleClick = useCallback(async () => {
    try {
      await copyText(text);
      setSnackbar({
        open: true,
        severity: 'success',
        message: '복사 성공',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        message: '복사 실패',
      });
    }
  }, [setSnackbar, text]);

  return (
    <Button type="button" onClick={handleClick} sx={[buttonStyle, fullWidth, fullHeight]}>
      <Typography variant="body2" sx={textStyle}>
        {text}
      </Typography>
    </Button>
  );
};

CopyText.displayName = 'CopyText';

export default memo(CopyText) as typeof CopyText;
