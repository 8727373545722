import { post } from 'api/request';

import { UploadFileResponseSuccess } from './types';

export default class S3API {
  static uploadFile(token: string, data: FormData) {
    return post<UploadFileResponseSuccess, FormData>(
      '/api/v1/campaign/manage/UploadFile',
      data,
      token
    );
  }

  static UploadVideoFile(token: string, data: FormData) {
    return post<UploadFileResponseSuccess, FormData>(
      '/api/v1/campaign/manage/UploadVideoFile',
      data,
      token
    );
  }
}
