import { useState, useCallback, MouseEvent } from 'react';

import { ArrowDropDown } from '@mui/icons-material';
import { Stack, Typography, Box, IconButton, Menu, MenuItem as MuiMenuItem } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { UserMenuItem, UserMenuProps } from './types';

const UserMenu = (props: UserMenuProps) => {
  const { name, email, userMenuItemList } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  const handleClick = useCallback((e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickMenuItem = useCallback(
    (userMenuItem: UserMenuItem) => {
      handleClose();
      userMenuItem.onClick();
    },
    [handleClose]
  );

  return (
    <Stack component="section" justifyContent="center" direction="row" alignItems="center">
      <Typography component="h2" sx={visuallyHidden}>
        사용자 정보
      </Typography>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Stack component="dl" direction="column" alignItems="end">
          <Box>
            <Typography component="dt" sx={visuallyHidden}>
              이름
            </Typography>
            <Typography component="dd">{name}</Typography>
          </Box>
          <Box>
            <Typography component="dt" sx={visuallyHidden}>
              이메일
            </Typography>
            <Typography component="dd">{email}</Typography>
          </Box>
        </Stack>
        <IconButton
          id="user-menu-btn"
          aria-controls={open ? 'user-menu' : undefined}
          aria-haspopup
          aria-expanded={open}
          aria-label="더보기"
          onClick={handleClick}
        >
          <ArrowDropDown fontSize="large" />
        </IconButton>
      </Stack>
      <Menu
        id="user-menu"
        aria-labelledby="btn-user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {userMenuItemList.map((userMenuItem, idx) => (
          <MuiMenuItem key={idx} onClick={() => handleClickMenuItem(userMenuItem)}>
            {userMenuItem.text}
          </MuiMenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default UserMenu;
