import { Box, Checkbox, CheckboxProps, FormControlLabel, Stack, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { ModalButtonGroup } from 'components';

import CampaignName, { CampaignNameProps } from './campaign-name';
import SelectIntegrationType, { SelectIntegrationTypeProps } from './select-integration-type';
import SelectParticipationType, { SelectParticipationTypeProps } from './select-participation-type';
import SelectPlatform, { SelectPlatformProps } from './select-platform';
import SelectSettlementType, { SelectSettlementTypeProps } from './select-settlement-type';

export type CreateCampaignFormProps = {
  onSubmit: VoidFunction;
  campaignNameProps: CampaignNameProps;
  participationTypeProps: SelectParticipationTypeProps;
  integrationTypeProps: SelectIntegrationTypeProps;
  settlementTypeProps: SelectSettlementTypeProps;
  houseAdProps?: CheckboxProps;
  platformProps: SelectPlatformProps;
  loading?: boolean;
  isValid?: boolean;
  onCancel: VoidFunction;
};

const CreateCampaignForm = (props: CreateCampaignFormProps) => {
  const {
    onSubmit,
    campaignNameProps,
    participationTypeProps,
    integrationTypeProps,
    settlementTypeProps,
    houseAdProps,
    platformProps,
    loading = false,
    isValid = false,
    onCancel,
  } = props;

  return (
    <Box component="form">
      <Stack component="fieldset">
        <Typography component="legend" sx={visuallyHidden}>
          캠페인 생성 폼
        </Typography>
        <Stack spacing={4}>
          <CampaignName {...campaignNameProps} />
          <SelectParticipationType {...participationTypeProps} />
          <SelectIntegrationType {...integrationTypeProps} />
          <SelectSettlementType {...settlementTypeProps} />
        </Stack>
        <Stack spacing={4}>
          {houseAdProps && (
            <FormControlLabel
              style={{ pointerEvents: 'none' }}
              label="House AD"
              control={<Checkbox style={{ pointerEvents: 'auto' }} {...houseAdProps} />}
            />
          )}
          <SelectPlatform {...platformProps} />
          <ModalButtonGroup
            type="button"
            confirmButtonText="계속"
            loading={loading}
            disableConfirm={!isValid}
            onCancel={onCancel}
            onConfirm={() => onSubmit()}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default CreateCampaignForm;
