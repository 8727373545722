import { useCallback } from 'react';

import { Box, Stack } from '@mui/material';
import { Header } from '@tanstack/react-table';

import { SSO } from 'styles';
import { AnyObject } from 'utils';

import ColumnHeader from './column-header';
import { TableLayerOrder } from './const';
import { CHECKBOX_COLUMN_ID } from './use-table';

export type TableHeadProps<T extends AnyObject> = {
  headers: Header<T, unknown>[];
  stickyColumnsCount: number;
  stickyColumnsPositionList: number[];
  enableScrollX: boolean;
  rowHeight: number;
};

const tableHeadStyle: SSO = {
  position: 'sticky',
  top: 0,
  zIndex: TableLayerOrder.ColumnContainer,
  bgcolor: 'background.paper',
};

const TableHead = <T extends AnyObject>(props: TableHeadProps<T>) => {
  const { headers, stickyColumnsCount, stickyColumnsPositionList, enableScrollX, rowHeight } =
    props;

  const getStickyStyle = useCallback(
    (headerIdx: number) => {
      const isStickyColumn = headerIdx < stickyColumnsCount;
      return enableScrollX && isStickyColumn
        ? {
            position: 'sticky',
            left: stickyColumnsPositionList[headerIdx],
            zIndex: TableLayerOrder.FixedColumnCell,
          }
        : false;
    },
    [enableScrollX, stickyColumnsCount, stickyColumnsPositionList]
  );

  const getFullWidthStyle = useCallback(
    (headerId: string, width: number) => {
      return headerId === CHECKBOX_COLUMN_ID || enableScrollX
        ? {
            width,
            flexShrink: 0,
          }
        : {
            flexGrow: 1,
            flexBasis: width,
            flexShrink: 0,
          };
    },
    [enableScrollX]
  );

  return (
    <Box role="rowgroup" sx={tableHeadStyle}>
      <Stack role="row" direction="row">
        {headers.map((header, headerIdx) => {
          const columnWidth = header.getSize();
          const stickyStyle = getStickyStyle(headerIdx);
          const fullWidthStyle = getFullWidthStyle(header.id, columnWidth);

          return (
            <ColumnHeader
              key={header.id}
              header={header}
              sx={[{ height: rowHeight }, stickyStyle, fullWidthStyle]}
            />
          );
        })}
      </Stack>
    </Box>
  );
};

export default TableHead;
