import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import { RouterLinkProps } from './types';

const defaultLinkStyle: CSSProperties = {
  display: 'block',
  textDecoration: 'none',
  color: 'inherit',
};

const RouterLink = (props: RouterLinkProps) => {
  const { style, ...rest } = props;
  return <Link style={{ ...defaultLinkStyle, ...style }} {...rest} />;
};

export default RouterLink;
