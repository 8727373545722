import { memo } from 'react';

import { FormControl, FormLabel, TextField, TextFieldProps } from '@mui/material';

import { labelStyle, textFieldStyle } from './const';

export type CampaignNameProps = Omit<TextFieldProps, 'variant' | 'autoComplete' | 'sx'>;

const CampaignName = memo((props: CampaignNameProps) => {
  return (
    <FormControl>
      <FormLabel htmlFor={props.id} required sx={labelStyle}>
        캠페인명
      </FormLabel>
      <TextField variant="outlined" autoComplete="off" sx={textFieldStyle} {...props} />
    </FormControl>
  );
});

CampaignName.displayName = 'CampaignName';

export default CampaignName;
