import * as C from 'api/campaign';
import { ResponseSuccess } from 'api/types';

export enum MediaPlatformTag {
  없음 = 2,
  'for Kakao' = 4,
  Line = 5,
  'with Band' = 6,
  'for afreeca' = 7,
  'for Kakao AD+' = 8,
}

/**
 * 매체 정보
 */
export type Media = {
  tagList: string[];
  mediaKey: number;
  mediaNo: number;
  mediaName: string;
  companyName: string;
  mediaPlatformTagNo: MediaPlatformTag;
  integrationType: C.MediaIntegrationType;
  platformType: C.PlatformType;
  isMarketReviewing: boolean;
  registDatetime: string; // "2021-11-10T15:47:34.763"
};

/**
 * 매체 목록 조회 응답
 */
export type MediaListResponseSuccess = ResponseSuccess & {
  MediaList: Media[];
};

/**
 * 매체 iOS 마켓 검수 옵션 업데이트 데이터
 */
export type UpdateMediaMarketReviewStatusData = Pick<Media, 'mediaKey'> & {
  toggleStatus: boolean;
};

/**
 * 매체 태그 업데이트 요청 데이터 (매체 일괄 업데이트)
 */
export type UpdateMultipleMediaTagMatchData = {
  mediaKeyList: Media['mediaKey'][];
  mediaTagNoList: C.MediaTag['tagNo'][];
  replaceTag: false;
};

/**
 * 매체 태그 업데이트 요청 데이터 (단일 매체 업데이트)
 */
export type UpdateSingleMediaTagMatchData = {
  mediaKeyList: [Media['mediaKey']];
  mediaTagNoList: C.MediaTag['tagNo'][];
  replaceTag: true;
};

/**
 * 매체 태그 업데이트 요청 데이터
 */
export type UpdateMediaTagMatchData =
  | UpdateMultipleMediaTagMatchData
  | UpdateSingleMediaTagMatchData;

/**
 * 특정 매체 단가 조회 params
 */
export type MediaPriceListParams = {
  mediaKey: number;
};

export type MediaPriceMeta = C.StandardMediaPriceMeta & {
  specialMediaPrice: number;
  specialMediaRSRate: number; // double
};

/**
 * 특정 매체 단가 조회 응답
 */
export type MediaPriceListResponseSuccess = ResponseSuccess & {
  MediaPriceList: MediaPriceMeta[];
};

export enum MediaCampaignStatus {
  라이브 = 1,
  보상 = 2,
  정지 = 3,
}

export enum NewsRCPMMediaCampaignStatus {
  비활성 = 0,
  활성 = 1,
}

/**
 * 노출 캠페인 정보
 */
export type MediaCampaign = {
  campaignKey: string;
  campaignName: string;
  openDateTime: string;
  closeDateTime: string;
  participationType: C.ParticipationType;
  integrationType: C.IntegrationType;
  platformType: C.PlatformType;
  settlementType: C.SettlementType;
  isOpenToUser: boolean;
  isCanComplete: boolean;
  mediaPrice: number;
  campaignNetPrice: number;
};

/**
 * 노출 캠페인 - 뉴스적립 정보
 */
export type NewsMediaCampaign = {
  integrationTypeName: string;
  integrationTypeNo: number;
  status: number;
  rsRate: number;
  newsPoint: number;
};

/**
 * 노출 캠페인 - 간편적립 정보
 */
export type RCPMMediaCampaign = {
  integrationTypeName: string;
  integrationTypeNo: number;
  status: number;
  rsRate: number;
  rcpmPoint: number;
};

/**
 * 노출 캠페인 - RV 정보
 */
export type RVMediaCampaign = {
  integrationTypeName: string;
  integrationTypeNo: number;
  status: number;
  rsRate: number;
  rvPoint: number;
};

/**
 * 노출 캠페인 목록 조회 params
 */
export type MediaCampaignListParams = {
  mediaKey: Media['mediaKey'];
  status: MediaCampaignStatus;
};

/**
 * 노출 캠페인 목록 조회 params (뉴스적립, 간편적립)
 */

export type NewsMediaCampaignListParams = Omit<MediaCampaignListParams, 'status'>;
export type RCPMMediaCampaignListParams = Omit<MediaCampaignListParams, 'status'>;
export type RVMediaCampaignListParams = Omit<MediaCampaignListParams, 'status'>;

/**
 * 노출 캠페인 목록 조회 응답
 */
export type MediaCampaignListResponseSuccess = ResponseSuccess & {
  MediaName: string;
  MatchedCampaignList: MediaCampaign[];
};

/**
 * 노출 캠페인 (뉴스적립) 목록 조회 응답
 */
export type NewsMediaCampaignListResponseSuccess = ResponseSuccess & {
  MediaName: string;
  MediaIntegratedNewsInfoList: NewsMediaCampaign[];
};

/**
 * 노출 캠페인 (간편적립) 목록 조회 응답
 */
export type RCPMMediaCampaignListResponseSuccess = ResponseSuccess & {
  MediaName: string;
  MediaIntegratedRCPMInfoList: RCPMMediaCampaign[];
};

/**
 * 노출 캠페인 (RV) 목록 조회 응답
 */
export type RVMediaCampaignListResponseSuccess = ResponseSuccess & {
  MediaName: string;
  MediaIntegratedRVInfoList: RVMediaCampaign[];
};

/**
 * 노출 캠페인 삭제 데이터
 */
export type RemoveMediaCampaignListData = {
  mediaKey: number;
  campaignKeyList: C.Campaign['campaignKey'][];
};

/**
 * 캠페인 목록 조회(검색어) params
 */
export type CampaignMetaListBySearchKeyParams = {
  searchKey: string;
  filterMediaKey?: number; //해당 미디어 키에 연결된 캠페인 필터
};

/**
 * 캠페인 목록 조회 응답
 */
export type CampaignMetaListBySearchKeyResponseSuccess = ResponseSuccess & {
  CampaignList: C.CampaignMeta[];
};

/**
 * 캠페인 목록 조회(태그) params
 */
export type CampaignMetaListByTagNoParams = {
  includeTagNoList: C.CampaignTag['tagNo'][];
  excludeTagNoList: C.CampaignTag['tagNo'][];
  filterMediaKey?: number; //해당 미디어 키에 연결된 캠페인 필터
};

/**
 * 매체 목록 조회(태그) 응답
 */
export type CampaignMetaListByTagNoResponseSuccess = ResponseSuccess & {
  CampaignCount: number;
  CampaignList: C.CampaignMeta[];
};

/**
 * 캠페인 추가 데이터
 */
export type AddMediaCampaignData = {
  mediaKey: Media['mediaKey'];
  campaignKeyList: C.Campaign['campaignKey'][];
};

/**
 * 노출 캠페인 상태 업데이트 데이터
 */
export type UpdateMediaCampaignStatusData = {
  mediaKey: Media['mediaKey'];
  campaignKeyList: C.Campaign['campaignKey'][];
  campaignStatus: MediaCampaignStatus;
};

/**
 * 노출 캠페인(뉴스적립) 상태 업데이트 데이터
 */
export type UpdateNewsMediaCampaignStatusData = {
  mediaKey: Media['mediaKey'];
  integrationTypeNoList: number[];
  status: NewsRCPMMediaCampaignStatus;
};

/**
 * 노출 캠페인(간편적립) 상태 업데이트 데이터
 */

export type UpdateRCPMMediaCampaignStatusData = UpdateNewsMediaCampaignStatusData;

/**
 * 노출 캠페인(RV) 상태 업데이트 데이터
 */

export type UpdateRVMediaCampaignStatusData = UpdateNewsMediaCampaignStatusData;

/**
 * 노출 캠페인 RS 업데이트 데이터
 */
export type UpdateMediaPointData = {
  mediaKey: Media['mediaKey'];
  integrationTypeNo: number;
  point: number;
};

/**
 * 노출 캠페인 포인트 업데이트 데이터
 */
export type UpdateMediaRSData = {
  mediaKey: Media['mediaKey'];
  integrationTypeNo: number;
  rs: number;
};

export type UpdateMediaPriceDataPartial = Pick<
  C.StandardMediaPriceMeta,
  'participationType' | 'mediaPrice' | 'mediaRSRate'
> & {
  useRSRate: boolean;
};

/**
 * 매체 표준 단가 업데이트 데이터
 */
export type UpdateStandardMediaPriceData = {
  standardPriceInfoList: UpdateMediaPriceDataPartial[];
};

/**
 * 특정 매체 단가 업데이트 데이터
 */
export type UpdateSpecialMediaPriceData = {
  mediaKeyList: Media['mediaKey'][];
  specialMediaPriceList: (UpdateMediaPriceDataPartial & {
    specialMediaPrice: number;
    specialMediaRSRate: number;
  })[];
};

/**
 * 매체 승인 상태
 */
export enum MediaApprovalStatus {
  승인 = 1,
  요청 = 2,
  반려 = 3,
  생성 = 4,
}

/**
 * 매체 승인 목록 조회 params
 */
export type MediaApprovalListParams = {
  approvalStatus: MediaApprovalStatus;
};

/**
 * 매체 승인 정보
 */
export type MediaApproval = {
  approvalRequestNo: number;
  customerNo: number;
  customerName: string;
  mediaKey: number;
  mediaName: string;
  requestMessage: string;
  registDateTime: string; // "2021-09-24T10:20:40.143"
  approvalItemNo: number;
  approvalStatus: MediaApprovalStatus;
  historyList: string[] | null;
};

/**
 * 매체 승인 목록 조회 응답
 */
export type MediaApprovalListResponseSuccess = ResponseSuccess & {
  ApprovalList: MediaApproval[];
};

/**
 * 매체 승인 상태 업데이트 (승인 | 반려)
 */
export type UpdateMediaApprovalStatusData = {
  message: string;
  approvalStatus: MediaApprovalStatus.승인 | MediaApprovalStatus.반려;
  approvalRequestNo: number;
  approvalItemNo: number;
};

/**
 * 매체 강제 승인 요청 데이터
 */
export type ForceApprovalData = Pick<Media, 'mediaKey'>;

/**
 * 매체 승인 정보 조회 요청 params
 */
export type MediaApprovalInfoParams = {
  approvalRequestNo: MediaApproval['approvalRequestNo'];
};

export type MediaApprovalHistory = {
  approvalHistoryNo: number;
  approvalItemNo: number;
  approvalStatus: MediaApprovalStatus;
  registDateTime: string; // "2021-05-31T18:10:12.983"
  message: string;
  customerNo: number;
  customerName: string;
  customerRealName: string;
};

/**
 * 매체 승인 정보 조회 응답 결과
 */
export type MediaApprovalInfoResponseSuccess = ResponseSuccess & {
  ApprovalHistory: null | {
    approvalRequestNo: number;
    customerNo: number;
    customerName: string;
    mediaKey: number;
    mediaName: string;
    requestMessage: string;
    registDateTime: string; // "2021-04-07T17:23:12.947"
    approvalItemNo: number;
    approvalStatus: number;
    historyList: MediaApprovalHistory[];
  };
};

/**
 * 매체 플랫폼 정보
 */
export type MediaPlatform = {
  settlementMediaPlatformMatchNo: number;
  settlementMediaPlatformNo: MediaPlatformTag;
  settlementMediaPlatformName: string;
  settlementCampaignTypeNo: C.SettlementType;
  settlementCampaignTypeName: string;
  revenueSharingRate: number;
  beforehandRevenueSharingRate: number;
  registDatetime: string; // "2015-10-22T10:50:39.067"
  updateDatetime: string; // "2015-10-22T10:50:39.067"
  connectedMediaCount: number;
};

/**
 * 매체 플랫폼 목록 조회
 */
export type MediaPlatformListResponseSuccess = ResponseSuccess & {
  MediaPlatformList: MediaPlatform[];
};

/**
 * 연결 매체 목록 조회 params
 */
export type ConnectedMediaByPlatformParams = Pick<MediaPlatform, 'settlementMediaPlatformNo'>;

/**
 * 연결 매체 정보
 */
export type ConnectedMedia = {
  mediaNo: number;
  mediaKey: number;
  comapanyName: string | null;
  mediaName: string;
  osPlatform: C.PlatformType;
};

/**
 * 연결 매체 목록 조회 결과
 */
export type ConnectedMediaByPlatformResponseSuccess = ResponseSuccess & {
  ConnectedMediaPlatformList: ConnectedMedia[];
};

/**
 * 연결 매체 RS 비율 업데이트
 */
export type UpdatePlatformRSRateData = Pick<
  MediaPlatform,
  | 'settlementMediaPlatformMatchNo'
  | 'settlementMediaPlatformNo'
  | 'settlementCampaignTypeNo'
  | 'revenueSharingRate'
>;

/**
 * 매체 승인 -> 검수 정보 -> 검증 신청서 데이터 조회
 */
export type MediaQAEmailFormParams = {
  approvalRequestNo: number;
};

export type MediaQAEmailForm = {
  customerName: string;
  phoneNumber: string;
  customerEmail: string;
  companyName: string;
  mediaName: string;
  platform: string;
  offerwallMenuLocation: string;
  pointCheck: string;
  fileLink: string;
  additionDescription: string;
  registDatetime: string; // "2022-10-12T14:22:37"
};

export type MediaQAEmailFormResponseSuccess = ResponseSuccess & {
  EmailForm: MediaQAEmailForm | null;
};
