import {
  RowSelectionState,
  SortingState,
  PaginationState,
  VisibilityState,
  ColumnOrderState,
  ExpandedState,
} from '@tanstack/react-table';
import { atomFamily } from 'recoil';

export type Param<T> = { tableId: string; value?: T };

// 열 선택
export const defaultRowSelectionState: RowSelectionState = {};

export const rowSelectionState = atomFamily<RowSelectionState, string>({
  key: 'table/rowSelectionState',
  default: defaultRowSelectionState,
});

// 정렬
export const defaultSortingState: SortingState = [];

export const sortingState = atomFamily<SortingState, Param<SortingState>>({
  key: `table/sortingState`,
  default: (param) => param.value || defaultSortingState,
});

// 페이징
export const DEFAULT_PAGE_SIZE = 50;

export const defaultPaginationState: PaginationState = {
  pageIndex: 0,
  pageSize: DEFAULT_PAGE_SIZE,
};

export const paginationState = atomFamily<PaginationState, Param<PaginationState>>({
  key: 'table/paginationState',
  default: (param) => param.value || defaultPaginationState,
});

// 글로벌 필터
export type GlobalFilterState = string | number | Record<string, string | number>;
export const defaultGlobalFilterState: GlobalFilterState = '';

export const globalFilterState = atomFamily<GlobalFilterState, Param<GlobalFilterState>>({
  key: 'table/globalFilterState',
  default: (param) => (param.value === undefined ? defaultGlobalFilterState : param.value),
});

// 컬럼 필터
export type ColumnFilter = {
  id: string;
  value: string | number;
};

export type ColumnFiltersState = ColumnFilter[];
export const defaultColumnFiltersState: ColumnFiltersState = [];

export const columnFiltersState = atomFamily<ColumnFiltersState, Param<ColumnFiltersState>>({
  key: 'table/columnFiltersState',
  default: (param) => param.value || defaultColumnFiltersState,
});

// 컬럼 노출
export const defaultColumnVisibilityState: VisibilityState = {};

export const columnVisibilityState = atomFamily<VisibilityState, Param<VisibilityState>>({
  key: 'table/columnVisibilityState',
  default: (param) => param.value || defaultColumnVisibilityState,
});

// 컬럼 순서
export const defaultColumnOrderState: ColumnOrderState = [];

export const columnOrderState = atomFamily<ColumnOrderState, Param<ColumnOrderState>>({
  key: 'table/columnOrderState',
  default: (param) => param.value || defaultColumnOrderState,
});

// 중첩 row 상태
export const defaultExpandedState: ExpandedState = {};

export const expandedState = atomFamily<ExpandedState, string>({
  key: 'table/expandedState',
  default: defaultExpandedState,
});
