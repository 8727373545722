import { Box } from '@mui/material';

import { overflow, pageContainerStyle } from 'styles';

import Footer from './footer';
import Header from './header';
import Main from './main';
import { LayoutProps } from './types';

const Layout = (props: LayoutProps) => (
  <Box sx={[pageContainerStyle, overflow]}>
    <Header />
    <Main>{props.children}</Main>
    <Footer />
  </Box>
);

export default Layout;
