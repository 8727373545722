import { memo, useMemo } from 'react';

import { FormControl, FormLabel, MenuItem, Select, SelectProps } from '@mui/material';

import { PlatformType } from 'api';

import { labelStyle, textFieldStyle } from './const';

export type SelectPlatformProps = Omit<SelectProps<PlatformType>, 'sx'>;

const SelectPlatform = memo((props: SelectPlatformProps) => {
  const platformTypeOptions = useMemo(
    () => [
      <MenuItem value={PlatformType.Android} key={PlatformType[PlatformType.Android]}>
        {PlatformType[PlatformType.Android]}
      </MenuItem>,
      <MenuItem value={PlatformType.Web} key={PlatformType[PlatformType.Web]}>
        {PlatformType[PlatformType.Web]}
      </MenuItem>,
      <MenuItem value={PlatformType.iOS} key={PlatformType[PlatformType.iOS]}>
        {PlatformType[PlatformType.iOS]}
      </MenuItem>,
    ],
    []
  );

  return (
    <FormControl>
      <FormLabel htmlFor={props.id} required sx={labelStyle}>
        운영 체제 선택
      </FormLabel>
      <Select sx={textFieldStyle} {...props}>
        {platformTypeOptions}
      </Select>
    </FormControl>
  );
});

SelectPlatform.displayName = 'SelectPlatform';

export default SelectPlatform;
