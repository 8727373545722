import { SnackbarOrigin } from '@mui/material';

export const SNACKBAR_STATE_KEY = 'snackbar';

export const DEFAULT_DURATION = 3000;

export const DEFAULT_ORIGIN: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'right',
};
