import { CssBaseline, GlobalStyles as MuiGlobalStyles } from '@mui/material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const GlobalStyles = () => {
  return (
    <MuiGlobalStyles
      styles={{
        body: {
          overflow: 'hidden',
        },
        fieldset: {
          padding: 0,
          margin: 0,
          border: 'none',
        },
        'ol, ul, li': {
          padding: 0,
          margin: 0,
          listStyle: 'none',
        },
        pre: {
          fontFamily: 'Roboto',
          margin: 0,
        },
        'p, dl, dt, dd': {
          margin: 0,
        },
      }}
    />
  );
};

const ResetStyle = () => (
  <>
    <CssBaseline />
    <GlobalStyles />
  </>
);

export default ResetStyle;
