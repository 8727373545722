import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useTheme } from '@mui/material';
import { useRecoilState } from 'recoil';

import { TableLayerOrder, DEFAULT_ROW_HEIGHT } from 'components';
import { AnyObject } from 'utils';

import { tableModalState } from './atoms';
import { UseTableModalOptions } from './types';

const useTableModal = <T extends AnyObject>(options: UseTableModalOptions<T>) => {
  const {
    tableId,
    modalName,
    isStickyCell = false,
    rowIdx,
    rowsCount,
    columns,
    columnId,
    rowHeight = DEFAULT_ROW_HEIGHT,
  } = options;

  const [tableModalName, setTableModalName] = useRecoilState(tableModalState(tableId));
  const [tableHeight, setTableHeight] = useState(0);

  const mount = tableModalName === modalName;
  const totalRowHeight = rowsCount * rowHeight;
  const safeAreaHeight = 150;

  const endIdx = rowsCount - 1;
  const indexOfLastThreeRows = rowIdx + 3;

  const alignY =
    // row 높이 합 + 하단 공백 <= 테이블 높이
    totalRowHeight + safeAreaHeight <= tableHeight
      ? 'top'
      : // 최하단 row 3개
      indexOfLastThreeRows > endIdx
      ? 'bottom'
      : 'top';

  const alignX =
    columns.findIndex(({ id }) => id === columnId) === columns.length - 1 ? 'right' : 'left';

  const handleOpenModal = useCallback(() => {
    setTableModalName(modalName);
  }, [setTableModalName, modalName]);

  const handleCloseModal = useCallback(() => {
    setTableModalName('');
  }, [setTableModalName]);

  const cellRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  useLayoutEffect(() => {
    const resizableCellEl = cellRef.current?.parentElement;

    if (resizableCellEl) {
      resizableCellEl.style.overflow = mount ? 'visible' : '';
      resizableCellEl.style.border = mount ? `1px solid ${theme.palette.line.primary}` : '';

      if (isStickyCell) {
        resizableCellEl.style.zIndex = mount ? `${TableLayerOrder.FixedRowCellPopup}` : '';
      } else {
        resizableCellEl.style.position = mount ? 'relative' : '';
      }
    }
  }, [tableModalName, isStickyCell, mount, theme]);

  useEffect(() => {
    const tableEl = document.getElementById(tableId);

    if (tableEl) {
      setTableHeight(tableEl.offsetHeight);
    }
  }, [tableId]);

  return {
    cellRef,
    mount,
    handleOpenModal,
    handleCloseModal,
    alignY,
    alignX,
    zIndex: isStickyCell ? TableLayerOrder.FixedRowCellPopup : TableLayerOrder.FixedRowCell,
  } as const;
};

export default useTableModal;
