import { object, string, ref } from 'yup';

import { newPasswordRegex } from 'utils';

import { ChangePasswordFormFields } from './types';

export const CHANGE_PASSWORD = 'change-password';

export const inputIdByField: { [K in ChangePasswordFormFields]: string } = {
  userId: `${CHANGE_PASSWORD}-user-id`,
  oldPassword: `${CHANGE_PASSWORD}-old-password`,
  newPassword: `${CHANGE_PASSWORD}-new-password`,
  newPasswordConfirm: `${CHANGE_PASSWORD}-new-password-confirm`,
};

export const chagnePasswordFormValidationMessages: {
  [K in ChangePasswordFormFields]: { [key: string]: string };
} = {
  userId: {
    email: '이메일 형식을 확인해주세요.',
    required: '이메일을 입력해주세요.',
  },
  oldPassword: {
    required: '기존 비밀번호를 입력해주세요.',
  },
  newPassword: {
    required: '신규 비밀번호를 입력해주세요.',
    matches: '비밀번호 형식이 잘못되었습니다. (영문, 숫자, 특수문자 조합 8자리 이상)',
  },
  newPasswordConfirm: {
    required: '신규 비밀번호를 입력해주세요.',
    oneOf: '입력한 비밀번호가 일치하지 않습니다.',
  },
};

export const changePasswordFormValidationSchema = object({
  userId: string()
    .email(chagnePasswordFormValidationMessages.userId.email)
    .required(chagnePasswordFormValidationMessages.userId.required),
  oldPassword: string().required(chagnePasswordFormValidationMessages.oldPassword.required),
  newPassword: string()
    .required(chagnePasswordFormValidationMessages.newPassword.required)
    .matches(newPasswordRegex, chagnePasswordFormValidationMessages.newPassword.matches),
  newPasswordConfirm: string()
    .required(chagnePasswordFormValidationMessages.newPasswordConfirm.required)
    .oneOf(
      [ref('newPassword'), null],
      chagnePasswordFormValidationMessages.newPasswordConfirm.oneOf
    ),
});
