import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import { Box, Stack, TextField } from '@mui/material';
import { isFunction, isNaN } from 'lodash-es';

import { Modal } from 'components/modal';
import ModalButtonGroup from 'components/modal-button-group';
import { useTypedFormik } from 'hooks';
import { fullWidth } from 'styles';

import { TableModalFormProps, TableModalFormValues } from './types';

const TableModalForm = <T extends TableModalFormValues>(props: TableModalFormProps<T>) => {
  const {
    title,
    acceptDecimal,
    modalName,
    alignX,
    alignY,
    zIndex,
    initialValues,
    fieldName,
    validationSchema,
    placeholder = '',
    onSubmit,
    onSaveValues,
    onClose,
  } = props;

  const { values, handleSubmit, setValues, handleBlur, shouldErrorShows, getHelperText, isValid } =
    useTypedFormik({
      initialValues,
      onSubmit,
      validationSchema,
    });

  const handleChangeFieldValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const fieldValueType = typeof initialValues[fieldName];

      if (acceptDecimal) {
        return setValues((prev) => ({
          ...prev,
          [fieldName]: value,
        }));
      }

      if (fieldValueType === 'number') {
        const valueToNum = Number(value);

        if (!isNaN(valueToNum)) {
          setValues((prev) => ({
            ...prev,
            [fieldName]: valueToNum,
          }));
        }
        return;
      }

      setValues((prev) => ({
        ...prev,
        [fieldName]: value,
      }));
    },
    [fieldName, initialValues, setValues]
  );

  const handleBlurField = useMemo(() => handleBlur(fieldName), [fieldName, handleBlur]);

  useEffect(() => {
    if (isFunction(onSaveValues)) {
      onSaveValues(values);
    }
  }, [onSaveValues, values]);

  return (
    <Modal
      title={title}
      modalName={modalName}
      renderOnMount
      showExitButton={false}
      disablePortal
      hideBackdrop
      disableEnforceFocus
      onClose={onClose}
      size="small"
      sx={[
        {
          position: 'absolute',
          zIndex,
        },
        {
          top: alignY === 'top' ? `calc(100% + 2px)` : 'initial',
          bottom: alignY === 'bottom' ? 'calc(100% + 1px)' : 'initial',
        },
        {
          left: alignX === 'left' ? -1 : 'initial',
          right: alignX === 'right' ? -1 : 'initial',
        },
      ]}
      modalContentStyle={{
        border: 1,
        borderColor: ({ palette }) => palette.line.darkGrey,
        position: 'initial',
        top: 'initial',
        left: 'initial',
        transform: 'none',
      }}
    >
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: '2px' }}>
        <Stack component="fieldset" alignItems="flex-start" spacing={3}>
          <TextField
            variant="outlined"
            autoComplete="off"
            placeholder={placeholder}
            error={shouldErrorShows(fieldName)}
            helperText={getHelperText(fieldName)}
            value={values[fieldName]}
            onChange={handleChangeFieldValue}
            onBlur={handleBlurField}
            sx={[
              fullWidth,
              {
                '& .MuiOutlinedInput-input': {
                  py: 1,
                  px: 2,
                },
              },
            ]}
          />
          <ModalButtonGroup
            confirmButtonText="저장"
            disableConfirm={!isValid}
            onCancel={onClose}
            type="submit"
            size="small"
          />
        </Stack>
      </Box>
    </Modal>
  );
};

export default TableModalForm;
