import { memo } from 'react';

import { Stack, Typography } from '@mui/material';

import { fullHeight, fullWidth } from 'styles';
import { formatNumber } from 'utils';

import { DailyCompleteProgressProps } from './types';

const DailyCompleteProgress = memo((props: DailyCompleteProgressProps) => {
  const {
    dailyCompleteProgress: [dailyCompleteCount, dailyGoalCount],
  } = props;

  const dailyGoalCountStr = dailyGoalCount === 0 ? '∞' : formatNumber(dailyGoalCount);

  return (
    <Stack sx={[fullWidth, fullHeight]}>
      <Stack direction="row" sx={{ m: 'auto 0 auto auto' }}>
        <Typography variant="body2">{formatNumber(dailyCompleteCount)}</Typography>
        <Typography
          variant="body2"
          sx={{
            pl: 2,
            position: 'relative',
            '&::before': {
              content: '"/"',
              position: 'absolute',
              top: '50%',
              left: 1,
              transform: 'translateY(-50%)',
              display: 'inline-block',
            },
          }}
        >
          {dailyGoalCountStr}
        </Typography>
      </Stack>
    </Stack>
  );
});

DailyCompleteProgress.displayName = 'DailyCompleteProgress';

export default DailyCompleteProgress;
