import { memo } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { getThemedProp } from 'styles';

import { InfoMessageProps } from './types';

const InfoMessage = memo((props: InfoMessageProps) => {
  const { title, description, sx = [] } = props;

  return (
    <Box
      sx={[
        {
          position: 'absolute',
          top: 24,
          left: -100,
          zIndex: 'tooltip',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Stack
        spacing={1}
        sx={[
          {
            width: 240,
            p: 2,
            borderRadius: 2,
            bgcolor: 'common.white',
            border: 1,
          },
          getThemedProp('borderColor', 'line.grey', 'line.darkGrey'),
        ]}
      >
        <Typography variant="body2" color="black" fontWeight="bold" textAlign="start">
          {title}
        </Typography>
        <Typography variant="body2" color="black" textAlign="start" sx={{ wordBreak: 'keep-all' }}>
          {description}
        </Typography>
      </Stack>
    </Box>
  );
});

InfoMessage.displayName = 'InfoMessage';

export default InfoMessage;
