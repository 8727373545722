import { ParsedQs } from 'qs';

import { SettlementListParams } from 'api';
import { useQueryParams } from 'hooks';
import { moment } from 'utils';

import { MIN_QUERY_DATE } from './const';

const useSettlementListParams = () => {
  return useQueryParams(getSettlementListParams);
};

export default useSettlementListParams;

function getSettlementListParams(parsedSearch: ParsedQs): SettlementListParams {
  const { searchYear, searchMonth } = parsedSearch;
  const minDate = moment(MIN_QUERY_DATE);
  const today = moment();
  const defaultDates = moment(today).subtract(1, 'month');

  const _searchYear = Number(searchYear);
  const parsedSearchYear = isNaN(_searchYear) ? defaultDates.year() : _searchYear;

  const _searchMonth = Number(searchMonth);
  const parsedSearchMonth = isNaN(_searchMonth) ? defaultDates.month() + 1 : _searchMonth;

  const dates = moment([parsedSearchYear, parsedSearchMonth - 1]);

  const isValidDates =
    dates.isValid() && dates.isSameOrAfter(minDate) && dates.isSameOrBefore(today);

  return {
    searchYear: isValidDates ? parsedSearchYear : defaultDates.year(),
    searchMonth: isValidDates ? parsedSearchMonth : defaultDates.month() + 1,
  };
}
