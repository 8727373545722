import { useMemo } from 'react';

import { Box, Typography, Stack, Select, MenuItem } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { CampaignSearchType } from 'api';
import SearchForm from 'components/search-form';
import { Option } from 'components/types';
import { useTypedFormik } from 'hooks';

import { SearchCampaignFormProps } from './types';

const SearchCampaignForm = (props: SearchCampaignFormProps) => {
  const { initialValues, onSubmit } = props;
  const { handleSubmit, getFieldProps } = useTypedFormik({ initialValues, onSubmit });

  const searchOptionList = useMemo<Option<CampaignSearchType>[]>(
    () => [
      {
        text: '담당자명',
        value: CampaignSearchType.담당자명,
      },
      {
        text: '캠페인명',
        value: CampaignSearchType.캠페인명,
      },
      {
        text: '캠페인키',
        value: CampaignSearchType.캠페인키,
      },
    ],
    []
  );

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography component="legend" sx={visuallyHidden}>
        캠페인 검색 폼
      </Typography>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Select
          {...getFieldProps('searchType')}
          sx={{
            minWidth: 120,
            height: 36,
            typography: 'body2',
          }}
        >
          {searchOptionList.map((option, idx) => (
            <MenuItem key={idx} value={option.value} sx={{ typography: 'body2' }}>
              {option.text}
            </MenuItem>
          ))}
        </Select>
        <SearchForm onClickSearch={handleSubmit} {...getFieldProps('searchText')} />
      </Stack>
    </Box>
  );
};

export default SearchCampaignForm;
