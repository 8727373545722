import { FormControl, Stack, TextField } from '@mui/material';
import { object, string } from 'yup';

import { CustomFormLabel, ModalButtonGroup } from 'components';
import { useTypedFormik } from 'hooks';
import { overflowY } from 'styles';

import { UpdateMediaApprovalFormProps } from './types';

const UpdateMediaApprovalForm = (props: UpdateMediaApprovalFormProps) => {
  const { label, initialValues, onSubmit, onCancel, loading = false } = props;
  const { handleSubmit, getFieldProps, shouldErrorShows, isValid } = useTypedFormik({
    initialValues,
    onSubmit,
    validationSchema: object({
      reason: string().required().trim().min(1),
    }),
  });

  return (
    <Stack component="form" onSubmit={handleSubmit} spacing={4}>
      <FormControl>
        <CustomFormLabel
          htmlFor="update-reason"
          required
          label={`${label} 사유`}
          textAlign="left"
        />
        <TextField
          id="update-reason"
          multiline
          rows={4}
          {...getFieldProps('reason')}
          error={shouldErrorShows('reason')}
          placeholder={`${label} 사유를 입력하세요.`}
          sx={{
            width: 1,
            minHeight: 108,
            mt: 3,
            '& .MuiOutlinedInput-root': { p: 0 },
            '& .MuiOutlinedInput-input': { p: 2 },
            '& .MuiInputBase-inputMultiline': overflowY,
          }}
        />
      </FormControl>
      <ModalButtonGroup
        onCancel={onCancel}
        confirmButtonText="저장"
        disableConfirm={!isValid || loading}
        loading={loading}
        type="submit"
      />
    </Stack>
  );
};

export default UpdateMediaApprovalForm;
