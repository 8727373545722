import { CSSProperties } from 'react';
import { ListChildComponentProps } from 'react-window';

import { CheckBoxOutlineBlank, CheckBox as CheckBoxIcon } from '@mui/icons-material';
import { ListSubheader, Checkbox, Typography } from '@mui/material';

import { hasOwnProperty } from 'utils';

import { LISTBOX_PADDING } from './const';
import { GroupingItem, ListItem } from './types';

function renderRow(props: ListChildComponentProps<ListItem[]>) {
  const { data, index, style } = props;
  const listItem = data[index];

  const inlineStyle: CSSProperties = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
    overflow: 'hidden',
  };

  if (hasOwnProperty(listItem, 'group')) {
    const { group, key } = listItem as GroupingItem;

    return (
      <ListSubheader key={key} style={{ ...inlineStyle, display: 'flex', alignItems: 'center' }}>
        {group}
      </ListSubheader>
    );
  }

  const { liProps, optionLabel, selected, multiple } = listItem;

  return (
    <li {...liProps} key={optionLabel} style={inlineStyle}>
      {multiple && (
        <Checkbox
          icon={<CheckBoxOutlineBlank fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          sx={{ mr: 2 }}
          checked={selected}
        />
      )}
      <Typography noWrap textOverflow="ellipsis">
        {optionLabel}
      </Typography>
    </li>
  );
}

export default renderRow;
