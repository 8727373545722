import { isNumber } from 'lodash-es';
import { number, object, string } from 'yup';

import { CSMacroType } from './types';

export const csMacroMessage: { [K in CSMacroType]: string } = {
  [CSMacroType['수동 입력']]: '',
  [CSMacroType['리워드 지급']]:
    '서비스 이용에 불편을 드려 대단히 죄송합니다.\n미지급 이력이 확인되어 광고 참여에 대한 보상을 지급해 드렸습니다.',
  [CSMacroType['참여 이력 없음']]:
    '확인 결과 요청하신 광고의 정상적인 캠페인 경로를 통한 참여 이력이 없는 것으로 확인되었습니다.\n저희 광고 리스트를 통해서 참여한 광고만 보상이 지급 가능합니다.\n광고 리스트에서 최초로 참여한 것이 아니라, 이전에 이미 앱을 설치하였거나 직접 마켓에서 앱을 다운받은 경우와 배너광고를 통해 앱을 설치한 경우는 포인트가 지급 되지 않습니다.',
  [CSMacroType['이미 참여한 광고']]:
    '확인 결과 이미 보상이 지급된 것으로 확인 되었습니다.\n앱 내에서 보상이 지급되었는지 다시 확인 부탁 드립니다. ',
  [CSMacroType['타 매체 참여']]:
    '확인 결과 이미 다른 앱 혹은 다른 채널에서 같은 광고에 참여를 하신 것으로 확인 되었습니다.\n\n동일한 광고의 경우 여러 앱 혹은 여러 채널에서 중복 참여가 불가능 하오니 이 점 양해 부탁 드립니다.',
  [CSMacroType['캠페인 미완료']]:
    '확인 결과 요청 하신 광고의 완료 시점까지 정상적으로 이루어지지 않은 것으로 확인 되었습니다.\n\n광고 설명에 기재되어 있는 내용대로 광고 완료 시점까지 진행이 되지 않을 시, 보상이 이루어지지 않을 수 있으니 다시 확인 부탁 드립니다.',
  [CSMacroType['이미 종료된 광고']]:
    '서비스 이용에 불편을 드려 대단히 죄송합니다.\n\n해당 광고의 경우 제공 업체의 사정으로 종료된 광고 혹은 일별 물량이 모두 소진되어 정상적으로 광고 참여가 이루어지지 않는 광고입니다.\n\n일별 물량이 모두 소진된 경우, 명일 재 참여가 가능하지만, 광고가 종료된 경우에는 재 참여가 어려우므로 다른 광고를 이용하시기를 부탁 드립니다.',
  [CSMacroType['회원가입형 광고']]:
    '서비스 이용에 불편을 드려 대단히 죄송합니다.\n\n광고 설명에 기재되어 있는 내용대로 광고 완료 지점까지 진행하셨음에도 광고 참여에 대한 보상을 받지 못하셨다면 저희 쪽으로 아래와 같은 정보를 보내주시길 부탁 드립니다.\n[참여한 광고명] , [가입자 성함] , [휴대전화번호]\n위 내용의 모든 정보가 저희 쪽으로 전달이 되어야만 확인이 가능하오니, 모든 정보를 작성 후 전달 부탁 드립니다.',
  [CSMacroType['CS문의 대기 답변']]:
    '전달 주신 정보를 통하여 확인 절차 진행하도록 하겠습니다.\n\n해당 광고의 경우 광고주 측 확인이 필요한 부분입니다.\n근무일 기준 (월~금) 짧게는 3~5일 길게는 약 10일의 시간이 소요될 수 있습니다.\n확인되는대로 회신드릴 예정이니 조금만 시간 양해 부탁드리며, 광고주 확인 후 문의 주신 E-mail 주소로 답변이 나가게 되니 E-mail 확인을 부탁 드리겠습니다.',
  [CSMacroType['내용 확인 어려움']]:
    '서비스 이용에 불편을 드려 대단히 죄송합니다.\n전달 주신 정보만으로는 저희 쪽에서 문제 파악에 어려움이 있습니다.\n\n광고 정상 참여 후 보상을 받지 못하셨다면 광고 참여 절차를 확인하여 전달 부탁 드립니다.\n광고의 오류로 인한 참여 실패나 보상 미지급 시에는 해당 오류 메시지 내용 및 오류 메시지가 노출된 시점 등을 함께 전달을 부탁 드리겠습니다.',
  [CSMacroType['과거 실행 이력 있음']]:
    '고객님의 정보 확인 결과, 이미 과거에 참여 이력이 있는 것으로 확인 되었습니다.\n신규 유저가 아닐 경우 포인트가 지급 되지 않습니다.',
  [CSMacroType['정상 참여 아님']]:
    '참여한 광고가 아닌 다른 광고를 선택한 후 문의를 남길 경우 정상적으로 대응이 이루어지지 않습니다.\n광고 리스트에 문의해야 할 광고 리스트가 노출 되지 않는 경우는 이미 정상적으로 포인트를 지급 받으셨거나 정상적인 절차로 광고에 참여하지 않은 경우입니다.\n해당 부분은 도움을 드리기에 어려움이 있으니 양해 부탁 드립니다.',
  [CSMacroType['닉네임 요청']]:
    '추가적인 정보 확인을 위하여 참여하신 광고의 앱 내 닉네임(ID) 정보를 함께 전달 부탁 드립니다.',
  [CSMacroType['주문 번호 요청']]:
    '추가적인 정보 확인을 위하여 참여하신 광고의 주문번호 정보를 함께 전달 부탁 드립니다.',
  [CSMacroType['광고주 확인중']]:
    '서비스 이용하심에 회신을 늦게 드리게 되어 대단히 죄송합니다.\n\n문의주신 내용으로 현재 광고주 측으로 확인중에 있습니다.\n회신이 오는대로 빠르게 답변 드릴 예정이니, 조금만 시간 양해 부탁드리겠습니다.',
  [CSMacroType['스크린샷 요청']]:
    '문의주신 캠페인의 경우, 달성 스크린샷을 전달해주셔야 정상 참여 유무 확인하여 리워드 지급을 도와드릴 수 있습니다.\n달성한 스크린샷을 캡쳐하여 cs.reward@adpopcorn.com 해당 메일로 전달 주시면 참여 유무 확인하여 리워드 적립 도와드리도록 하겠습니다.\n보내실 때 캠페인 명, 성함, 연락처 같이 기입하여 전달 부탁드리겠습니다.',
};

export const csReplyFormValidationSchema = object().shape({
  csMacroType: number()
    .required()
    .test('isValidType', '유효하지 않은 매크로 타입입니다.', (value) => {
      if (isNumber(value)) {
        return CSMacroType[value] !== undefined;
      }

      return false;
    }),
  csReplyMessage: string().required().min(1),
});
