import { useCallback } from 'react';

import { Download } from '@mui/icons-material';
import { IconButton, SxProps, Theme } from '@mui/material';
import { Cell, Column, HeaderGroup, Row } from '@tanstack/react-table';
import { isFunction } from 'lodash-es';

import { getButtonStyle } from 'styles';
import { AnyObject } from 'utils';

import { CHECKBOX_COLUMN_ID } from './table';

export type ExportTableProps<T extends AnyObject> = {
  rows: Row<T>[];
  columns: Column<T>[];
  excludedColumnId?: string[];
  cellMapper?: (cell: Cell<T, unknown>, idx: number) => unknown;
  footerGroups?: HeaderGroup<T>[];
  disabled?: boolean;
  sx?: SxProps<Theme>;
  fileName?: string;
};

const ExportTable = <T extends AnyObject>(props: ExportTableProps<T>) => {
  const {
    rows,
    columns,
    excludedColumnId = [],
    cellMapper,
    footerGroups = [],
    disabled = false,
    sx = [],
    fileName = 'sheet',
  } = props;

  const _excludedColumnId = [CHECKBOX_COLUMN_ID].concat(excludedColumnId);

  const exportTable = useCallback(async () => {
    if (disabled) return;
    const xlsx = await import('xlsx');
    const { writeFile, utils } = xlsx;

    const headerList = columns
      .filter((column) => !_excludedColumnId.includes(column.id))
      .map((column) => column.columnDef.header as string);

    const tableBody = rows.map((row) => {
      const rowData: Record<string, unknown> = {};

      const cells = row
        .getVisibleCells()
        .filter((cell) => !_excludedColumnId.includes(cell.column.id))
        .map(cellMapper || ((cell) => cell.getValue()));

      headerList.forEach((header, idx) => {
        rowData[header] = cells[idx];
      });

      return rowData;
    });

    const tableFooter = footerGroups.map((footerGroup) => {
      const headers = footerGroup.headers;
      const footerRow: Record<string, unknown> = {};

      headers.forEach((header) => {
        const headerName = header.column.columnDef.header as string;
        const footer = header.column.columnDef.footer;

        footerRow[headerName] = (
          isFunction(footer) ? footer(header.getContext()) : footer
        ) as string;
      });

      return footerRow;
    });

    const tableData = tableBody.concat(tableFooter);

    const ws = utils.json_to_sheet(tableData, {
      header: headerList,
    });

    const wb = utils.book_new();

    utils.book_append_sheet(wb, ws, 'SheetJS');
    writeFile(wb, `${fileName}.xlsx`);
  }, [_excludedColumnId, cellMapper, columns, disabled, fileName, footerGroups, rows]);

  return (
    <IconButton
      aria-label="테이블 내보내기"
      sx={[
        getButtonStyle(disabled ? 'disabled' : 'primary'),
        {
          borderRadius: 1,
          width: 36,
          height: 36,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onClick={exportTable}
      disabled={disabled}
    >
      <Download fontSize="small" />
    </IconButton>
  );
};

export default ExportTable;
