import { ChangeEvent, memo, useCallback } from 'react';

import { UpdateMediaStatusType } from 'api';
import { SwitchCell } from 'components';

import { ToggleRewardStateProps } from './types';

const ToggleRewardState = memo((props: ToggleRewardStateProps) => {
  const { campaignKey, mediaKey, isOpenToUser, isCanComplete, onToggleState } = props;

  const handleToggleState = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (isOpenToUser && !checked) return;

      onToggleState({
        campaignKey,
        mediaKey,
        toggleType: UpdateMediaStatusType.OpenReward,
        toggleStatus: checked,
      });
    },
    [campaignKey, isOpenToUser, mediaKey, onToggleState]
  );

  const label = `보상 상태 ${isCanComplete ? '활성화' : '비활성화'}`;

  return (
    <SwitchCell
      label={label}
      checked={isCanComplete}
      disabled={isOpenToUser && isCanComplete}
      onChange={handleToggleState}
    />
  );
});

ToggleRewardState.displayName = 'ToggleRewardState';

export default ToggleRewardState;
