import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { parse, stringify } from 'qs';

import { AnyObject } from 'utils';

export type ParsedQs = Record<string, string | undefined>;

const useQueryParams = <T extends AnyObject>(parser: (parsedSearch: ParsedQs) => T) => {
  const location = useLocation();
  const navigate = useNavigate();

  const parsedSearch = useMemo(() => {
    return parser(parse(location.search, { ignoreQueryPrefix: true }) as ParsedQs);
  }, [location.search, parser]);

  const handleSetSearchParams = useCallback(
    (params: Partial<T>, replace = false) => {
      const nextState = { ...parsedSearch, ...params };
      navigate({ search: stringify(nextState) }, { replace });
    },
    [navigate, parsedSearch]
  );

  useEffect(() => {
    navigate({ search: stringify(parsedSearch) }, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    queryParams: parsedSearch,
    setQueryParams: handleSetSearchParams,
  };
};

export default useQueryParams;
