import { Box, Stack, FormControl, TextField, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { CustomFormLabel, ModalButtonGroup } from 'components';
import { useTypedFormik } from 'hooks';
import { fullWidth, overflowY } from 'styles';

import { denyPaymentFormValidationSchema, inputIdByField } from './const';

export type DenyPaymentFormValues = {
  denyMessage: string;
};

export type DenyPaymentFormProps = {
  initialValues: DenyPaymentFormValues;
  onSubmit: (values: DenyPaymentFormValues) => void;
  onCancel: VoidFunction;
  loading: boolean;
};

const DenyPaymentForm = (props: DenyPaymentFormProps) => {
  const { initialValues, onSubmit, onCancel, loading } = props;

  const { handleSubmit, getFieldProps, isValid, shouldErrorShows } = useTypedFormik({
    initialValues,
    onSubmit,
    validationSchema: denyPaymentFormValidationSchema,
    validateOnMount: true,
  });

  return (
    <Box component="form">
      <Typography component="legend" sx={visuallyHidden}>
        반려 사유 전송 폼
      </Typography>
      <Stack component="fieldset" spacing={4}>
        <FormControl sx={{ flexDirection: 'row', alignItems: 'start', mt: '2px' }}>
          <CustomFormLabel
            htmlFor={inputIdByField.denyMessage}
            label="반려 사유"
            width={80}
            required
            sx={{ mt: 1 }}
          />
          <TextField
            id={inputIdByField.denyMessage}
            multiline
            rows={4}
            sx={[
              fullWidth,
              {
                minHeight: 108,
                ml: 4,
                '& .MuiOutlinedInput-root': { p: 0 },
                '& .MuiOutlinedInput-input': { p: 2 },
                '& .MuiInputBase-inputMultiline': overflowY,
              },
            ]}
            placeholder="반려 사유"
            {...getFieldProps('denyMessage')}
            error={shouldErrorShows('denyMessage')}
          />
        </FormControl>
        <ModalButtonGroup
          onCancel={onCancel}
          confirmButtonText="확인"
          disableConfirm={!isValid || loading}
          loading={loading}
          type="button"
          onConfirm={() => handleSubmit()}
        />
      </Stack>
    </Box>
  );
};

export default DenyPaymentForm;
