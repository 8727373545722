import { memo } from 'react';

import { FormLabel } from '@mui/material';

import { CustomFormLabelProps } from './types';

const CustomFormLabel = memo((props: CustomFormLabelProps) => {
  const {
    htmlFor,
    width = 'initial',
    required = false,
    textAlign = 'right',
    label,
    sx = [],
  } = props;

  return (
    <FormLabel
      htmlFor={htmlFor}
      sx={[
        {
          width,
          minWidth: width,
          color: 'text.primary',
          wordBreak: 'keep-all',
          textAlign,
          fontWeight: 500,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {required ? `* ${label}` : label}
    </FormLabel>
  );
});

CustomFormLabel.displayName = 'CustomFormLabel';

export default CustomFormLabel;
