import { useTheme, useMediaQuery, Stack, Typography, Divider } from '@mui/material';

import * as s from './footer.style';

const Footer = () => {
  const theme = useTheme();
  const matches = useMediaQuery(`(min-width: ${theme.breakpoints.values.md}px)`);

  return (
    <Stack component="footer" justifyContent="center" alignItems="center" sx={s.footerStyle}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Typography component="p" variant="subtitle2">
          Copyright © AdPopcorn All Rights Reserved.
        </Typography>
        {matches && (
          <Stack
            component="dl"
            direction="row"
            spacing={2}
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Stack direction="row">
              <Typography component="dt" variant="subtitle2" sx={s.dtStyle}>
                사업자 등록번호
              </Typography>
              <Typography component="dd" variant="subtitle2">
                694-88-02136
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography component="dt" variant="subtitle2" sx={s.dtStyle}>
                주소
              </Typography>
              <Typography component="dd" variant="subtitle2">
                (04036) 서울특별시 마포구 양화로 45, 15층(서교동)
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default Footer;
