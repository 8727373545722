import { get } from 'api/request';

import * as T from './types';

export default class LogAPI {
  static getSystemLogList(token: string, params: T.SystemLogListParams) {
    return get<T.SystemLogListResponseSuccess, T.SystemLogListParams>(
      '/api/v1/management/LogInfo/SystemLog',
      token,
      params
    );
  }

  static getCampaignLogList(token: string, params: T.CampaignLogListParams) {
    return get<T.CampaignLogListResponseSuccess, T.CampaignLogListParams>(
      '/api/v1/management/LogInfo/CampaignLog',
      token,
      params
    );
  }
}
