import { ReplyCSData } from 'api';

/**
 * CS 회신 메시지 타입
 */
export enum CSMacroType {
  '수동 입력' = 0,
  '리워드 지급' = 1,
  '참여 이력 없음' = 2,
  '이미 참여한 광고' = 3,
  '타 매체 참여' = 4,
  '캠페인 미완료' = 5,
  '이미 종료된 광고' = 6,
  '회원가입형 광고' = 7,
  'CS문의 대기 답변' = 8,
  '내용 확인 어려움' = 9,
  '과거 실행 이력 있음' = 10,
  '정상 참여 아님' = 11,
  '닉네임 요청' = 12,
  '주문 번호 요청' = 13,
  '광고주 확인중',
  '스크린샷 요청' = 15,
}

export type ReplyCSFormValues = Pick<ReplyCSData, 'csReplyMessage'> & {
  csMacroType: CSMacroType;
};
