import { createTheme, PaletteMode, responsiveFontSizes } from '@mui/material';
import { deepmerge } from '@mui/utils';

import { blue, amber, red, green, grey, blueGrey, white, black, darkGrey } from './colors';

const commonOption = createTheme({
  palette: {
    primary: blue,
    error: red,
    success: green,
    warning: amber,
    grey: grey,
    blueGrey: blueGrey,
    darkGrey: darkGrey,
    common: {
      white: white,
      black: black,
    },
  },
});

const lightOption = createTheme({
  palette: {
    mode: 'light',
    button: {
      primary: {
        color: white,
        bgc: blue[500],
        bgcHover: blue[700],
      },
      secondary: {
        color: blue[500],
        bgc: 'rgba(38, 153, 244, 0.15)',
        bgcHover: 'rgba(38, 153, 244, 0.25)',
      },
      default: {
        color: white,
        bgc: blueGrey[600],
        bgcHover: blueGrey[800],
      },
      disabled: {
        color: grey[500],
        bgc: grey[100],
        bgcHover: grey[200],
      },
      success: {
        color: white,
        bgc: green[500],
        bgcHover: green[700],
      },
      error: {
        color: white,
        bgc: red[500],
        bgcHover: red[700],
      },
      warning: {
        color: white,
        bgc: amber[500],
        bgcHover: amber[700],
      },
    },
    status: {
      success: green[500],
      warning: amber[500],
      error: red[500],
      test: blue[500],
    },
    text: {
      primary: grey[900],
      secondary: grey[700],
      disabled: grey[500],
      white: white,
      black: black,
      blueGrey: blueGrey[500],
      highlighted: blue[500],
    },
    line: {
      primary: blue[500],
      darkGrey: grey[400],
      grey: grey[200],
      lightGrey: grey[100],
    },
    background: {
      paper: white,
      default: white,
      grey: grey[100],
      lightGrey: grey[50],
      lightBlue: 'rgba(38, 153, 244, 0.04)',
    },
    icon: {
      primary: blue[500],
      darkGrey: grey[900],
      grey: grey[600],
      blueGrey: blueGrey[500],
      white: white,
    },
  },
  spacing: 4,
});

const darkOption = createTheme({
  palette: {
    mode: 'dark',
    button: {
      primary: {
        color: white,
        bgc: blue[400],
        bgcHover: blue[600],
      },
      secondary: {
        color: blue[400],
        bgc: 'rgba(69, 167, 246, 0.15)',
        bgcHover: 'rgba(69, 167, 246, 0.25)',
      },
      default: {
        color: blueGrey[500],
        bgc: 'rgba(255, 255, 255, 0.02)',
        bgcHover: 'rgba(255, 255, 255, 0.04)',
      },
      disabled: {
        color: darkGrey[500],
        bgc: darkGrey[800],
        bgcHover: darkGrey[700],
      },
      success: {
        color: white,
        bgc: green[400],
        bgcHover: green[600],
      },
      error: {
        color: white,
        bgc: red[400],
        bgcHover: red[600],
      },
      warning: {
        color: white,
        bgc: amber[400],
        bgcHover: amber[600],
      },
    },
    status: {
      success: green[400],
      warning: amber[400],
      error: red[400],
      test: blue[400],
    },
    text: {
      primary: white,
      secondary: darkGrey[500],
      disabled: darkGrey[600],
      white: white,
      black: black,
      blueGrey: blueGrey[500],
      highlighted: blue[400],
    },
    line: {
      primary: blue[400],
      darkGrey: darkGrey[800],
      grey: darkGrey[600],
      lightGrey: darkGrey[500],
    },
    background: {
      paper: blueGrey[900],
      default: blueGrey[900],
      darkGrey: darkGrey[800],
      grey: darkGrey[700],
      white: 'rgba(255, 255, 255, 0.04)',
    },
    icon: {
      primary: blue[400],
      darkGrey: darkGrey[500],
      grey: blueGrey[500],
      white: white,
    },
  },
  spacing: 4,
});

const lightTheme = createTheme(deepmerge(commonOption, lightOption));
const darkTheme = createTheme(deepmerge(commonOption, darkOption));

const lightThemeWithResponsiveFont = responsiveFontSizes(lightTheme);
const darkThemeWithResponsiveFont = responsiveFontSizes(darkTheme);

const getTheme = (mode: PaletteMode) => {
  return mode === 'light' ? lightThemeWithResponsiveFont : darkThemeWithResponsiveFont;
};

export default getTheme;
