import { useCallback } from 'react';

import axios, { AxiosError } from 'axios';

import { ResponseFail } from 'api';
import { SnackbarState, useSnackbar } from 'components';
import useUser, { UserStatus } from 'hooks/use-user';

const useAxiosErrorHandler = () => {
  const [, setSnackbar] = useSnackbar();
  const { setUser } = useUser();

  const axiosErrorHandler = useCallback(
    (error: unknown) => {
      if (!axios.isAxiosError(error)) {
        throw error;
      }

      const { response } = error as AxiosError<ResponseFail>;

      const snackbarState: SnackbarState = {
        open: true,
        severity: 'error',
        message: '서버 상태를 확인해주세요.',
      };

      if (response === undefined) {
        setSnackbar(snackbarState);
        return;
      }

      const { status, data } = response;

      if (status === 400) {
        setSnackbar({ ...snackbarState, message: data.ResultMsg });
        return;
      }

      if (status === 401) {
        setUser((prev) => ({ ...prev, accessToken: '', status: UserStatus.Expired, expiredAt: 0 }));
        setSnackbar({ ...snackbarState, message: '로그아웃되었습니다.' });
        return;
      }

      setSnackbar(snackbarState);
    },
    [setSnackbar, setUser]
  );

  return axiosErrorHandler;
};

export default useAxiosErrorHandler;
