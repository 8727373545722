import { memo } from 'react';

import { Stack, Typography } from '@mui/material';

import { SSO, fullWidth, fullHeight } from 'styles';
import { formatDate } from 'utils';

import { DurationProps } from './types';

const textStyle: SSO = {
  m: 'auto',
  whiteSpace: 'nowrap',
};

const Duration = memo((props: DurationProps) => {
  const { openDateTime, closeDateTime } = props;
  const startDateStr = openDateTime === 0 ? '시작일 미설정' : formatDate(openDateTime);
  const endDateStr = closeDateTime === 0 ? '종료일 미설정' : formatDate(closeDateTime);

  return (
    <Stack sx={[fullWidth, fullHeight]}>
      <Typography
        variant="body2"
        sx={[
          textStyle,
          {
            position: 'relative',
            '&::after': {
              content: '"~"',
              position: 'absolute',
              top: '50%',
              right: -12,
              transform: 'translateY(-50%)',
            },
          },
        ]}
      >
        {startDateStr}
      </Typography>
      <Typography variant="body2" sx={textStyle}>
        {endDateStr}
      </Typography>
    </Stack>
  );
});

Duration.displayName = 'Duration';

export default Duration;
