import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { S3API, UseUploadFileData } from 'api';
import useUser from 'hooks/use-user';

const useUploadFile = () => {
  const { user } = useUser();

  const mutateFn = useCallback(
    (data: UseUploadFileData) => {
      const { field, file } = data;
      const formData = new FormData();
      formData.append(field, file);

      return S3API.uploadFile(user.accessToken, formData);
    },
    [user.accessToken]
  );

  return useMutation(mutateFn);
};

export default useUploadFile;
