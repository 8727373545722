import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';

import { getButtonStyle } from 'styles';

import { ModalButtonGroupProps } from './types';

const ModalButtonGroup = (props: ModalButtonGroupProps) => {
  const {
    onCancel,
    confirmButtonText = '확인',
    disableConfirm = false,
    loading = false,
    type,
    onConfirm,
    size = 'medium',
    sx = [],
  } = props;

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      spacing={3}
      sx={[
        {
          position: 'sticky',
          left: 0,
          bottom: 0,
          width: 1,
          bgcolor: 'background.paper',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <LoadingButton
        type={type}
        variant="contained"
        size={size}
        loading={loading}
        disabled={disableConfirm}
        onClick={onConfirm}
        sx={[getButtonStyle(), { display: 'flex' }]}
      >
        {confirmButtonText}
      </LoadingButton>
      {onCancel && (
        <Button type="button" onClick={onCancel} size={size} sx={getButtonStyle('disabled')}>
          취소
        </Button>
      )}
    </Stack>
  );
};

export default ModalButtonGroup;
