import { memo } from 'react';

import { Stack, Box, Typography } from '@mui/material';

import { getThemedProp, overflowX } from 'styles';

import { DataListProps } from './types';

const borderStyle = getThemedProp('borderColor', 'line.grey', 'line.darkGrey');

const DataList = memo((props: DataListProps) => {
  const { dataList, dtWidth = 140, dtPosition = 'start', ddPosition = 'start' } = props;

  return (
    <Stack component="dl" sx={[{ m: 0, border: 2 }, borderStyle]}>
      {dataList.map((data, idx) => (
        <Stack
          key={idx}
          direction="row"
          sx={{
            '&:not(:last-child)': {
              borderBottom: 2,
              ...borderStyle,
            },
          }}
        >
          <Box
            component="dt"
            sx={[
              { flexShrink: 0, width: dtWidth, textAlign: dtPosition, borderRight: 2 },
              borderStyle,
            ]}
          >
            <Typography sx={{ p: 2 }}>{data.dt}</Typography>
          </Box>
          <Box component="dd" sx={[{ width: 1, m: 0, textAlign: ddPosition }, overflowX]}>
            <Typography whiteSpace="nowrap" sx={{ p: 2 }}>
              {data.dd}
            </Typography>
          </Box>
        </Stack>
      ))}
    </Stack>
  );
});

DataList.displayName = 'DataList';

export default DataList;
