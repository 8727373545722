import { IntegrationType, ParticipationType } from 'api';

export enum ParticipationTypeCategory {
  기본 = 0,
  SNS = 1,
  '간편 적립' = 2,
}

export type ParticipationTypeFormValue = ParticipationType | '';
export type IntegrationTypeFormValue = IntegrationType | '';
