import { object, string } from 'yup';

export const DENY_PAYMENT_FORM = 'deny-payment-form';

export const inputIdByField = {
  denyMessage: `${DENY_PAYMENT_FORM}-denyMessage`,
};

export const denyPaymentFormValidationSchema = object({
  denyMessage: string().required().trim().min(1),
});
