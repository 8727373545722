import { memo } from 'react';

import {
  Pagination as MuiPagination,
  PaginationProps as MuiPaginationProps,
  Stack,
  Typography,
} from '@mui/material';

import { formatNumber } from 'utils';

export type PaginationProps = Omit<MuiPaginationProps, 'page'> & {
  pageIndex: number;
  totalRowsCount: number;
  pageSize: number;
};

const Pagination = memo((props: PaginationProps) => {
  const { totalRowsCount, pageIndex, pageSize, ...rest } = props;

  const start = pageSize * pageIndex + 1;
  const safeStart = formatNumber(start <= totalRowsCount ? start : totalRowsCount);

  const end = pageSize * (pageIndex + 1);
  const safeEnd = formatNumber(end <= totalRowsCount ? end : totalRowsCount);

  const ranges = `${safeStart} - ${safeEnd} of ${formatNumber(totalRowsCount)}`;

  return (
    <Stack direction="row" justifyContent="center" sx={{ position: 'relative' }}>
      <Typography
        sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          transform: 'translateY(-50%)',
        }}
      >
        {ranges}
      </Typography>
      <MuiPagination page={pageIndex + 1} {...rest} />
    </Stack>
  );
});

Pagination.displayName = 'Pagination';

export default Pagination;
