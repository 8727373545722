import { useEffect } from 'react';

// NOTE: date-picker가 열려있을 때 tooltip이 노출되면, tooltip에 focus되어 date-picker가 닫힘.
// setTimeout으로 차트가 그려진 이후 tabindex 어트리뷰트를 제거
const useRemoveRechartsTabindex = (data: unknown[]) => {
  useEffect(() => {
    const removeTooltipTabIndex = () => {
      const tooltipWrapper = document.querySelector('.recharts-tooltip-wrapper');

      if (tooltipWrapper && tooltipWrapper.hasAttribute('tabindex')) {
        tooltipWrapper.removeAttribute('tabindex');
      }
    };

    if (data.length > 0) {
      setTimeout(() => removeTooltipTabIndex(), 300);
    }
  }, [data]);

  return null;
};

export default useRemoveRechartsTabindex;
