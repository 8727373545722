import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { CompleteMediaRewardData, CSAPI } from 'api';
import { useUser } from 'hooks';
import { MutationOptions } from 'query-client';

const useCompleteMediaReward = (options?: MutationOptions<CompleteMediaRewardData>) => {
  const { user } = useUser();

  const mutateFn = useCallback(
    (data: CompleteMediaRewardData) => {
      return CSAPI.completeMediaReward(user.accessToken, data);
    },
    [user.accessToken]
  );

  return useMutation(mutateFn, options);
};

export default useCompleteMediaReward;
