import { useCallback, useMemo } from 'react';

import { Modal, useModal } from 'components/modal';
import { useSnackbar } from 'components/snackbar';
import { useUser } from 'hooks';

import ChangePasswordForm from './change-password-form';
import { CHANGE_PASSWORD } from './const';
import { ChangePasswordFormValues } from './types';
import useChangePassword from './use-change-password';

const ChangePasswordFormContainer = () => {
  const { user } = useUser();
  const { isLoading, mutate } = useChangePassword();
  const { handleCloseModal } = useModal(CHANGE_PASSWORD);
  const [, setSnackbar] = useSnackbar();

  const initialValues = useMemo<ChangePasswordFormValues>(
    () => ({
      userId: user.userId,
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    }),
    [user]
  );

  const handleSubmit = useCallback(
    (values: ChangePasswordFormValues) => {
      mutate(values, {
        onSuccess: (response) => {
          handleCloseModal();

          setSnackbar({
            open: true,
            severity: 'success',
            message: response.data.ResultMsg,
          });
        },
      });
    },
    [mutate, handleCloseModal, setSnackbar]
  );

  return (
    <Modal title="비밀번호 변경" modalName={CHANGE_PASSWORD} disableExit={isLoading}>
      <ChangePasswordForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onCancel={handleCloseModal}
        loading={isLoading}
      />
    </Modal>
  );
};

export default ChangePasswordFormContainer;
