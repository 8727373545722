import { Box, Skeleton } from '@mui/material';

import { SSO } from 'styles';

import { CampaignIconProps } from './types';

const iconStyle: SSO = {
  display: 'block',
  width: 42,
  height: 42,
  flexShrink: 0,
  borderRadius: 1,
  border: 1,
  borderColor: 'line.lightGrey',
  overflow: 'hidden',
  bgcolor: 'background.grey',
};

const CampaignIcon = (props: CampaignIconProps) => {
  const { src, alt, sx = [] } = props;

  return src ? (
    <Box
      component="img"
      sx={[iconStyle, ...(Array.isArray(sx) ? sx : [sx])]}
      src={src}
      alt={alt || '아이콘'}
    />
  ) : (
    <Skeleton
      variant="rectangular"
      animation={false}
      aria-hidden
      sx={[iconStyle, ...(Array.isArray(sx) ? sx : [sx])]}
    />
  );
};

export default CampaignIcon;
