import { useRef, useMemo, useCallback, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';

import { useSnackbar } from 'components/snackbar';

import { DEFAULT_MAX_FILE_SIZE, formatMapper } from './const';
import { InputFileProps } from './types';

const InputFile = (props: InputFileProps) => {
  const {
    id = '',
    accept,
    maxFileSize = DEFAULT_MAX_FILE_SIZE,
    onChange,
    onFileSizeFail,
    loading = false,
    buttonText,
    icon,
    sx = [],
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [, setSnackbar] = useSnackbar();

  const fileExtensionList = useMemo(
    () => accept.map((extension) => formatMapper[extension]).join(', '),
    [accept]
  );

  const handleFileExtensionError = useCallback(() => {
    setSnackbar({
      open: true,
      severity: 'error',
      message: '지원하지 않는 파일 포맷입니다.',
    });
  }, [setSnackbar]);

  // const handleFileSizeError = useCallback(() => {
  //   const fileSizeToMB = maxFileSize / (1000 * 1000);

  //   setSnackbar({
  //     open: true,
  //     severity: 'error',
  //     message: `${fileSizeToMB.toFixed(1)}MB 이하 파일을 첨부해주세요.`,
  //   });
  // }, [maxFileSize, setSnackbar]);

  const handleChange = useCallback(
    (e: Event) => {
      const files = (e.target as HTMLInputElement).files;
      const hasFile = files && files.length > 0;

      if (hasFile) {
        const file = files[0];

        if (!fileExtensionList.includes(file.type)) {
          handleFileExtensionError();
          return;
        }

        // if (file.size > maxFileSize) {
        //   const fileSizeErrorHandler = onFileSizeFail || handleFileSizeError;
        //   fileSizeErrorHandler();
        //   return;
        // }

        onChange(file);

        if (inputRef.current) {
          inputRef.current.value = '';
        }
      }
    },
    [
      fileExtensionList,
      handleFileExtensionError,
      // handleFileSizeError,
      maxFileSize,
      onChange,
      onFileSizeFail,
    ]
  );

  useEffect(() => {
    const inputEl = inputRef.current;

    if (inputEl) {
      inputEl.addEventListener('change', handleChange);

      return () => {
        inputEl.removeEventListener('change', handleChange);
      };
    }
  }, [handleChange]);

  return (
    <label {...(id && { htmlFor: id })}>
      <input
        {...(id && { id })}
        type="file"
        ref={inputRef}
        accept={fileExtensionList}
        style={{ display: 'none' }}
        disabled={loading}
      />
      <LoadingButton loading={loading} component="span" sx={[...(Array.isArray(sx) ? sx : [sx])]}>
        {icon}
        <Box component="span">{buttonText}</Box>
      </LoadingButton>
    </label>
  );
};

export default InputFile;
