import { keyframes } from '@emotion/react';
import { ButtonType, TextColor, Theme } from '@mui/material';
import { SystemStyleObject, ResponsiveStyleValue, AllSystemCSSProperties } from '@mui/system';

export type SSO = SystemStyleObject<Theme>;

export const pageContainerStyle: SSO = {
  minWidth: ({ breakpoints }) => breakpoints.values.md,
  height: '100vh',
};

export const fullWidth: SSO = {
  width: 1,
};

export const fullHeight: SSO = {
  height: 1,
};

export const getTextColor = (type: TextColor = 'primary'): SSO => ({
  color: `text.${type}`,
});

export const getThemedProp = <K extends keyof AllSystemCSSProperties>(
  prop: K,
  lightValue: ResponsiveStyleValue<AllSystemCSSProperties[K]>,
  darkValue: ResponsiveStyleValue<AllSystemCSSProperties[K]>
): SSO => ({
  [prop]: ({ palette }) => (palette.mode === 'light' ? lightValue : darkValue),
});

export const getButtonStyle = (type: ButtonType = 'primary'): SSO => ({
  color: `button.${type}.color`,
  bgcolor: `button.${type}.bgc`,
  '&:hover': {
    bgcolor: `button.${type}.bgcHover`,
  },
  fontWeight: 'bold',
});

export const rotateAnimation = keyframes`
  from { transform: rotate(0deg) },
  to  { transform: rotate(360deg) },
`;

const overflowCommon: SSO = {
  overscrollBehavior: 'contain',
  WebkitOverflowScrolling: 'touch',
  msOverflowStyle: '-ms-autohiding-scrollbar',
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',

  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: ({ palette }) =>
      palette.mode === 'light' ? 'rgba(0, 0, 0, 0.2)' : palette.grey[500],
    borderRadius: 4,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: ({ palette }) =>
      palette.mode === 'light' ? 'rgba(0, 0, 0, 0.3)' : palette.grey[600],
  },
  '&::-webkit-scrollbar-thumb:active': {
    backgroundColor: ({ palette }) => (palette.mode === 'light' ? 'rgb(0, 0, 0)' : 'white'),
  },
  '&::-webkit-scrollbar-thumb:vertical': {
    minHeight: '1.5rem',
  },
  '&::-webkit-scrollbar-thumb:horizontal': {
    minWidth: '1.5rem',
  },
  '&::-webkit-scrollbar-corner': {
    backgroundColor: 'transparent',
  },
};

export const overflow: SSO = {
  overflow: 'auto',
  ...overflowCommon,

  '.has-obtrusive-scrollbar &::-webkit-scrollbar': {
    height: 12,
    width: 12,
  },
};

export const overflowX: SSO = {
  overflowX: 'auto',
  ...overflowCommon,

  '.has-obtrusive-scrollbar &::-webkit-scrollbar': {
    width: 0,
    height: 12,
  },
};

export const overflowY: SSO = {
  overflowY: 'auto',
  ...overflowCommon,

  '.has-obtrusive-scrollbar &::-webkit-scrollbar': {
    width: 12,
    height: 0,
  },
};

export const stickyTitle: SSO[] = [
  {
    position: 'sticky',
    top: 0,
    left: 0,
    pb: 5,
    zIndex: 5,
  },
  getThemedProp('bgcolor', 'grey.50', 'darkGrey.900'),
];
