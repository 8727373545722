import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useUser, UserStatus } from 'hooks';

import { RedirectState } from './redirect-route';

const AuthRoute = () => {
  const { user, isAuthorizedUser } = useUser();
  const { pathname, search } = useLocation();
  const nextPagePath = `${pathname}${search}`;

  const redirectState: RedirectState = {
    nextPagePath:
      user.status === UserStatus.LoggedOut
        ? 'dashboard'
        : user.status === UserStatus.Expired
        ? nextPagePath
        : '/',
  };

  if (!isAuthorizedUser) {
    return <Navigate to="/login" replace state={redirectState} />;
  }

  return <Outlet />;
};

export default AuthRoute;
