import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Menu as MenuIcon } from '@mui/icons-material';
import { Box, IconButton, List } from '@mui/material';

import { getThemedProp, SSO } from 'styles';
import { hasOwnProperty } from 'utils';

import { NO_SELECTED_MENU_IDX, NAV_MAX_WIDTH, NAV_MIN_WIDTH } from './const';
import { GroupedNavLinkItem, NavLinkItem } from './nav-link-item';
import { NavigationProps, NavLink } from './types';

const getNavStyle = (width: number): SSO => ({
  width,
  overflow: 'hidden',
  transition: (theme) =>
    theme.transitions.create(['width', 'transform'], {
      duration: theme.transitions.duration.standard,
    }),
});

const Navigation = (props: NavigationProps) => {
  const { links } = props;
  const { pathname } = useLocation();
  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [selectedMenuIdx, setSelectedMenuIdx] = useState(NO_SELECTED_MENU_IDX);

  const activeMenuIdx = useMemo(
    () =>
      links.findIndex((link) =>
        hasOwnProperty(link, 'subLinks') ? pathname.startsWith(link.to) : pathname === link.to
      ),
    [links, pathname]
  );

  const toggleNav = useCallback(() => {
    if (hovered) {
      setHovered(false);
      setSelectedMenuIdx(NO_SELECTED_MENU_IDX);
      return;
    }

    setClicked((prev) => {
      const navClicked = !prev;
      setSelectedMenuIdx(navClicked ? activeMenuIdx : NO_SELECTED_MENU_IDX);
      return navClicked;
    });
  }, [activeMenuIdx, hovered]);

  const handleSelectMenuIdx = useCallback(
    (idx: number) => () => {
      setSelectedMenuIdx(selectedMenuIdx === idx ? NO_SELECTED_MENU_IDX : idx);
    },
    [selectedMenuIdx]
  );

  const isSelectedMenuIdx = useCallback(
    (idx: number) => {
      return selectedMenuIdx === idx;
    },
    [selectedMenuIdx]
  );

  const handleHoverNav = useCallback(
    (e: MouseEvent) => {
      if (clicked) return;

      const { clientX, clientY } = e;
      const { innerHeight } = window;
      const mouseOut = clientX > NAV_MAX_WIDTH;
      const mouseIn =
        clientX <= NAV_MIN_WIDTH &&
        clientY >= NAV_MIN_WIDTH &&
        clientY < innerHeight - NAV_MIN_WIDTH;

      if (!hovered && mouseIn) {
        setSelectedMenuIdx(activeMenuIdx);
        setHovered(true);
      } else if (hovered && mouseOut) {
        setSelectedMenuIdx(NO_SELECTED_MENU_IDX);
        setHovered(false);
      }
    },
    [activeMenuIdx, clicked, hovered]
  );

  useEffect(() => {
    window.addEventListener('mousemove', handleHoverNav);

    return () => {
      window.removeEventListener('mousemove', handleHoverNav);
    };
  }, [handleHoverNav]);

  const open = clicked || hovered;

  return (
    <Box sx={{ position: 'relative', height: 1, bgcolor: 'blueGrey.900' }}>
      <IconButton
        sx={[
          {
            position: 'absolute',
            top: -NAV_MIN_WIDTH,
            left: 0,
            width: NAV_MIN_WIDTH,
            height: NAV_MIN_WIDTH,
            borderRight: 1,
            borderRadius: 0,
          },
          getThemedProp('borderColor', 'line.grey', 'line.darkGrey'),
        ]}
        aria-label="내비게이션 버튼"
        onClick={toggleNav}
      >
        <MenuIcon />
      </IconButton>
      <List component="nav" sx={getNavStyle(open ? NAV_MAX_WIDTH : NAV_MIN_WIDTH)}>
        {links.map((link, idx) =>
          hasOwnProperty(link, 'subLinks') ? (
            <GroupedNavLinkItem
              key={idx}
              pathname={pathname}
              onClick={handleSelectMenuIdx(idx)}
              expanded={isSelectedMenuIdx(idx)}
              {...link}
              subLinks={link.subLinks as NavLink[]}
            />
          ) : (
            <NavLinkItem
              key={idx}
              pathname={pathname}
              onClick={handleSelectMenuIdx(idx)}
              {...link}
            />
          )
        )}
      </List>
    </Box>
  );
};

export default Navigation;
