import { forwardRef } from 'react';
import { FixedSizeList } from 'react-window';

import { Box, Checkbox, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { overflowY } from 'styles';
import { AnyObject } from 'utils';

import { OptionsProps } from './types';

const DEFAULT_HEIGHT = 400;
const DEFAULT_ITEM_SIZE = 40;
const DEFAULT_OVERSCAN_COUNT = 5;

const Options = <T extends AnyObject>(props: OptionsProps<T>) => {
  const { options, checkedOptions, onToggle, uniqueKey, optionGenerator } = props;

  return (
    <FixedSizeList
      itemData={options}
      height={DEFAULT_HEIGHT}
      width="100%"
      itemSize={DEFAULT_ITEM_SIZE}
      overscanCount={DEFAULT_OVERSCAN_COUNT}
      itemCount={options.length}
      outerElementType={OuterElement}
    >
      {({ index, style, data }) => {
        const option = data[index];
        const labelId = `option-${option[uniqueKey]}`;

        return (
          <ListItemButton key={labelId} role="listitem" onClick={onToggle(option)} style={style}>
            <ListItemIcon>
              <Checkbox
                checked={
                  checkedOptions.findIndex(
                    (checked) => checked[uniqueKey] === option[uniqueKey]
                  ) !== -1
                }
                tabIndex={-1}
                disableRipple
                inputProps={{
                  'aria-labelledby': labelId,
                }}
              />
            </ListItemIcon>
            <ListItemText
              id={labelId}
              primary={optionGenerator(option)}
              sx={{
                '& .MuiListItemText-primary': {
                  whiteSpace: 'nowrap',
                  width: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
            />
          </ListItemButton>
        );
      }}
    </FixedSizeList>
  );
};

export default Options;

export const OuterElement = forwardRef<HTMLDivElement>((props, ref) => {
  return <Box ref={ref} {...props} sx={overflowY} />;
});

OuterElement.displayName = 'OuterElement';
