import { useCallback, useMemo } from 'react';

import { CHANGE_PASSWORD } from 'components/change-password-form';
import { useModal } from 'components/modal';
import { useSnackbar } from 'components/snackbar';
import { UserStatus, useUser } from 'hooks';

import { UserMenuItem } from './types';
import UserMenu from './user-menu';

const UserMenuContainer = () => {
  const { handleOpenModal } = useModal(CHANGE_PASSWORD);
  const { user, setUser } = useUser();
  const [, setSnackbar] = useSnackbar();

  const handleLogout = useCallback(() => {
    setUser((prev) => ({
      ...prev,
      name: user.rememberMe ? user.name : '',
      userId: user.rememberMe ? user.userId : '',
      accessToken: '',
      status: UserStatus.LoggedOut,
      roles: [],
    }));

    setSnackbar({
      open: true,
      severity: 'success',
      message: '로그아웃 되었습니다.',
    });
  }, [setSnackbar, setUser, user]);

  const userMenuItemList = useMemo<UserMenuItem[]>(
    () => [
      {
        text: '비밀번호 변경',
        onClick: handleOpenModal,
      },
      {
        text: '로그아웃',
        onClick: handleLogout,
      },
    ],
    [handleOpenModal, handleLogout]
  );

  return <UserMenu name={user.name} email={user.userId} userMenuItemList={userMenuItemList} />;
};

export default UserMenuContainer;
