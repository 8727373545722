import { get } from 'api/request';

import * as T from './types';

export default class ReportAPI {
  /**
   * 매출 리포트
   */
  static getSalesReportDashboard(token: string) {
    return get<T.SalesReportDashboardResponseSuccess>(
      '/api/v1/report/revenue/DashboardReport',
      token
    );
  }

  /**
   * 참여적립 리포트
   */
  static getGeneralCampaignReport(token: string, params: T.ReportParams) {
    return get<T.GeneralCampaignReportResponseSuccess, T.ReportParams>(
      '/api/v1/report/revenue/GeneralCampaignReport',
      token,
      params
    );
  }

  /**
   * 간편적립 리포트
   */
  static getCpmReport(token: string, params: T.ReportParams) {
    return get<T.CpmReportResponseSuccess, T.ReportParams>(
      '/api/v1/report/revenue/cpmReport',
      token,
      params
    );
  }

  /**
   * 간편적립 리포트 V2 (상세보기)
   */
  static getCpmReportV2(token: string, params: T.ReportParams) {
    return get<T.CpmReportV2ResponseSuccess, T.ReportParams>(
      '/api/v1/report/revenue/cpmReportV2',
      token,
      params
    );
  }

  /**
   * 뉴스적립 리포트
   */
  static getNewsReport(token: string, params: T.ReportParams) {
    return get<T.NewsReportResponseSuccess, T.ReportParams>(
      '/api/v1/report/revenue/newsReport',
      token,
      params
    );
  }

  /**
   * 뉴스적립 리포트 V2 (상세보기)
   */
  static getNewsReportV2(token: string, params: T.ReportParams) {
    return get<T.NewsReportV2ResponseSuccess, T.ReportParams>(
      '/api/v1/report/revenue/newsReportV2',
      token,
      params
    );
  }

  /**
   * 동영상적립 리포트 V2 (상세보기)
   */
  static getRVReportV2(token: string, params: T.ReportParams) {
    return get<T.RVReportResponseSuccess, T.ReportParams>(
      '/api/v1/report/revenue/VideoReportV2',
      token,
      params
    );
  }

  /**
   * 혜택 리포트 V2 (상세보기)
   */
  static getBenefitReportV2(token: string, params: T.ReportParams) {
    return get<T.BenefitReportResponseSuccess, T.ReportParams>(
      '/api/v1/report/revenue/BenefitReportV2',
      token,
      params
    );
  }

  /**
   * 캠페인 리포트 조회
   */
  static getCampaignReport(token: string, params: T.ReportParams) {
    return get<T.CampaignReportResponseSuccess, T.ReportParams>(
      '/api/v1/report/revenue/campaignReport',
      token,
      params
    );
  }

  /**
   * 캠페인 리포트 상세 조회
   */
  static getCampaignReportDetail(token: string, params: T.CampaignReportDetailParams) {
    return get<T.CampaignReportDetailResponseSuccess, T.CampaignReportDetailParams>(
      '/api/v1/report/revenue/ReportDetailByCampaign',
      token,
      params
    );
  }

  /**
   * 매체 리포트 조회
   */
  static getMediaReport(token: string, params: T.ReportParams) {
    return get<T.MediaReportResponseSuccess, T.ReportParams>(
      '/api/v1/report/revenue/MediaReport',
      token,
      params
    );
  }

  /**
   * 매체 리포트 조회
   */
  static getMediaReportDetail(token: string, params: T.MediaReportDetailParams) {
    return get<T.MediaReportDetailResponseSuccess, T.MediaReportDetailParams>(
      '/api/v1/report/revenue/ReportDetailByMedia',
      token,
      params
    );
  }

  /**
   * 요약 리포트 조회
   */
  static getSummaryReport(token: string, params: T.ReportParams) {
    return get<T.SummaryReportResponseSuccess, T.ReportParams>(
      '/api/v1/report/revenue/SummaryReport',
      token,
      params
    );
  }

  /**
   * 웹툰 리포트 조회
   */
  static getWebtoonReport(token: string, params: T.ReportParams) {
    return get<T.WebtoonReportResponseSuccess, T.ReportParams>(
      '/api/v1/report/revenue/WebtoonReportV2',
      token,
      params
    );
  }
}
