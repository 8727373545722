import { memo } from 'react';

import { Stack, LinearProgress, Typography } from '@mui/material';

import { fullWidth, fullHeight } from 'styles';
import { formatNumber } from 'utils';

import { ProgressProps } from './types';

const Progress = memo((props: ProgressProps) => {
  const { numerator, denominator, infinity = false } = props;

  const percentage = infinity
    ? 0
    : numerator === 0 && denominator === 0
    ? 0
    : (numerator / denominator) * 100;

  return (
    <Stack sx={[fullWidth, fullHeight]} justifyContent="center" spacing={1}>
      <Stack direction="row" alignSelf="end" spacing={1}>
        <Typography variant="body2" color="text.highlighted">
          {formatNumber(numerator)}
        </Typography>
        <Typography variant="body2">/</Typography>
        <Typography variant="body2">{infinity ? '∞' : formatNumber(denominator)}</Typography>
        <Typography variant="body2" color="text.highlighted">
          {`[${percentage.toFixed(2)}%]`}
        </Typography>
      </Stack>
      <LinearProgress
        variant="determinate"
        value={percentage > 100 ? 100 : percentage}
        sx={{ height: 8, borderRadius: 1 }}
      />
    </Stack>
  );
});

Progress.displayName = 'Progress';

export default Progress;
