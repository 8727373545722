export const DEFAULT_COLUMN_WIDTH = 200;
export const MIN_COLUMN_WIDTH = 50;
export const CHECKBOX_COLUMN_ID = 'checkbox';

export function sortString(str1: string, str2: string) {
  return str1 < str2 ? -1 : str1 > str2 ? 1 : 0;
}

export function sortNumber(num1: number, num2: number) {
  return num1 - num2;
}
