import { useCallback, useEffect } from 'react';

import * as rt from '@tanstack/react-table';
import { isFunction } from 'lodash-es';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { AnyObject } from 'utils';

import * as a from './atoms';
import { DEFAULT_COLUMN_WIDTH, MIN_COLUMN_WIDTH } from './const';

export type UseTableOptions<
  T extends AnyObject,
  M extends rt.TableMeta<T> = rt.TableMeta<T>,
  G extends a.GlobalFilterState = a.GlobalFilterState
> = {
  rows: T[];
  columns: rt.ColumnDef<T>[];
  meta: M;
  initialColumnVisibility?: rt.VisibilityState;
  initialColumnOrder?: rt.ColumnOrderState;
  initialSortingState?: rt.SortingState;
  initialPaginationState?: rt.PaginationState;
  initialColumnFiltersState?: a.ColumnFiltersState;
  initialGlobalFilterState?: G;
  globalFilterFn?: (rowData: T, globalFilterValue: G) => boolean;
  resetOnUnmount?: boolean;
};

const useTable = <T extends AnyObject, M extends rt.TableMeta<T>, G extends a.GlobalFilterState>(
  options: UseTableOptions<T, M, G>
) => {
  const {
    rows,
    columns,
    initialColumnVisibility,
    initialColumnOrder,
    initialSortingState,
    initialPaginationState,
    initialGlobalFilterState,
    globalFilterFn,
    initialColumnFiltersState,
    meta,
    resetOnUnmount = true,
  } = options;

  const scrollToTop = useCallback(() => {
    const tableEl = document.getElementById(meta.tableId);

    if (tableEl) {
      tableEl.scrollTo({ top: 0 });
    }
  }, [meta.tableId]);

  /**
   * 컬럼 노출 상태
   */
  const [columnVisibility, setColumnVisibility] = useRecoilState(
    a.columnVisibilityState({ tableId: meta.tableId, value: initialColumnVisibility })
  );

  /**
   * 컬럼 노출 상태 초기화
   */
  const resetColumnVisibilityState = useResetRecoilState(
    a.columnVisibilityState({ tableId: meta.tableId, value: initialColumnVisibility })
  );

  /**
   * 컬럼 순서 상태
   */
  const [columnOrder, setColumnOrder] = useRecoilState(
    a.columnOrderState({ tableId: meta.tableId, value: initialColumnOrder })
  );

  /**
   * 컬럼 순서 상태 초기화
   */
  const resetColumnOrderState = useResetRecoilState(
    a.columnOrderState({ tableId: meta.tableId, value: initialColumnOrder })
  );

  /**
   * 테이블 페이징 상태
   */
  const [pagination, setPagination] = useRecoilState(
    a.paginationState({ tableId: meta.tableId, value: initialPaginationState })
  );

  /**
   * 테이블 페이징 상태 초기화
   */
  const resetPaginationState = useResetRecoilState(
    a.paginationState({ tableId: meta.tableId, value: initialPaginationState })
  );

  /**
   * 테이블 페이징 상태 업데이트
   */
  const handleChangePaginationState = useCallback(
    (updater: rt.Updater<rt.PaginationState>) => {
      scrollToTop();
      return setPagination(updater);
    },
    [scrollToTop, setPagination]
  );

  /**
   * 테이블 정렬 상태
   */
  const [sorting, setSorting] = useRecoilState(
    a.sortingState({ tableId: meta.tableId, value: initialSortingState })
  );

  /**
   * 테이블 정렬 상태 초기화
   */
  const resetSortingState = useResetRecoilState(
    a.sortingState({ tableId: meta.tableId, value: initialSortingState })
  );

  /**
   * 테이블 정렬 상태 업데이트
   */
  const handleChangeSortState = useCallback(
    (updater: rt.Updater<rt.SortingState>) => {
      scrollToTop();
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
      return setSorting(updater);
    },
    [scrollToTop, setPagination, setSorting]
  );

  /**
   * 테이블 글로벌 필터 상태
   */
  const [globalFilter, setGlobalFilter] = useRecoilState(
    a.globalFilterState({ tableId: meta.tableId, value: initialGlobalFilterState })
  );

  /**
   * 테이블 글로벌 필터 상태 초기화
   */
  const resetGlobalFilterState = useResetRecoilState(
    a.paginationState({ tableId: meta.tableId, value: initialPaginationState })
  );

  /**
   * 테이블 글로벌 필터 상태 업데이트
   */
  const handleChangeGlobalFilterState = useCallback(
    (updater: rt.Updater<a.GlobalFilterState>) => {
      scrollToTop();
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
      return setGlobalFilter(updater);
    },
    [scrollToTop, setGlobalFilter, setPagination]
  );

  const _globalFilterFn = useCallback(
    (row: rt.Row<T>, columnId: string, globalFilterValue: G) => {
      if (isFunction(globalFilterFn)) {
        return globalFilterFn(row.original, globalFilterValue);
      }

      return true;
    },
    [globalFilterFn]
  );

  /**
   * 테이블 컬럼 필터 상태
   */
  const [columnFilters, setColumnFilters] = useRecoilState(
    a.columnFiltersState({ tableId: meta.tableId, value: initialColumnFiltersState })
  );

  /**
   * 테이블 컬럼 필터 상태 초기화
   */
  const resetColumnFiltersState = useResetRecoilState(
    a.columnFiltersState({ tableId: meta.tableId, value: initialColumnFiltersState })
  );

  /**
   * 테이블 컬럼 필터 상태 업데이트
   */
  const handleChangeColumnFiltersState = useCallback(
    (updater: rt.Updater<rt.ColumnFiltersState>) => {
      scrollToTop();
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
      return setColumnFilters(updater as rt.Updater<a.ColumnFiltersState>);
    },
    [scrollToTop, setColumnFilters, setPagination]
  );

  /**
   * 테이블 선택된 열 상태
   */
  const [rowSelection, setRowSelection] = useRecoilState(a.rowSelectionState(meta.tableId));

  /**
   * 테이블 선택된 열 초기화
   */
  const resetRowSelectionState = useResetRecoilState(a.rowSelectionState(meta.tableId));

  /**
   * 테이블 열 확장 상태
   */
  const [expanded, setExpanded] = useRecoilState(a.expandedState(meta.tableId));

  /**
   * 테이블 열 확장 초기화
   */
  const resetExpandedState = useResetRecoilState(a.expandedState(meta.tableId));

  const table = rt.useReactTable<T>({
    data: rows,
    columns: columns,
    getCoreRowModel: rt.getCoreRowModel(),
    autoResetAll: false,

    state: {
      columnVisibility,
      columnOrder,
      sorting,
      globalFilter,
      columnFilters,
      pagination,
      rowSelection,
      expanded,
    },

    meta, // arbitary data (ex. cell data updater)

    // column visibility, order
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,

    // sort
    onSortingChange: handleChangeSortState,
    getSortedRowModel: rt.getSortedRowModel(),

    // global filter
    onGlobalFilterChange: handleChangeGlobalFilterState,
    getFilteredRowModel: rt.getFilteredRowModel(),
    globalFilterFn: _globalFilterFn,

    // column filters
    onColumnFiltersChange: handleChangeColumnFiltersState,
    getFacetedRowModel: rt.getFacetedRowModel(),
    getFacetedUniqueValues: rt.getFacetedUniqueValues(),
    getFacetedMinMaxValues: rt.getFacetedMinMaxValues(),

    // pagination
    onPaginationChange: handleChangePaginationState,
    getPaginationRowModel: rt.getPaginationRowModel(),

    // row select
    onRowSelectionChange: setRowSelection,

    // row expand
    onExpandedChange: setExpanded,
    getExpandedRowModel: rt.getExpandedRowModel(),
    getSubRows: (row) => row.subRows as T[],

    // resize
    columnResizeMode: 'onEnd',
    defaultColumn: {
      size: DEFAULT_COLUMN_WIDTH,
      minSize: MIN_COLUMN_WIDTH,
    },
  });

  /**
   * 페이지 당 보기옵션 필터 (옵션 변경 시, 선택 상태를 초기화)
   */
  const handleChangeRowsPerPage = (size: number) => {
    table.setPageSize(size);
    resetRowSelectionState();
  };

  /**
   * 테이블 상태 초기화
   */
  const resetTableState = useCallback(() => {
    resetSortingState();
    resetPaginationState();
    resetGlobalFilterState();
    resetColumnFiltersState();
    resetRowSelectionState();
    resetColumnVisibilityState();
    resetColumnOrderState();
    resetExpandedState();
  }, [
    resetColumnFiltersState,
    resetGlobalFilterState,
    resetPaginationState,
    resetRowSelectionState,
    resetSortingState,
    resetColumnVisibilityState,
    resetColumnOrderState,
    resetExpandedState,
  ]);

  /**
   * unmount시 테이블 상태 초기화
   */
  useEffect(() => {
    return () => {
      if (resetOnUnmount) {
        resetTableState();
      }
    };
  }, [resetOnUnmount, resetTableState]);

  return {
    ...table,
    setRowsPerPage: handleChangeRowsPerPage,
    setGlobalFilter: table.setGlobalFilter as (updater: rt.Updater<G>) => void,
    setColumnFilters: table.setColumnFilters as (updater: rt.Updater<a.ColumnFiltersState>) => void,
  };
};

export default useTable;
