import { Popper as MuiPopper, styled } from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';

const AutocompletePopper = styled(MuiPopper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export default AutocompletePopper;
