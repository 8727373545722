import { useCallback, useMemo } from 'react';

import { CopyAll } from '@mui/icons-material';
import { IconButton, SxProps, Theme } from '@mui/material';
import { Cell, Column, HeaderGroup, Row } from '@tanstack/react-table';
import { isFunction } from 'lodash-es';

import { getButtonStyle } from 'styles';
import { AnyObject } from 'utils';

import { useSnackbar } from './snackbar';
import { CHECKBOX_COLUMN_ID } from './table';

export type CopyTableProps<T extends AnyObject> = {
  rows: Row<T>[];
  columns: Column<T>[];
  excludedColumnId?: string[];
  cellMapper?: (cell: Cell<T, unknown>, idx: number) => unknown;
  footerGroups?: HeaderGroup<T>[];
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

const CopyTable = <T extends AnyObject>(props: CopyTableProps<T>) => {
  const [, setSnackbar] = useSnackbar();
  const {
    rows,
    columns,
    excludedColumnId = [],
    cellMapper,
    footerGroups = [],
    disabled = false,
    sx = [],
  } = props;

  const _excludedColumnId = useMemo(
    () => [CHECKBOX_COLUMN_ID].concat(excludedColumnId),
    [excludedColumnId]
  );

  const getTableData = useCallback(() => {
    const tableHead = columns
      .filter((column) => !_excludedColumnId.includes(column.id))
      .map((column) => column.columnDef.header)
      .join('\t');

    const tableBody = rows.map((row) => {
      const tableRow = row
        .getVisibleCells()
        .filter((cell) => !_excludedColumnId.includes(cell.column.id))
        .map(cellMapper || ((cell) => cell.getValue()))
        .join('\t');

      return tableRow;
    });

    const tableFooter = footerGroups.map((footerGroup) => {
      return footerGroup.headers
        .map((header) => {
          const footer = header.column.columnDef.footer;
          const colSpan = header.colSpan;
          const result = (isFunction(footer) ? footer(header.getContext()) : footer) as string;
          return result.concat(Array.from({ length: colSpan }, () => '\t').join(''));
        })
        .join('');
    });

    return `${tableHead}\n${tableBody.join('\n')}\n${tableFooter.join('\n')}`;
  }, [_excludedColumnId, cellMapper, columns, footerGroups, rows]);

  const copyTable = useCallback(async () => {
    if (disabled) return;

    try {
      const tableData = getTableData();
      await navigator.clipboard.writeText(tableData);
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        message: '복사 실패',
      });
    }
  }, [disabled, getTableData, setSnackbar]);

  return (
    <IconButton
      aria-label="테이블 데이터 복사"
      sx={[
        getButtonStyle(disabled ? 'disabled' : 'primary'),
        {
          borderRadius: 1,
          width: 36,
          height: 36,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onClick={copyTable}
      disabled={disabled}
    >
      <CopyAll fontSize="small" />
    </IconButton>
  );
};

export default CopyTable;
