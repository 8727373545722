import { Autorenew } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';

import { getButtonStyle, rotateAnimation } from 'styles';

export type RefreshButtonProps = Omit<IconButtonProps, 'children'> & {
  loading?: boolean;
};

const RefreshButton = (props: RefreshButtonProps) => {
  const { disabled = false, loading = false, sx = [], ...rest } = props;

  return (
    <IconButton
      aria-label="새로고침"
      disabled={disabled}
      sx={[
        getButtonStyle(disabled ? 'disabled' : 'primary'),
        {
          borderRadius: 1,
          width: 36,
          height: 36,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      <Autorenew
        fontSize="small"
        sx={[loading && { animation: `${rotateAnimation} 1s infinite` }]}
      />
    </IconButton>
  );
};

export default RefreshButton;
