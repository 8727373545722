import { get } from 'api/request';

import * as T from './types';

export default class DashboardAPI {
  static getDashboardInfo(token: string, params: T.DashboardInfoParams) {
    return get<T.DashboardInfoResponseSuccess, T.DashboardInfoParams>(
      '/api/v1/dashboard/dashboard/DashboardInfo',
      token,
      params
    );
  }

  static getHourlyRevenue(token: string, params: T.HourlyRevenueParams) {
    return get<T.HourlyRevenueResponseSuccess, T.HourlyRevenueParams>(
      '/api/v1/dashboard/dashboard/HourlyRevenue',
      token,
      params
    );
  }

  static getTopCampaignRevenue(token: string, params: T.TopCampaignRevenueParams) {
    return get<T.TopCampaignRevenueResponseSuccess, T.TopCampaignRevenueParams>(
      '/api/v1/dashboard/dashboard/TopCampaignRevenue',
      token,
      params
    );
  }

  static getTopMediaRevenue(token: string, params: T.TopMediaRevenueParams) {
    return get<T.TopMediaRevenueResponseSuccess, T.TopMediaRevenueParams>(
      '/api/v1/dashboard/dashboard/TopMediaRevenue',
      token,
      params
    );
  }
}
