import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { CompleteMultiMediaRewardData, CSAPI } from 'api';
import { useUser } from 'hooks';
import { MutationOptions } from 'query-client';

const useCompleteMultiMediaReward = (options?: MutationOptions<CompleteMultiMediaRewardData>) => {
  const { user } = useUser();

  const mutateFn = useCallback(
    (data: CompleteMultiMediaRewardData) => {
      return CSAPI.completeMediaMultiReward(user.accessToken, data);
    },
    [user.accessToken]
  );

  return useMutation(mutateFn, options);
};

export default useCompleteMultiMediaReward;
